import {CallOptions, MenuItem} from "../types/types";

export let API_URL: any;
export let REACT_STATISTIC_API_URL = process.env.REACT_STATISTIC_API_URL;
export let WSS_URL:any;
export let WEBRTC_WSS_URL : any;
export let WWS_WEBRTC:any;
export let MVP = true;
export let PROD = false;

const hostname = window.location.hostname;
const origin = window.location.origin;

if (hostname === 'callcenter.stage.in.ua') {
    API_URL = process.env.REACT_APP_PROD_API_URL;
    WSS_URL = process.env.REACT_APP_PROD_WSS_URL;
    WEBRTC_WSS_URL = process.env.REACT_APP_PROD_WWS_WEBRTC_URL;
    WWS_WEBRTC = process.env.REACT_APP_PROD_WWS_WEBRTC;
    REACT_STATISTIC_API_URL = process.env.REACT_STATISTIC_API_URL;
    PROD = true;
} else if (hostname === 'localhost' || hostname === 'callcenter-dev.stage.in.ua') {
    API_URL = process.env.REACT_APP_API_URL;
    WSS_URL = process.env.REACT_APP_WSS_URL;
    WEBRTC_WSS_URL = process.env.REACT_APP_WWS_WEBRTC_URL;
    WWS_WEBRTC = process.env.REACT_APP_WWS_WEBRTC;
} else {
    API_URL = origin + '/api/';
    WSS_URL = origin + '/wss/';
    WEBRTC_WSS_URL = 'wss://' + hostname + '/webrtc';
    WWS_WEBRTC = hostname;
}

export const menuConfig: MenuItem[] = [
    {
        label: 'Вихiднi кампанii',
        icon: 'pi pi-folder',
        to: '/',
        name: 'Dialer'
    },
    {
        label: 'Вхідні черги',
        icon: 'pi pi-database',
        to: '/queue',
        name: 'Queues'
    },
    {
        label: 'Монiторинг Подiй',
        icon: 'pi pi-chart-bar',
        to: '/monitoring',
        name: 'Monitoring'
    },
    {
        label: 'Branch',
        icon: 'pi pi-sitemap',
        to: '/branch',
        name: 'Branch'
    },
    {
        label: 'Групи кваліфікацій',
        icon: 'pi pi-th-large',
        to: '/skill_group',
        name: 'SkillGroupManager'
    },
    {
        label: 'Статистика',
        icon: 'pi pi-chart-pie',
        name: 'Statistic',
        items: [
            {
                label: 'Дані дзвінків',
                icon: 'pi pi-chart-bar',
                to: '/statistics',
                name: 'Statistic'
            },
            {
                label: 'Дзвінки операторів',
                icon: 'pi pi-chart-line',
                to: '/calls',
                name: 'Statistic'
            },
            {
                label: 'Статуси операторів',
                icon: 'pi pi-list-check',
                to: '/statuses',
                name: 'Statistic'
            }
        ]
    },
    {
        label: 'Налаштування',
        icon: 'pi pi-cog',
        name: 'Settings',
        items: [
            {
                label: 'Ролі користувачів',
                icon: 'pi pi-shield',
                to: '/role',
                name: 'Role'
            },
            {
                label: 'Статуси',
                icon: 'pi pi-verified',
                to: '/status',
                name: 'Status'
            },
            {
                label: 'Користувачі',
                icon: 'pi pi-id-card',
                to: '/usersmanager',
                name: 'UsersManager'
            },
            {
                label: 'Транки',
                icon: 'pi pi-sliders-v',
                to: '/trunks',
                name: 'Trunks'
            },
            {
                label: 'Бібліотека аудіо',
                icon: 'pi pi-volume-up',
                to: '/audio',
                name: 'Audio'
            }
        ]
    }
];

export const menuRoleConfig: MenuItem[] = [
    {
        label: 'Вихiднi кампанii',
        icon: 'pi pi-folder',
        to: '/',
        name: 'Dialer'
    },
    {
        label: 'Вхідні черги',
        icon: 'pi pi-database',
        to: '/queue',
        name: 'Queues'
    },
    {
        label: 'Монiторинг Подiй',
        icon: 'pi pi-chart-bar',
        to: '/monitoring',
        name: 'Monitoring'
    },
    {
        label: 'Branch',
        icon: 'pi pi-sitemap',
        to: '/branch',
        name: 'Branch'
    },
    {
        label: 'Плеєр',
        icon: 'pi pi-play',
        to: '/role',
        name: 'Player'
    },
    {
        label: 'Групи кваліфікацій',
        icon: 'pi pi-th-large',
        to: '/skill_group',
        name: 'SkillGroupManager'
    },
    {
        label: 'Статистика',
        icon: 'pi pi-chart-pie',
        name: 'Statistic',
        items: [
            {
                label: 'Дані дзвінків',
                icon: 'pi pi-chart-bar',
                to: '/statistics',
                name: 'Statistic'
            },
            {
                label: 'Дзвінки операторів',
                icon: 'pi pi-chart-line',
                to: '/calls',
                name: 'Statistic'
            },
            {
                label: 'Статуси операторів',
                icon: 'pi pi-list-check',
                to: '/statuses',
                name: 'Statistic'
            }
        ]
    },
    {
        label: 'Налаштування',
        icon: 'pi pi-cog',
        name: 'Settings',
        items: [
            {
                label: 'Ролі користувачів',
                icon: 'pi pi-shield',
                to: '/role',
                name: 'Role'
            },
            {
                label: 'Статуси',
                icon: 'pi pi-verified',
                to: '/status',
                name: 'Status'
            },
            {
                label: 'Користувачі',
                icon: 'pi pi-id-card',
                to: '/usersmanager',
                name: 'UsersManager'
            },
            {
                label: 'Транки',
                icon: 'pi pi-sliders-v',
                to: '/trunks',
                name: 'Trunks'
            },
            {
                label: 'Бібліотека аудіо',
                icon: 'pi pi-volume-up',
                to: '/audio',
                name: 'Audio'
            }
        ]
    }
];

export const callOptions: CallOptions = {
    mediaConstraints: {
        audio: true,
        video: false
    },
    pcConfig: {
        iceCandidatePoolSize: 1,
        iceTransportPolicy: "relay",
        rtcpMuxPolicy: "require",
        iceServers: []
    },
    extraHeaders: []
}
export const base64ToBlob = (base64: string, contentType: string) => {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], {type: contentType});
}

