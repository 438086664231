import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {Button} from "primereact/button";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {Calendar} from "primereact/calendar";
import {getCallStatisticExcel} from "../../../../redux/api/apiCallsStatisticsList";
import {setClearFilters} from "../../../../redux/actions/actions";

const CallsStatisticsListNav = (props: any) => {
    const dispatch = useAppDispatch()
    const exportLoading = useAppSelector(state => state.StatisticsCallsListReducer.exportLoading);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as any;
    const statisticPermissions = checkPermissions(rolesVision, 'Statistic');
    const statisticsCallsList = useAppSelector(state => state.StatisticsCallsListReducer?.statisticsCallsList);
    const [isListLength, setIsListLength] = useState(false);

    useEffect(() => {
        setIsListLength(!!statisticsCallsList?.users?.length);
    }, [statisticsCallsList]);

    const {
        update: hasUpdatePermission,
    } = statisticPermissions;

    let filteredObject = {
        "sort": props.sortData?.sort,
        "filter": props.sortData?.filter
    };

    // useEffect(() => {
    //     setSelectedFields(userStatisticSortFields?.fields);
    // }, []);
    //
    // useEffect(() => {
    //     setSelectedFields(userStatisticSortFields?.fields);
    // }, [userStatisticSortFields]);
    //
    // const onColumnToggle = (e: { value: { name: any; }[]; }) => {
    //     const updatedFields = selectedFields.map((field: { name: any; inSystem: any; }) => {
    //         if (!field.inSystem) {
    //             return {
    //                 ...field,
    //                 isActive: e.value.some((selected: { name: any; }) => selected.name === field.name)
    //             };
    //         }
    //         return field;
    //     });
    //     setSelectedFields(updatedFields);
    // };
    //
    // const saveUserFields = () => {
    //     dispatch(updateUserStatisticFields(selectedFields, jwtToken?.jwtToken));
    // };

    // const itemTemplate = (option: any) => {
    //     let itemName:string;
    //     switch (option.name) {
    //         case "nameTo":
    //             itemName = 'Клiент';
    //             break;
    //         case "ownerCall":
    //             itemName = 'Власник дзвінка';
    //             break;
    //         case "fullName":
    //             itemName = 'Оператор';
    //             break;
    //         case "direction":
    //             itemName = 'Тип Активності';
    //             break;
    //         case "campaignFullName":
    //             itemName = 'ID / Назва Кампанii';
    //             break;
    //         case "queueFullName":
    //             itemName = 'ID / Назва Черги';
    //             break;
    //         case "status":
    //             itemName = 'Статус';
    //             break;
    //         case "typeChannel":
    //             itemName = 'Активність';
    //             break;
    //         case "callResetName":
    //             itemName = 'Завершив';
    //             break;
    //         case "amdHangup":
    //             itemName = 'Автовідповідач';
    //             break;
    //         case "start":
    //             itemName = 'Початок';
    //             break;
    //         case "duration":
    //             itemName = 'Тривалість розмови';
    //             break;
    //         case "fullCallDuration":
    //             itemName = 'Тривалість дзвінка';
    //             break;
    //         case "reactionClient":
    //             itemName = 'Реакція Клієнта';
    //             break;
    //         case "reactionOperator":
    //             itemName = 'Реакція Оператора';
    //             break;
    //         case "end":
    //             itemName = 'Завершення';
    //             break;
    //         default:
    //             itemName = option.name;
    //     }
    //
    //     return (
    //         <div className="custom-item">
    //             <span>{itemName}</span>
    //         </div>
    //     );
    // }

    // const selectedItemTemplate = (option: any) => {
    //     if (!option) {
    //         return <p className="opacity-50">Нічого не обрано</p>;
    //     }
    //
    //     let itemName:string;
    //
    //     switch (option.name) {
    //         case "nameTo":
    //             itemName = 'Клiент';
    //             break;
    //         case "ownerCall":
    //             itemName = 'Власник дзвінка';
    //             break;
    //         case "fullName":
    //             itemName = 'Оператор';
    //             break;
    //         case "direction":
    //             itemName = 'Тип Активності';
    //             break;
    //         case "campaignFullName":
    //             itemName = 'ID / Назва Кампанii';
    //             break;
    //         case "queueFullName":
    //             itemName = 'ID / Назва Черги';
    //             break;
    //         case "status":
    //             itemName = 'Статус';
    //             break;
    //         case "typeChannel":
    //             itemName = 'Активність';
    //             break;
    //         case "callResetName":
    //             itemName = 'Завершив';
    //             break;
    //         case "amdHangup":
    //             itemName = 'Автовідповідач';
    //             break;
    //         case "start":
    //             itemName = 'Початок';
    //             break;
    //         default:
    //             itemName = option.name;
    //     }
    //
    //     return <Tag
    //         value={itemName} // Отображаем имя опции
    //         className="custom-chip"
    //     ></Tag>;
    // };

    // const onRowReorder = (e: { dragIndex: number; dropIndex: number; }) => {
    //     let updatedRows = [...selectedFields];
    //     const draggedRow = updatedRows[e.dragIndex];
    //
    //     updatedRows.splice(e.dragIndex, 1);
    //     updatedRows.splice(e.dropIndex, 0, draggedRow);
    //
    //     updatedRows = updatedRows.map((row, index) => ({
    //         ...row,
    //         order: index + 1
    //     }));
    //
    //     setSelectedFields(updatedRows);
    // }
    //
    // const handleDelete = (index: number) => {
    //     const updatedFields = selectedFields.map((field: any, i: number) =>
    //         i === index ? { ...field, isActive: false } : field
    //     );
    //     setSelectedFields(updatedFields);
    // }
    //
    // const columnGroupItems = () => {
    //     const filteredData = selectedFields
    //         ?.filter((field: { isActive: boolean }) => field.isActive)
    //         ?.sort((a: { order: number }, b: { order: number }) => a.order - b.order);
    //
    //     const normalizeDisplayOrder = (array: any[]) => {
    //         return array?.map((item, index) => ({
    //             ...item,
    //             order: index + 1,
    //         }));
    //     };
    //
    //     const filteredArray = normalizeDisplayOrder(filteredData);
    //
    //     const nameField = (rowData: any) => {
    //         let itemName:string;
    //
    //         switch (rowData.name) {
    //             case "nameTo":
    //                 itemName = 'Клiент';
    //                 break;
    //             case "ownerCall":
    //                 itemName = 'Власник дзвінка';
    //                 break;
    //             case "fullName":
    //                 itemName = 'Оператор';
    //                 break;
    //             case "direction":
    //                 itemName = 'Тип Активності';
    //                 break;
    //             case "campaignFullName":
    //                 itemName = 'ID / Назва Кампанii';
    //                 break;
    //             case "queueFullName":
    //                 itemName = 'ID / Назва Черги';
    //                 break;
    //             case "status":
    //                 itemName = 'Статус';
    //                 break;
    //             case "typeChannel":
    //                 itemName = 'Активність';
    //                 break;
    //             case "callResetName":
    //                 itemName = 'Завершив';
    //                 break;
    //             case "amdHangup":
    //                 itemName = 'Автовідповідач';
    //                 break;
    //             case "start":
    //                 itemName = 'Початок';
    //                 break;
    //             case "duration":
    //                 itemName = 'Тривалість розмови';
    //                 break;
    //             case "fullCallDuration":
    //                 itemName = 'Тривалість дзвінка';
    //                 break;
    //             case "reactionClient":
    //                 itemName = 'Реакція Клієнта';
    //                 break;
    //             case "reactionOperator":
    //                 itemName = 'Реакція Оператора';
    //                 break;
    //             case "end":
    //                 itemName = 'Завершення';
    //                 break;
    //             default:
    //                 itemName = rowData.name;
    //         }
    //
    //         return <p className="m-0">{itemName}</p>;
    //     }
    //
    //     const buttonTemplate = (rowData: any) => {
    //         const originalIndex = selectedFields.findIndex((field: any) => field.name === rowData.name); // Получаем оригинальный индекс в selectedFields
    //         return !rowData?.inSystem ? (
    //             <Button
    //                 onClick={() => handleDelete(originalIndex)} // Удаляем по индексу из оригинального массива
    //                 icon="pi pi-times"
    //                 className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2 mb-0 px-0"
    //                 disabled={!hasUpdatePermission}
    //             />
    //         ) : <></>;
    //     }
    //
    //         return <DataTable className="w-full" value={filteredArray} reorderableColumns reorderableRows onRowReorder={onRowReorder}>
    //             <Column rowReorder style={{width: '20px'}}/>
    //             <Column field="order" className="text-center" style={{minWidth: '10px', width: '40px'}}/>
    //             <Column field="name" body={nameField} style={{minWidth: '50%'}}/>
    //             <Column body={buttonTemplate} style={{width: '20px', padding: 0}}/>
    //         </DataTable>
    //     };

    const isDisabled = () => {
        return !props.endTime || !props.startTime || !props.filterDateTime || !props.filterEndDateTime;
    };

    return (
        <>
        <div className="table-buttons">
            <div className="text-normal-weight flex align-items-center">
                <Button
                    label="Мої активності"
                    className={props.activeTab === 'table1' ? 'mr-2 mb-2' : 'mr-2 mb-2'}
                />
                {/*<Button className="mb-2" type="button" icon="pi pi-cog"*/}
                {/*        onClick={(e) => {*/}
                {/*            // @ts-ignore*/}
                {/*            op.current.toggle(e);*/}
                {/*            setSelectedFields(userStatisticSortFields?.fields);*/}

                {/*}}/>*/}
                <div className="flex field align-items-center max-w-15rem ml-4 desktop-filter">
                    <span className="title pt-2" style={{width: '25px'}}>З*</span>
                    <Calendar className="mt-2" value={props.filterDateTime ? props.filterDateTime : null} onChange={(e: any) => {
                        // options.filterCallback(e.value, options.index);
                        props.setFilterDateTime(e.value)
                    }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
                    <Calendar
                        appendTo="self"
                        id="icon"
                        className="mt-2 ml-2"
                        placeholder="00:00"
                        value={props.startTime ? props.startTime : null}
                        timeOnly
                        onChange={(e) => props.setStartTime(e.value)}
                        hourFormat="24"
                    />
                </div>
                <div className="flex field align-items-center max-w-15rem ml-2 desktop-filter">
                    <span className="title pt-2" style={{width: '35px'}}>По*</span>
                    <Calendar className="mt-2" value={props.filterEndDateTime ? props.filterEndDateTime : null} onChange={(e: any) => {
                        props.setFilterEndDateTime(e.value)
                    }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
                    <Calendar
                        appendTo="self"
                        id="icon"
                        className="mt-2 ml-2"
                        value={props.endTime ? props.endTime : null}
                        required
                        placeholder="00:00"
                        timeOnly
                        onChange={(e) => props.setEndTime(e.value)}
                        hourFormat="24"
                    />
                </div>
                <Button disabled={isDisabled()} onClick={() => props.setHasStartFilter(!props.hasStartFilter)}
                            icon="pi pi-search" className="mb-2 ml-2 desktop-filter"/>
                {/*<OverlayPanel ref={op}>*/}
                {/*    <div className="flex flex-column">*/}
                {/*        <div className="mb-3 text-bold">Виберіть стовпці для відображення:</div>*/}
                {/*        <MultiSelect*/}
                {/*            value={selectedFields?.filter((field: any) => !field.inSystem && field?.isActive)}*/}
                {/*            options={selectedFields?.filter((field: { inSystem: any }) => !field.inSystem)}*/}
                {/*            optionLabel="name"*/}
                {/*            onChange={onColumnToggle}*/}
                {/*            className="w-full sm:w-20rem mb-2"*/}
                {/*            display="chip"*/}
                {/*            filter*/}
                {/*            selectedItemTemplate={selectedItemTemplate}*/}
                {/*            itemTemplate={itemTemplate}*/}
                {/*            // panelFooterTemplate={panelHeaderTemplate}*/}
                {/*        />*/}
                {/*        {*/}
                {/*            selectedFields?.length ?*/}
                {/*                <div className="users-list">*/}
                {/*                    {columnGroupItems()}*/}
                {/*                </div>*/}
                {/*                :*/}
                {/*                <span className="no-users-message">Груп не обрано</span>*/}
                {/*        }*/}


                {/*        <div className="flex justify-content-between field mt-2 mb-0">*/}
                {/*            <Button icon="pi pi-times" className="p-button p-component p-button-secondary p-button-icon-only" onClick={(e) =>{*/}
                {/*                // @ts-ignore*/}
                {/*                op.current.toggle(e)*/}
                {/*            }}></Button>*/}
                {/*            <Button icon="pi pi-check" onClick={() => {*/}
                {/*                saveUserFields();*/}
                {/*                // @ts-ignore*/}
                {/*                op.current.hide();*/}
                {/*            }}></Button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</OverlayPanel>*/}
            </div>
            <div className="desktop">
                {hasUpdatePermission && <Button onClick={() => dispatch(getCallStatisticExcel(filteredObject, jwtToken?.jwtToken))}
                                                label="Експорт таблиці"
                                                loading={exportLoading}
                                                disabled={!isListLength}
                                                icon="pi pi-file-excel"
                                                style={{backgroundColor: '#1d6f42'}}
                                                className="mr-2 mb-2 p-button-success text-white shadow-none"
                />}
                <Button type="button" icon="pi pi-filter-slash" label="Очистити фільтри" className="p-button-outlined mr-2 mb-2" onClick={() => {
                    props.resetSorting();

                }}/>
            </div>
            <div className="mobile">
                {hasUpdatePermission && <Button onClick={() => dispatch(getCallStatisticExcel(filteredObject, jwtToken?.jwtToken))}
                                                icon="pi pi-file-excel"
                                                disabled={!isListLength}
                                                loading={exportLoading}
                                                style={{backgroundColor: '#1d6f42'}}
                                                className="mr-2 mb-2 p-button-success text-white shadow-none"
                />}
                <Button type="button" icon="pi pi-filter-slash" className="p-button-outlined mr-2 mb-2" onClick={() => dispatch(setClearFilters())}/>
            </div>
        </div>
<div className="flex align-items-center flex-wrap">
            <div className="col-12 sm:col-5 field align-items-center mobile-filter mb-1">
                <span className="title pt-2" style={{width: '35px'}}>З*</span>
                <Calendar className="mt-2" value={props.filterDateTime ? props.filterDateTime : null} onChange={(e: any) => {
                    // options.filterCallback(e.value, options.index);
                    props.setFilterDateTime(e.value)
                }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
                <Calendar
                    appendTo="self"
                    id="icon"
                    className="mt-2 ml-2"
                    placeholder="00:00"
                    value={props.startTime ? props.startTime : null}
                    timeOnly
                    onChange={(e) => props.setStartTime(e.value)}
                    hourFormat="24"
                />
            </div>
            <div className="col-12 sm:col-5 field align-items-center mobile-filter mb-1">
                <span className="title pt-2" style={{width: '35px'}}>По*</span>
                <Calendar className="mt-2" value={props.filterEndDateTime ? props.filterEndDateTime : null} onChange={(e: any) => {
                    props.setFilterEndDateTime(e.value)
                }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
                <Calendar
                    appendTo="self"
                    id="icon"
                    className="mt-2 ml-2"
                    value={props.endTime ? props.endTime : null}
                    required
                    placeholder="00:00"
                    timeOnly
                    onChange={(e) => props.setEndTime(e.value)}
                    hourFormat="24"
                />
            </div>
    <div className="col-12 sm:col-1 mb-2 sm:mb-0 p-0">
        <Button onClick={() => props.setHasStartFilter(!props.hasStartFilter)}
                disabled={isDisabled()}
                icon="pi pi-search" label="Пошук" className="mobile-filter mobile-filter-btn" style={{minWidth: '30px'}}/>
    </div>

</div>
            </>
    )
}

export default CallsStatisticsListNav;
