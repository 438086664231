import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {ProgressBar} from "primereact/progressbar";
import {classNames} from "primereact/utils";
import {setSelectedRolesIndex} from "../../../redux/actions/actionsRoles";
import {Checkbox} from "primereact/checkbox";
import {setStatusDialogVisible, setStatusField, setStatusTime} from "../../../redux/actions/actionsStatuses";
import {Badge} from "primereact/badge";
import {setEditType} from "../../../redux/actions/actions";
import {Divider} from "primereact/divider";
import {InputText} from "primereact/inputtext";

const StatusesListDetails = (props: any) => {
    const dispatch = useAppDispatch();
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const loader = useAppSelector(state => state.StatusesListReducer.detailsLoading);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const statusData = useAppSelector(state => state.StatusesListReducer.statusData) as unknown as any;
    const statusesDictionary = useAppSelector(state => state.StatusesListReducer.statusesDictionary) as unknown as any;
    const archiveRolesTableToggle = useAppSelector(state => state.StatusesListReducer.archiveStatusTableToggle);
    const rolePermissions = checkPermissions(rolesVision, 'Status');
    const [timeInSeconds, setTimeInSeconds] = useState<any>(null);
    const [timeInHoursMinutesSeconds, setTimeInHoursMinutesSeconds] = useState({
        hours: '00',
        minutes: '00',
        remainingSecondsFinal: '00'
    });
    let inSystem = statusData?.inSystem;
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);

    const {
        update: hasRoleUpdatePermission
    } = rolePermissions;

    const convertToHoursMinutesSeconds = (seconds: any) => {
        const hours = Math.floor(seconds / 3600);
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
        const remainingSecondsFinal = remainingSeconds % 60;

        return {
            hours: hours.toString().padStart(2, '0'),
            minutes: minutes.toString().padStart(2, '0'),
            remainingSecondsFinal: remainingSecondsFinal.toString().padStart(2, '0')
        };
    };

    useEffect(() => {
        if (statusData?.timeStatus !== undefined) {
            const timeInSeconds = statusData.timeStatus;
            setTimeInSeconds(timeInSeconds);

            const timeInHoursMinutesSeconds = convertToHoursMinutesSeconds(timeInSeconds);
            setTimeInHoursMinutesSeconds(timeInHoursMinutesSeconds);
        }
    }, [statusData?.timeStatus]);


    useEffect(() => {
        if (timeInSeconds) {
            dispatch(setStatusTime(timeInSeconds));
        }
    }, [timeInSeconds]);

    const typeBodyTemplate = (rowData: any) => {
        let typeName = statusesDictionary.map((item: any) => {
            if (item.dictionaryId === rowData.typeId) {
                return item.description
            }
        })
        return (
            <React.Fragment>
                <span style={{verticalAlign: 'middle'}} className="image-text">
                    {typeName}
                </span>
            </React.Fragment>
        );
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fields = [
        {label: "ID", value: statusData?.id},
        {label: "Тип статуса:", value: typeBodyTemplate(statusData)}
    ];

    const openEditor = () => {
        dispatch(setSelectedRolesIndex(statusData?.id || props.selectedRowData));
        dispatch(setStatusDialogVisible(true));
        // props.setSelectedRowData(null);
        dispatch(setStatusTime(null));
        if (hasRoleUpdatePermission) {
            dispatch(setEditType(true));
        } else {
            dispatch(setEditType(false));
        }
    };

    const formattedBranches = branches?.map((branch: { id: any; name: any; }) => ({
        ...branch,
        label: `${branch.id} ${branch.name}`,
    }));

    const branchItems = statusData?.statusBranches?.map((queueItem: any) => {
        return formattedBranches?.map((branchItem: any, index: number) => {
            if (queueItem.id === branchItem.id) {
                return (
                    <div className="field col-12 md:col-12 flex m-0 px-0" key={index}>
                        {branchItem.label}
                    </div>
                )
            }
        })
    });

    const replaceStatusName = statusData?.statusName?.replace(/Copy.*/, 'Copy...');

    return (
        <div className="">
            <div className="relative">
                {loader && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
                <div className={classNames('grid', {'opacity-50': loader})} style={{paddingTop: "0.3rem", padding: '0.3rem 10px 5px'}}>
                    {windowWidth > 1248 && <div className="grid col-12 md:col-12 mb-0 flex justify-content-between">
                        <div className="field col-12 md:col-6 flex align-items-center flex-wrap m-0">
                            <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                                <p className="w-full mb-2">ID: {statusData?.id}</p>
                            </div>
                            <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">
                                <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">Тип статуса:</div>
                                <div className="field col-12 md:col-8 flex align-items-center m-0 p-0">{typeBodyTemplate(statusData)}</div>
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center m-0 p-0">
                                <p className="w-full font-bold">{statusData?.statusName}</p>
                            </div>
                            <div className="field col-12 md:col-8 flex align-items-center m-0 p-0"></div>
                        </div>
                        <div className="col-12 md:col-6 flex align-items-center m-0 p-0 justify-content-end text-right">
                            <div className="field col-12 md:col-5 flex align-items-center justify-content-end m-0 p-0">
                                <p className="w-full">{<Badge value={replaceStatusName} className={`p-badge text-white customer-badge text-xs p-0 px-2`} style={{borderRadius: '6px', backgroundColor: `${statusData.statusColor}`, minWidth: '180px'}}/>}</p>
                            </div>
                            <div className="field col-12 md:col-4 flex align-items-center justify-content-end m-0 p-0">
                                <p className="w-full">{statusData?.active ? 'Активна' : 'Не активна'}</p>
                            </div>
                            <div className="field col-12 md:col-3 flex align-items-center m-0 p-0">
                                <div className="field col-12 md:col-12 flex align-items-center justify-content-end m-0 p-0">
                                    <button className="close-button block cursor-pointer mr-1" onClick={() => {
                                        openEditor();
                                    }}><span className={classNames('', {
                                        'pi pi-pencil': archiveRolesTableToggle && hasRoleUpdatePermission && !inSystem,
                                        'pi pi-eye': !archiveRolesTableToggle || !hasRoleUpdatePermission || inSystem
                                    })}></span></button>
                                    <button className="close-button block cursor-pointer ml-0" onClick={() => props.setSelectedRowData(null)}><span className="pi pi-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <Divider className="m-0"/>
                    </div>}
                    {windowWidth <= 1248 && <div className="grid col-12 md:col-12 mb-0 px-0">
                        <div className="field col-5 md:col-5 flex align-items-center m-0">
                            <p className="w-full">{statusData?.statusName}</p>
                        </div>
                        <div className="field col-7 md:col-7 flex align-items-center m-0 px-0">
                            <div className="field col-12 md:col-12 flex align-items-center justify-content-end m-0 px-0">
                                <Badge value={replaceStatusName} className={`p-badge text-white customer-badge text-xs p-0 px-2`} style={{borderRadius: '6px', backgroundColor: `${statusData.statusColor}`, minWidth: '125px'}}/>
                            </div>
                        </div>
                        {(<div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>)}
                    </div>}
                    {windowWidth <= 1248 && fields.map((field: any, index) => (
                        <React.Fragment key={index}>
                            <div className="field col-12 md:col-6 flex align-items-center m-0">
                                <p>{field.label}</p>
                            </div>
                            <div className="field col-12 md:col-6 flex align-items-center m-0">
                                <p>{field.value}</p>
                            </div>
                        </React.Fragment>
                    ))}
                    {windowWidth > 1248 && <div className="field col-12 md:col-12 flex flex-wrap m-0 p-0 pb-1">
                        <div className="field col-12 md:col-4 flex m-0">Бранч</div>
                        <div className="field col-12 md:col-4 flex m-0">Дозволи статуса</div>
                        <div className="field col-12 md:col-4 flex m-0">Час статуса</div>
                        <div className="field col-12 md:col-12 p-0 pb-1" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                        <div className="field col-12 md:col-12 flex m-0 scroll-detail-items">
                            <div className="field col-12 md:col-4 flex flex-column m-0">{statusData?.statusBranches?.length ? branchItems : '- - -'}</div>
                            <div className="field col-12 md:col-4 flex m-0 ">
                                <div className="field col-12 md:col-12 flex-column mb-0 p-0">
                                    <label htmlFor="name" className="mb-2">Дзвiнки:</label>
                                    <div className="flex align-items-center mb-2">
                                        <Checkbox
                                            name="category"
                                            disabled
                                            checked={statusData?.callInboundExternal}
                                            onChange={() => {
                                                dispatch(setStatusField({section: 'statusData', fieldName: 'callInboundExternal', value: !statusData?.callInboundExternal}));
                                                if (!!statusData?.callInboundExternal) {
                                                    dispatch(setStatusField({section: 'statusData', fieldName: 'isCallAuto', value: false}));
                                                }
                                            }}
                                        />
                                        <label htmlFor='Вхiднi зовн.' className="ml-2">
                                            Вхiднi зовнiшнi
                                        </label>
                                    </div>
                                    <div className="flex align-items-center mb-2">
                                        <Checkbox
                                            name="callOutboundInternal"
                                            checked={statusData?.callOutboundExternal}
                                            onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'callOutboundExternal', value: !statusData?.callOutboundExternal}))}
                                            disabled
                                        />
                                        <label htmlFor='Вихiднi зовн.' className="ml-2">
                                            Вихiднi зовнiшнi
                                        </label>
                                    </div>
                                    <div className="flex align-items-center mb-2">
                                        <Checkbox
                                            name="category"
                                            checked={statusData?.callInboundInternal}
                                            onChange={() => {
                                                dispatch(setStatusField({section: 'statusData', fieldName: 'callInboundInternal', value: !statusData?.callInboundInternal}));
                                            }}
                                            disabled
                                        />
                                        <label htmlFor='Вхiднi внутр' className="ml-2">
                                            Вхiднi внутрішні
                                        </label>
                                    </div>
                                    <div className="flex align-items-center mb-2">
                                        <Checkbox
                                            name="callOutboundInternal"
                                            checked={statusData?.callOutboundInternal}
                                            onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'callOutboundInternal', value: !statusData?.callOutboundInternal}))}
                                            disabled
                                        />
                                        <label htmlFor='Вихiднi внутр.' className="ml-2">
                                            Вихiднi внутрішні
                                        </label>
                                    </div>
                                    <div className="flex align-items-center mb-2">
                                        <Checkbox
                                            name="callDialer"
                                            checked={statusData?.callDialer}
                                            onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'callDialer', value: !statusData?.callDialer}))}
                                            disabled
                                        />
                                        <label htmlFor='Вихiднi внутр.' className="ml-2">
                                            Дайлер
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <div className="field col-12 md:col-4 flex m-0">
                                <div className="field col-4 md:col-4">
                                    <label>Годин</label>
                                    <InputText
                                        id="name"
                                        keyfilter="num"
                                        maxLength={2}
                                        disabled
                                        className="w-full opacity-100"
                                        value={timeInHoursMinutesSeconds.hours || ''}
                                    />
                                </div>
                                <div className="field col-12 md:col-4">
                                    <label>Хвилин</label>
                                    <InputText
                                        id="name"
                                        keyfilter="num"
                                        maxLength={2}
                                        disabled
                                        className="w-full opacity-100"
                                        value={timeInHoursMinutesSeconds.minutes}
                                    />
                                </div>
                                <div className="field col-12 md:col-4">
                                    <label>Секунд</label>
                                    <InputText
                                        id="name"
                                        value={timeInHoursMinutesSeconds.remainingSecondsFinal}
                                        keyfilter="num"
                                        className="w-full opacity-100"
                                        maxLength={2}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    {windowWidth <= 1248 && <div className="field col-12 md:col-12 flex-column mb-0">
                        <label htmlFor="name" className="font-bold">Дозволи статуса:</label>
                    </div>}

                    {windowWidth <= 1248 && <div className="field col-12 md:col-6 flex-column mb-0">
                        <label htmlFor="name" className="mb-2">Дзвiнки:</label>
                        <div className="flex align-items-center mb-2">
                            <Checkbox
                                name="category"
                                disabled
                                checked={statusData?.callInboundExternal}
                                onChange={() => {
                                    dispatch(setStatusField({section: 'statusData', fieldName: 'callInboundExternal', value: !statusData?.callInboundExternal}));
                                    if (!!statusData?.callInboundExternal) {
                                        dispatch(setStatusField({section: 'statusData', fieldName: 'isCallAuto', value: false}));
                                    }
                                }}
                            />
                            <label htmlFor='Вхiднi зовн.' className="ml-2">
                                Вхiднi зовнiшнi
                            </label>
                        </div>
                        <div className="flex align-items-center mb-2">
                            <Checkbox
                                name="callOutboundInternal"
                                checked={statusData?.callOutboundExternal}
                                onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'callOutboundExternal', value: !statusData?.callOutboundExternal}))}
                                disabled
                            />
                            <label htmlFor='Вихiднi зовн.' className="ml-2">
                                Вихiднi зовнiшнi
                            </label>
                        </div>
                        <div className="flex align-items-center mb-2">
                            <Checkbox
                                name="category"
                                checked={statusData?.callInboundInternal}
                                onChange={() => {
                                    dispatch(setStatusField({section: 'statusData', fieldName: 'callInboundInternal', value: !statusData?.callInboundInternal}));
                                }}
                                disabled
                            />
                            <label htmlFor='Вхiднi внутр' className="ml-2">
                                Вхiднi внутрішні
                            </label>
                        </div>
                        <div className="flex align-items-center mb-2">
                            <Checkbox
                                name="callOutboundInternal"
                                checked={statusData?.callOutboundInternal}
                                onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'callOutboundInternal', value: !statusData?.callOutboundInternal}))}
                                disabled
                            />
                            <label htmlFor='Вихiднi внутр.' className="ml-2">
                                Вихiднi внутрішні
                            </label>
                        </div>
                        <div className="flex align-items-center mb-2">
                            <Checkbox
                                name="callDialer"
                                checked={statusData?.callDialer}
                                onChange={() => dispatch(setStatusField({section: 'statusData', fieldName: 'callDialer', value: !statusData?.callDialer}))}
                                disabled
                            />
                            <label htmlFor='Вихiднi внутр.' className="ml-2">
                                Дайлер
                            </label>
                        </div>
                    </div>}
                    {windowWidth <= 1248 && <div className="field col-12 md:col-12 flex-column flex-wrap mb-0">
                        <div className="field col-12 md:col-12 px-0"><div><label htmlFor="name" className="font-bold text-base">Час статуса:</label></div></div>
                        <div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                        <div className="field col-12 md:col-12 flex m-0 px-0">
                            <div className="field col-4 md:col-4 px-0">
                                <label>Годин</label>
                                <InputText
                                    id="name"
                                    keyfilter="num"
                                    maxLength={2}
                                    disabled
                                    className="w-full opacity-100"
                                    value={timeInHoursMinutesSeconds.hours || ''}
                                />
                            </div>
                            <div className="field col-12 md:col-4">
                                <label>Хвилин</label>
                                <InputText
                                    id="name"
                                    keyfilter="num"
                                    maxLength={2}
                                    disabled
                                    className="w-full opacity-100"
                                    value={timeInHoursMinutesSeconds.minutes}
                                />
                            </div>
                            <div className="field col-12 md:col-4">
                                <label>Секунд</label>
                                <InputText
                                    id="name"
                                    value={timeInHoursMinutesSeconds.remainingSecondsFinal}
                                    keyfilter="num"
                                    className="w-full opacity-100"
                                    maxLength={2}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-12 px-0"><div><label htmlFor="name" className="font-bold text-base">Бранч:</label></div></div>
                        <div className="field col-12 md:col-12 p-0" style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                        <div className="field col-12 md:col-12 flex m-0 flex-column px-0">{statusData?.statusBranches?.length ? branchItems : '- - -'}</div>

                    </div>}
                    {/*{windowWidth <= 1248 && <div className="field col-12 md:col-6 flex-column mb-0">*/}
                    {/*    <label htmlFor="name" className="mb-2"></label>*/}
                    {/*    <div className="flex align-items-center mt-2">*/}
                    {/*        <Checkbox disabled checked={statusData?.isCallAuto}/>*/}
                    {/*        <label htmlFor="ingredient1" className="ml-2" style={{wordWrap: 'break-word', maxWidth: '80%'}}>Автопризначення</label>*/}
                    {/*    </div>*/}
                    {/*</div>}*/}
                </div>
            </div>
        </div>
    )
}

export default StatusesListDetails;
