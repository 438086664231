import React, {useEffect, useRef, useState} from "react";
import {useAppSelector, useAppDispatch} from 'redux/hooks'
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {TabPanel, TabView} from "primereact/tabview";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {validateStatusForm} from "../../../redux/validator/validator";
import StatusesListDialogGeneralSettings from "../StatusesListDialogGeneralSettings";
import {setStatusDialogVisible, setStatusErrorFields} from "../../../redux/actions/actionsStatuses";
import StatusParams from "../StatusParams";
import StatusesListDialogInfo from "../StatusesListDialogInfo";
import {archiveStatus, createStatus, updateStatus} from "../../../redux/api/apiStatusesList";
import {ConfirmDialog} from "primereact/confirmdialog";
import {getItem} from "../../../redux/cache/cacheService";
import {codes} from "../../../redux/notificationCodes";
import {ProgressBar} from "primereact/progressbar";
import {getNotification} from "../../../redux/api/api";
import {getUsersManagerUserBranchesData} from "../../../redux/api/apiUsersList";

const StatusListDialog = (props: any) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.UsersReducer.userData);
    const toast = useRef<Toast>(null);
    const toastBC = useRef<Toast>(null);
    const [activeIndex, setActiveIndex] = useState(0)
    // @ts-ignore
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const statusData = useAppSelector(state => state.StatusesListReducer.statusData) as unknown as any;
    const statusDialogVisible = useAppSelector(state => state.StatusesListReducer.statusDialogVisible);
    const rolesPermissions = checkPermissions(rolesVision, 'Status');
    const dataSort = useAppSelector(state => state.StatusesListReducer.dataSort);
    const archiveStatusTableToggle = useAppSelector(state => state.StatusesListReducer.archiveStatusTableToggle);
    const notifications = useAppSelector(state => state.NotificationsReducer);
    const statusTime = useAppSelector(state => state.StatusesListReducer.statusTime);
    let inSystem = statusData?.inSystem;
    const [visible, setVisible] = useState<boolean>(false);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const {
        create: hasRoleCreatePermission,
        update: hasRoleUpdatePermission,
        visible: hasRoleVisionPermission,
        delete: hasRoleDeletePermission,
    } = rolesPermissions;

    let cancelNotification = getItem(codes.archive_status_question);
    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    const deleteCampaignMessage = <div>{notifications && notifications.archiveStatusMessage ? notifications.archiveStatusMessage.text : cancelNotification ? cancelNotification : progressBar}</div>


    useEffect(() => {
        setActiveIndex(0);
        // dispatch(getUsersManagerUserBranchesData(jwtToken?.jwtToken));
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const showSaveChangesNotification = () => {
        dispatch(setStatusDialogVisible(false));
        setActiveIndex(0);
    }

    const deletionToastShow = () => {
        if (!cancelNotification) {
            dispatch(getNotification(user.userId, 153, user));
        }
        setVisible(true);
    }


    const createStatusData = (data: any) => {
        let updatedStatusData =  data;
        if (statusTime) {
            updatedStatusData = { ...data, timeStatus: statusTime };
        }
        const validationErrors = validateStatusForm(updatedStatusData);
        dispatch(setStatusErrorFields(validationErrors));
        if (!validationErrors) {
            dispatch(createStatus(updatedStatusData, jwtToken?.jwtToken, dataSort));
        }
        if (validationErrors?.name || validationErrors?.branchId) {
            setActiveIndex(0);
        }
        if (!validationErrors?.name && validationErrors?.timeStatus) {
            setActiveIndex(1);
        }
    };

    const updateStatusData = (data: any) => {
        let updatedStatusData =  data;
        if (statusTime) {
            updatedStatusData = { ...data, timeStatus: statusTime };
        }
        const validationErrors = validateStatusForm(updatedStatusData);
        dispatch(setStatusErrorFields(validationErrors));
        if (!validationErrors) {
            if (props?.branchId) {
                dispatch(updateStatus(updatedStatusData, jwtToken?.jwtToken, dataSort, props?.branchId));
            } else {
                dispatch(updateStatus(updatedStatusData, jwtToken?.jwtToken, dataSort));
            }
        }
        if (validationErrors?.name || validationErrors?.branchId) {
            setActiveIndex(0);
        }
        if (!validationErrors?.name && validationErrors?.timeStatus) {
            setActiveIndex(1);
        }
    };

    const footer = (
        <div className="dialog-footer">
            <Divider/>
            <Toast ref={toast}/>
            <Toast ref={toastBC} position="center"/>
            <div className="buttons">
                {statusData?.id && archiveStatusTableToggle && hasRoleDeletePermission && !inSystem && <Button icon={windowWidth > 1248 ? "pi pi-trash" : ''}
                                                                                                className="p-button-outlined"
                                                                                                label="Видалити"
                                                                                                onClick={() => {
                                                                                                    deletionToastShow();
                                                                                                }}
                />}
                <Button icon={windowWidth > 1248 ? "pi pi-ban" : ''}
                        className="p-button-outlined"
                        label="Відміна"
                        onClick={() => {
                            dispatch(setStatusDialogVisible(false));
                        }
                }
                />
                {!statusData?.id && archiveStatusTableToggle && hasRoleCreatePermission && !inSystem && <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                                                 className=""
                                                                                                 label="Створити та зберегти"
                                                                                                 onClick={() => {
                                                                                                     createStatusData(statusData);
                                                                                                 }
                }
                />}
                {statusData?.id && archiveStatusTableToggle && hasRoleUpdatePermission && !inSystem ? <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                                                label="Зберегти"
                                                                                                onClick={() => {
                                                                                                    updateStatusData(statusData);
                                                                                                }}
                /> : ''}
            </div>
        </div>
    );

    const bodyHeader = () => {
        return <StatusesListDialogInfo/>;
    }

    const createdUserTime = () => {
        const time = new Date(statusData?.statusData?.createdDateTime || '').toLocaleString("uk-UA")
        if (statusData?.statusData?.createdDateTime) {
            return time;
        } else {
            return '-'
        }
    }
    const updatedUserTime = () => {
        const time = new Date(statusData?.statusData?.updatedDateTime || '').toLocaleString("uk-UA")
        if (statusData?.statusData?.updatedDateTime) {
            return time;
        } else {
            return '-'
        }
    }

    const deleteCampaign = () => {
        if (user.userId) {
            const archiveStatusData = {
                statusId: statusData?.id,
                value: true
            };
            dispatch(archiveStatus(archiveStatusData, jwtToken?.jwtToken));
        }
    };

    return (
        <Dialog
            header={bodyHeader}
            footer={!hasRoleVisionPermission ? null : footer}
            visible={statusDialogVisible}
            dismissableMask={false}
            closeOnEscape={false}
            draggable={true}
            blockScroll={true}
            position="top"
            style={{width: '95vw', maxWidth: "1200px", minHeight: "80vh"}}
            onHide={() => showSaveChangesNotification()}
        >
            {statusData && <div className="company-info-popup">
                <div className="grid align-items-center">
                    <div className="field col-12 md:col-6">
                        {!!statusData.id && <div className="item pl-2">
                            ID: {statusData?.id ? statusData?.id : ''}
                        </div>}
                    </div>
                    <div className="field col-12 md:col-6 flex justify-content-end mb-0">
                        {!!statusData.id && <div className="field col-12 md:col-12 flex flex-wrap p-0 m-0">
                            {statusData?.statusData?.createdByUserId && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                                <div className="field col-12 md:col-2 flex p-0 m-0">Створено:</div>
                                <div className="field col-12 md:col-6 flex p-0 m-0">{statusData?.statusData?.userCreate ? statusData?.statusData?.userCreate : '-'}</div>
                                <div className="field col-12 md:col-4 flex p-0 m-0">{createdUserTime()}</div>
                            </div>}
                            {statusData?.statusData?.updatedByUserId && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                                <div className="field col-12 md:col-2 flex p-0 m-0">Оновлено:</div>
                                <div className="field col-12 md:col-6 flex p-0 m-0">{statusData?.statusData?.userUpdate ? statusData?.statusData?.userUpdate : '-'}</div>
                                <div className="field col-12 md:col-4 flex p-0 m-0">{updatedUserTime()}</div>
                            </div>}
                        </div>}
                    </div>
                </div>
                <TabView className="user-dialog-info" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Загальні налаштування">
                        {statusData && <StatusesListDialogGeneralSettings/>}
                    </TabPanel>
                    <TabPanel header="Параметри статуса">
                        <StatusParams/>
                    </TabPanel>
                </TabView>
            </div>}
            <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={deleteCampaignMessage}
                           icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => deleteCampaign()}/>
        </Dialog>
    )
}

export default StatusListDialog
