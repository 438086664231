import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {classNames} from "primereact/utils";
import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {InputSwitch} from "primereact/inputswitch";
import {addRolesGroupBranch, deleteRoleBranch, setRoleField} from "../../../redux/actions/actionsRoles";
import {MultiSelect} from "primereact/multiselect";
import {Button} from "primereact/button";
import {setBranchDialogVisible} from "../../../redux/actions/actionsBranchList";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {getBranchDetailById} from "../../../redux/api/apiBranchList";
import {setEditType} from "../../../redux/actions/actions";

const RolesListDialogGeneralSettings = () => {
    const dispatch = useAppDispatch();
    const errorFields = useAppSelector(state => state.RolesReducer.errorFields) as unknown as any;
    const descriptionField = useRef(null);
    const nameField = useRef(null);
    const rolesData = useAppSelector(state => state.RolesReducer.rolesData) as unknown as any;
    const editType = useAppSelector(state => state.UsersReducer.editType);
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);
    const archiveTableToggle = useAppSelector(state => state.RolesReducer.archiveRolesTableToggle)
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const branchPermissions = checkPermissions(rolesVision, 'Branch');
    const branchDetailsLoader = useAppSelector(state => state.BranchesListReducer.branchDetailsLoader);
    const [currentUserId, setCurrentUserId] = useState<any>(null);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    const {
        visible: hasBranchVisionPermission,
        update: hasBranchUpdatePermission
    } = branchPermissions;

    useEffect(() => {
        dispatch(setBranchDialogVisible(false));
    }, []);

    const scrollIntoNameView = () => {
        // @ts-ignore
        nameField?.current?.scrollIntoView({behavior: 'smooth'});
    }

    const scrollIntoDescriptionView = () => {
        // @ts-ignore
        descriptionField?.current?.scrollIntoView({behavior: 'smooth'});
    }

    const formattedBranches = branches?.map((branch: { id: any; name: any; }) => ({
        ...branch,
        label: `${branch.id} ${branch.name}`,
    }));

    const branchesValue = rolesData?.roleBranches?.map((companiesItem: any) => {
        const user = formattedBranches.find((userItem: any) => userItem.id === companiesItem.id);
        const {rolesId, ...userWithoutCampaignId} = companiesItem;
        return {
            ...userWithoutCampaignId,
            description: user?.description,
            id: user?.id,
            isArchived: user?.isArchived,
            name: user?.name,
            label: user?.label,
            ownerId: user?.ownerId,
            ownerName: user?.ownerName,
            parentId: user?.parentId,
            parentName: user?.parentName,
            userCount: user?.userCount,
            integrationURLId: user?.integrationURLId,
            isMainIntegrationUrl: user?.isMainIntegrationUrl,
            mainBranchId: user?.mainBranchId
        }
    });

    useEffect(() => {
        // @ts-ignore
        if (!rolesData.name && errorFields?.name) {
            scrollIntoNameView();
        }
        // @ts-ignore
        if (!rolesData.description && errorFields?.description) {
            scrollIntoDescriptionView();
        }
    }, [errorFields, rolesData]);

    const onChangeNameField = (e: any) => {
        dispatch(setRoleField({section: 'rolesData', fieldName: 'name', value: e.target.value}));
    }

    const changeRolesBranches = (e: any) => {
        const filteredEValue = e.value?.filter((item: { id: number; }) => item.id);
        const existingIds = new Set(filteredEValue.map((item: { id: number; }) => item.id));
        const skillGroupData = new Set(formattedBranches.map((item: { id: number; }) => item.id));
        const newItems = rolesData && rolesData.roleBranches
            ? rolesData.roleBranches.filter((item: { id: number; }) =>
                !existingIds.has(item.id) && !skillGroupData.has(item.id))
            : [];
        const updatedEValue = [...filteredEValue, ...newItems];

        dispatch(addRolesGroupBranch({ roleId: rolesData?.id, id: updatedEValue }));
    }

    const branchItems = rolesData?.roleBranches?.map((queueItem: any) => {
        return formattedBranches?.map((branchItem: any, index: number) => {
            if (queueItem.id === branchItem.id) {
                return (
                    <div key={index} className="flex align-items-center mb-2">
                        {hasBranchVisionPermission && <Button
                            className="max-w-4rem p-button-secondary"
                            style={{minWidth: '40px'}}
                            loading={branchDetailsLoader && currentUserId === branchItem.id}
                            disabled={!archiveTableToggle || branchDetailsLoader}
                            icon={archiveTableToggle && hasBranchUpdatePermission ? 'pi pi-pencil' : !archiveTableToggle || !hasBranchUpdatePermission ? 'pi pi-eye' : ''}
                            onClick={() => {
                                setCurrentUserId(branchItem.id);
                                dispatch(getBranchDetailById(branchItem.id, jwtToken?.jwtToken, true));
                                if (hasBranchUpdatePermission) {
                                    dispatch(setEditType(true));
                                } else {
                                    dispatch(setEditType(false));
                                }
                            }}/>}
                        <div className="field col-12 md:col-9 flex align-items-center m-0">
                            {branchItem.label}
                        </div>
                        <div className="field col-12 md:col-2 flex align-items-center justify-content-end m-0 p-0 pr-2">
                            <Button name={branchItem.id}
                                    onClick={() => {
                                        dispatch(deleteRoleBranch(branchItem.id));
                                    }}
                                    icon="pi pi-times"
                                    className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2 m-0"
                                    disabled={!archiveTableToggle || !editType}
                            />
                        </div>
                    </div>
                )
            }
        })
    })

    return (
        <div className="general-settings">
            <div className="grid p-fluid">
                <div className="field col-12 md:col-6" ref={nameField}>
                    <label htmlFor="name" className={classNames('', {'p-error': errorFields?.name})}>Назва ролі *</label>
                    <InputText
                        id="name"
                        value={rolesData?.name || ''}
                        maxLength={50}
                        className={classNames('', {'p-invalid': errorFields?.name})}
                        onChange={e => {
                            onChangeNameField(e);
                        }}
                        disabled={!archiveTableToggle || !rolesData?.isEdit || !editType}
                    />
                    {errorFields?.name && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span className="title p-error text-sm">{errorFields?.name?.name}*</span></div>}               </div>
                <div className="field col-12 md:col-6"></div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="description" className={classNames('', {'p-error': errorFields?.description})}>Коментар</label>
                    <InputTextarea
                        id="description"
                        value={rolesData?.description || ''}
                        onChange={e => {
                            dispatch(setRoleField({section: 'rolesData', fieldName: 'description', value: e.target.value}))
                        }}
                        className={classNames('', {'p-invalid': errorFields?.description})}
                        autoResize
                        rows={3}
                        maxLength={500}
                        disabled={!archiveTableToggle || !rolesData?.isEdit || !editType}
                    />
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
                <div className="field col-12 md:col-4 flex flex-column">
                    <label htmlFor="name" className={classNames('', {'p-error': errorFields?.branchId && !rolesData?.branchId})}>Бранч *</label>
                    <MultiSelect
                        appendTo="self"
                        value={branchesValue}
                        options={formattedBranches}
                        className={classNames('', {'p-invalid': errorFields?.branchId})}
                        onChange={(e) => {
                            changeRolesBranches(e);
                        }}
                        placeholder="Оберіть бранч"
                        filter
                        optionLabel="label"
                        selectedItemsLabel={branchesValue ? `Обрано: ${branchesValue?.length}` : ""}
                        maxSelectedLabels={0}
                        scrollHeight="250px"
                        disabled={!archiveTableToggle || !rolesData?.isEdit || !editType}
                    />
                    {
                        branchesValue?.length ?
                            <div className="users-list mt-3">
                                {branchItems}
                            </div>
                            :
                            <>

                                {
                                    errorFields?.branchId ? <span className={classNames('', {'block title p-error text-sm mt-4': errorFields?.branchId})}>Нічого не вибрано</span> :
                                        <span className={classNames('no-users-message mt-4')}>Нічого не вибрано</span>
                                }
                            </>

                    }
                </div>
                <div className="field col-12 md:col-6"></div>
            </div>
            <div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
                <div>
                    <label htmlFor="name" className="font-bold text-base">Стан</label>
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
                <div>
                    <div className="field-radiobutton my-4 pl-2">
                        <label htmlFor="name" className="mr-2">Неактивна</label>
                        <InputSwitch
                            disabled={!archiveTableToggle || !rolesData?.isEdit || !editType}
                            checked={rolesData?.active} onChange={(e) => {
                            dispatch(setRoleField({section: 'rolesData', fieldName: 'active', value: e.value}));
                        }}/>
                        <label htmlFor="icon">Активна</label>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RolesListDialogGeneralSettings;
