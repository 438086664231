import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {checkPermissions} from "../../redux/permissions/permissionsUtils";
import {RoleDataSort} from "../../types/types";
import {getItem} from "../../redux/cache/cacheService";
import {codes} from "../../redux/notificationCodes";
import {ProgressBar} from "primereact/progressbar";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {classNames} from "primereact/utils";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {ConfirmDialog} from "primereact/confirmdialog";
import RolesListNav from "./RolesListNav";
import {archiveRole, copyRoleById, getRoleDetails, getRolesDictionary, getRolesListWithPaginationFilterAndSorting} from "../../redux/api/apiRoles";
import {setCurrentPage, setCurrentPageSize, setRoleArchiveTableToggle, setRoleDialogVisible, setRoleErrorFields, setRoleListDataSort, setSelectedRolesIndex} from "../../redux/actions/actionsRoles";
import RolesListDetails from "./RolesListDetails";
import RolesListDialog from "./RolesListDialog";
import {Paginator} from "primereact/paginator";
import {Dropdown} from "primereact/dropdown";
import {getNotificationMessage} from "../../redux/api/apiNotifications";
import {setEditType} from "../../redux/actions/actions";
import BranchDialog from "../Branch/BranchDialog";
import {getQualificationGroupListWithPaginationFilterAndSorting} from "../../redux/api/apiQualificationGroup";

const RolesList = () => {
    const dispatch = useAppDispatch();
    const clearFilters = useAppSelector(state => state.RolesReducer.clearFilters);
    const companies = useAppSelector(state => state.CompaniesListReducer);
    const pagination = useAppSelector(state => state.RolesReducer.pagination);
    const paginationPage = useAppSelector(state => state.RolesReducer.paginationPage);
    const paginationSize = useAppSelector(state => state.RolesReducer.paginationSize);
    const archiveRolesTableToggle = useAppSelector(state => state.RolesReducer.archiveRolesTableToggle);
    const loader = useAppSelector(state => state.RolesReducer.loading);
    const [visible, setVisible] = useState<boolean>(false);
    const [mobileVisible, setMobileVisible] = useState<boolean>(false);
    // const [cancelNotificationStatusChange, setCancelNotificationStatusChange] = useState<boolean>(false);
    const [showCopyNotification, setShowCopyNotification] = useState<boolean>(false);
    const [id, setId] = useState<number>(0);
    const toast = useRef<Toast>(null);
    const [first, setFirst] = useState(0);
    const [data] = useState<any>(null);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [isDescFilter, setIsDescFilter] = useState<boolean>(true);
    const [filterRoleId, setFilterRoleId] = useState<any>(null);
    const [filterRoleName, setFilterRoleName] = useState<any>(null);
    const [filterStatusId, setFilterStatusId] = useState<any>(null)
    const [sortOrder, setSortOrder] = useState<number>(0);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const rolePermissions = checkPermissions(rolesVision, 'Role');
    const [page, setPage] = useState<any>(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const rolesList = useAppSelector(state => state.RolesReducer.rolesList) as unknown as any;
    const rolesData = useAppSelector(state => state.RolesReducer.rolesData) as unknown as any;
    const notifications = useAppSelector(state => state.NotificationsReducer);
    const dataSort = useAppSelector(state => state.RolesReducer.dataSort);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [showUnArchiveNotification, setShowUnArchiveNotification] = useState<boolean>(false);
    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    const [sortData, setSortData] = useState<any>({
        isArchived: !archiveRolesTableToggle,
        pageNumber: page ? page : paginationPage,
        pageSize: paginationSize,
        sort: {
            orderBy: orderBy ? orderBy : "Id",
            isDesc: isDescFilter
        }
    });
    // @ts-ignore
    const activeClick = useAppSelector(state => state.UsersListReducer.activeClick);
    const {
        create: hasRoleCreatePermission,
        update: hasRoleUpdatePermission,
        delete: hasRoleDeletePermission,
    } = rolePermissions;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(setCurrentPage(1));
    }, []);

    useEffect(() => {
        dispatch(setCurrentPageSize(10));
        dispatch(setRoleArchiveTableToggle(true));
        dispatch(getRolesDictionary(jwtToken?.jwtToken));
    }, []);

    useEffect(() => {
        setSelectedRowData(null);
        let filter: { key: string; value: string }[] = [];
        const dataSort: RoleDataSort = {
            isArchived: !archiveRolesTableToggle,
            pageNumber: page ? page : paginationPage,
            pageSize: paginationSize,
            sort: {
                orderBy: orderBy ? orderBy : "Id",
                isDesc: isDescFilter
            }
        }

        if (filterRoleId?.value) {
            filter.push(filterRoleId);
        }
        if (filterRoleName?.value) {
            filter.push(filterRoleName);
        }
        if (filterStatusId) {
            dataSort.active = filterStatusId?.value
        }
        if (filter) {
            dataSort['filter'] = filter;
        }
        dispatch(getRolesListWithPaginationFilterAndSorting(dataSort, jwtToken?.jwtToken));
        dispatch(setRoleListDataSort(dataSort));
        setPage(null);
        setSortData(dataSort);
    }, [paginationPage, paginationSize, data, archiveRolesTableToggle, orderBy, isDescFilter, filterRoleId, filterRoleName, filterStatusId]);

    useEffect(() => {
        if (activeClick?.item === "/role" && activeClick?.from ==='#/role') {
            dispatch(getRolesListWithPaginationFilterAndSorting(sortData, jwtToken?.jwtToken));
        }
    }, [activeClick]);

    let archiveRoleNotification = getItem(codes.archive_role_question);
    let copyNotification = getItem(codes.copy_role);

    // const cancelMessage = <div>{companies && companies.notificationCancel ? companies.notificationCancel.text : cancelNotification ? cancelNotification : progressBar}</div>
    // @ts-ignore
    const copyRoleMessage = <div>{notifications && notifications.copyRoleMessage ? notifications.copyRoleMessage.text : copyNotification ? copyNotification : progressBar}</div>
    const archivedRoleNotificationMessage = <div>{notifications && notifications.archiveRoleMessage ? notifications.archiveRoleMessage ? notifications.archiveRoleMessage?.text : archiveRoleNotification : archiveRoleNotification}</div>


    useEffect(() => {
        setFirst(0);
        dispatch(setCurrentPageSize(10));
        dispatch(setCurrentPage(1));
    }, [archiveRolesTableToggle]);


    const copyRoleMethod = () => {
        if (jwtToken?.jwtToken) {
            dispatch(copyRoleById(id, jwtToken?.jwtToken, dataSort));
        }
    }

    const archiveRoleAction = () => {
        if (jwtToken?.jwtToken) {
            dispatch(archiveRole(id, jwtToken?.jwtToken, dataSort));
        }
    }

    const actionTemplate = (rowData: any) => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        let archiveRoleNotification = getItem(codes.archive_role_question);
        const deletionToastShow = (id: number) => {
            if (!archiveRoleNotification) {
                dispatch(getNotificationMessage(user.userId, 144, user));
            }
            setVisible(true);
            setId(id);
        }

        const copyToastShow = (Id: number) => {
            if (!copyNotification) {
                dispatch(getNotificationMessage(user.userId, 200, user));
            }
            setShowCopyNotification(true);
            setId(Id);
        }

        const unArchiveToastShow = (Id: number) => {
            setShowUnArchiveNotification(true);
            setId(Id);
        }

        return (
            <div className="flex">
                <div className="controls-buttons">
                    {hasRoleCreatePermission && (<Button className="p-button-text p-button-icon" onClick={() => copyToastShow(rowData.id)} icon="pi pi-copy"></Button>)}
                </div>
                {archiveRolesTableToggle && hasRoleDeletePermission && <Button className="p-button-text" icon="pi pi-trash" disabled={!rowData.isEdit} onClick={() => deletionToastShow(rowData.id)}></Button>}
                {!archiveRolesTableToggle && hasRoleCreatePermission && <Button className="p-button-text" icon="pi pi-folder-open" onClick={() => unArchiveToastShow(rowData.id)}></Button>}
            </div>
        );
    };

    const filterClearTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} className="p-button-secondary"></Button>;
    }

    const filterApplyTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} className=""></Button>
    }

    const dataTableRef = useRef<any>(null);

    const handleReset = () => {
        dataTableRef?.current?.reset();
        customFilter();
        setSortOrder(0);
        dispatch(setCurrentPageSize(10));
        dispatch(setCurrentPage(1));
        setFirst(0);
    };

    const onPageChange = (event: any) => {
        setFirst(event.first);
        dispatch(setCurrentPage(event.page + 1));
        dispatch(setCurrentPageSize(event.rows));
    };

    const customSort = (event: any) => {
        const handleAction = (sortField: string) => {
            let newOrderBy: string;

            switch (sortField) {
                case 'id':
                    newOrderBy = 'Id';
                    break;
                case 'name':
                    newOrderBy = 'Name';
                    break;
                case 'active':
                    newOrderBy = 'Active';
                    break;
                default:
                    return;
            }

            setOrderBy(newOrderBy);
            setIsDescFilter(!isDescFilter);
            setSortOrder(sortOrder === 1 ? -1 : 1);
        };
        let sortField = event.sortField;
        handleAction(sortField);
    };


    const customFilter = (event?: any) => {
        if (event) {
            const {id, name, active} = event.filters;
            setFilterRoleId(id ? {key: 'Id', value: id.constraints[0].value} : null);
            setFilterRoleName(name ? {key: 'Name', value: name.constraints[0].value} : null);
            setFilterStatusId(active ? {key: 'Active', value: active.value ? active.value.code : null} : null);
        } else {
            setFilterRoleId(null);
            setFilterRoleName(null);
            setFilterStatusId(null);
            setOrderBy(null);
            setIsDescFilter(true);
        }
    };

    const renderHeader = (field: string, sortField: string) => {
        const sorted = sortField === orderBy;
        const sortIcon = classNames('p-sortable-column-icon', {
            'pi pi-sort-amount-up-alt': sorted && sortOrder === 1,
            'pi pi-fw pi-sort-amount-down': sorted && sortOrder === -1,
            'pi pi-fw pi-sort-alt': sortOrder === 0 || !sorted
        });

        return (
            <div className="flex align-items-center sticky top-0" onClick={() => customSort({'sortField': sortField})}>
                {field} <i className={sortIcon}></i>
            </div>
        );
    };

    const isMobile = () => {
        return windowWidth <= 1248;
    }

    const openEditCampaignDialog = () => {
        dispatch(setSelectedRolesIndex(rolesData?.id || selectedRowData));
        dispatch(setRoleDialogVisible(true));
        setSelectedRowData(null);
        if (hasRoleUpdatePermission) {
            dispatch(setEditType(true));
        } else {
            dispatch(setEditType(false));
        }
    }

    const dialogHeader = () => {
        return <>
            <div className="flex align-items-center justify-content-end m-0 p-0 mr-1">
                <Button className="p-dialog-header-icon p-dialog-header-close p-link border-noround	outline-none shadow-none h-full justify-content-end" onClick={() => {
                    openEditCampaignDialog();
                }}>
                    <span className={classNames('', {
                        'pi pi-pencil': archiveRolesTableToggle && hasRoleUpdatePermission,
                        'pi pi-eye': !archiveRolesTableToggle || !hasRoleUpdatePermission
                    })}></span></Button>
            </div>
        </>
    }

    const activeDict = [
        {name: 'Активна', code: true},
        {name: 'Неактивна', code: false},
    ];

    const activeFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Виберіть:</div>
                <Dropdown
                    value={options.value}
                    options={activeDict}
                    // itemTemplate={activeItemTemplate}
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    optionLabel="name"
                    className="p-column-filter"
                />
            </>
        );
    };

    return (
        <div className="layout-dashboard">
            <Toast ref={toast}/>
            <div className="">
                <div className="flex flex-wrap col-12 md:col-12 p-0">
                    {/*<div className="card widget-table">*/}
                    <div className={classNames('card widget-table col-12 transition-duration-300 monitoring-table align-self-start m-0 mr-0', {'': selectedRowData && !isMobile(), 'md:col-12': !selectedRowData})}>
                        <RolesListNav handleReset={handleReset}/>
                        <DataTable
                            ref={dataTableRef}
                            scrollable
                            scrollHeight={(selectedRowData) && !isMobile() ? '35vh' : '70vh'}
                            className="p-datatable-customers"
                            value={archiveRolesTableToggle ? rolesList : rolesList}
                            dataKey="id"
                            filters={clearFilters}
                            rowHover={false}
                            selectionMode="single"
                            selection={selectedRowData}
                            onSort={customSort}
                            onFilter={customFilter}
                            emptyMessage="Нічого не знайдено"
                            responsiveLayout='scroll'
                            onRowClick={(e) => {
                                setSelectedRowData(e.data.id);
                                dispatch(getRoleDetails(e.data.id, jwtToken?.jwtToken));
                                dispatch(setSelectedRolesIndex(e.data.id));
                                dispatch(setRoleErrorFields(null));
                                setMobileVisible(true);
                                if (isMobile()) {
                                    setMobileVisible(true);
                                }
                            }}
                            loading={loader}
                            rowClassName={(rowData) =>
                                rowData?.id === selectedRowData ? 'p-highlight' : ''
                            }
                        >
                            <Column
                                field="id"
                                filterField="id"
                                header={renderHeader('ID', 'Id')}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть ID:"
                                filter
                                sortable
                                className={classNames('', {'active-header-column': orderBy === 'CampaignId'})}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '10%', minWidth: '90px'}}
                            />
                            <Column
                                field="name"
                                filterField="name"
                                header={renderHeader('Назва', 'Name')}
                                className={classNames('', {'active-header-column': orderBy === 'Name'})}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть назву кампанії"
                                filter
                                sortable
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: (hasRoleCreatePermission || hasRoleDeletePermission) ? '50%' : '70%', minWidth: '150px'}}
                            />
                            <Column
                                field="active"
                                filterField="active"
                                header={renderHeader('Стан', 'Active')}
                                className={classNames('', {'active-header-column': orderBy === 'Active'})}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть назву кампанії"
                                filter
                                body={data => data?.active ? 'Активна' : 'Не активна'}
                                sortable
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                filterElement={activeFilterTemplate}
                                style={{cursor: 'pointer', width: '20%', minWidth: '150px'}}
                            />
                            {(hasRoleCreatePermission || hasRoleDeletePermission) && (<Column header="Дія" style={{cursor: 'pointer', width: '20%', minWidth: '100px'}} body={actionTemplate}></Column>)}
                        </DataTable>
                        <Paginator
                            first={first}
                            rows={paginationSize}
                            totalRecords={pagination.totalItems}
                            rowsPerPageOptions={[5, 10, 15, 25, 50]}
                            onPageChange={onPageChange}
                            className="border-top-1 border-bottom-1"
                        />
                    </div>
                    {selectedRowData && companies && (
                        <>
                            {windowWidth > 1248 && <div className={classNames('card widget-table col-12 md:col-12 align-self-start sticky top-0 mt-4')}>
                                <RolesListDetails setSelectedRowData={setSelectedRowData} selectedRowData={selectedRowData} windowWidth={windowWidth}/>
                            </div>}

                            {windowWidth <= 1248 && <div>
                                <Dialog visible={mobileVisible} header={dialogHeader} className="monitoring-info" onHide={() => setMobileVisible(false)}>
                                    <RolesListDetails/>
                                </Dialog>
                            </div>}
                        </>
                    )}
                    <RolesListDialog/>
                    <ConfirmDialog visible={showUnArchiveNotification} onHide={() => setShowUnArchiveNotification(false)} message={'Роль буде розпаковано ! Розпакувати роль ?'}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => {
                        archiveRoleAction()
                        if (id === selectedRowData) {
                            setSelectedRowData(null);
                        }
                    }}/>
                    <ConfirmDialog visible={showCopyNotification} onHide={() => setShowCopyNotification(false)} message={copyRoleMessage}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => copyRoleMethod()}/>
                    <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={archivedRoleNotificationMessage}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => {
                        archiveRoleAction()
                        if (id === selectedRowData) {
                            setSelectedRowData(null);
                        }
                    }}/>
                </div>
            </div>
            <BranchDialog/>
        </div>
    );
};

export default RolesList;
