import React, {useEffect, useState} from "react";
import {useAppSelector, useAppDispatch} from 'redux/hooks'
import {Divider} from "primereact/divider";
import {Button} from "primereact/button";
import {TabPanel, TabView} from "primereact/tabview";
import {Dialog} from "primereact/dialog";
import {resetScheduler, setChangedCompaniesData, setChangedScheduler, setCompanyStateSave, setDialogVisible, setErrorFields} from "redux/actions/actions";
import {createCompanies, deleteCompanies, getCampaignDirectoriesGetTranksPhones, getCampaignId, getNotification, updateCompanies} from "redux/api/api";
import CompaniesListDialogGeneralSettings from "components/DialogGeneralSettings";
import CompaniesListDialogUsers from "components/DialogUsers";
import CompaniesListDialogStartEndFinish from "components/DialogStartEndFinish";
import CompaniesListDialogManagement from "components/DialogManagement";
import CompaniesListDialogInfo from "components/DialogInfo";
import CompaniesListDialogLeads from "components/DialogLeads";
import CompaniesListRingCycle from "components/DialogRingCycle";
import {ConfirmDialog} from "primereact/confirmdialog";
import {getItem} from "../../redux/cache/cacheService";
import {codes} from "../../redux/notificationCodes";
import {ProgressBar} from "primereact/progressbar";
import {validate} from "../../redux/validator/validator";
import {checkPermissions} from "../../redux/permissions/permissionsUtils";
import {getSkillGroupList} from "../../redux/api/apiQualificationGroup";

const CompaniesListDialog = () => {
    const dispatch = useAppDispatch()
    const companies = useAppSelector(state => state.CompaniesListReducer)
    // @ts-ignore
    const companiesData = useAppSelector(state => state.CompaniesListReducer.companiesData)
    // @ts-ignore
    const dialogVisible = useAppSelector(state => state.CompaniesListReducer.dialogVisible)
    // @ts-ignore
    const companyStateSave = useAppSelector(state => state.CompaniesListReducer.companyStateSave)
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.CompaniesListReducer.archiveTableToggle)
    // @ts-ignore
    const changeScheduler = useAppSelector(state => state.CompaniesListReducer.changeScheduler)
    const user = useAppSelector(state => state.UsersReducer.userData);
    const [activeIndex, setActiveIndex] = useState(0)
    const [selectIndex, setSelectIndex] = useState(0)
    const [showDeleteNotification, setShowDeleteNotification] = useState<boolean>(false);
    const [showSaveChangeNotification, setShowSaveChangeNotification] = useState<boolean>(false);
    const [createdSchedule, setCreatedSchedule] = useState<boolean>(false);
    const [visible, setVisible] = useState(false);
    // @ts-ignore
    const selectedCompanyId = useAppSelector(state => state.CompaniesListReducer.selectedCompanyId)
    // @ts-ignore
    const scheduler = useAppSelector(state => state.CompaniesListReducer?.companiesData?.scheduler)
    const deletionNotification = getItem(codes.cancel)
    const saveChangesNotification = getItem(codes.save_changes)
    // @ts-ignore
    const changedCompaniesData = useAppSelector(state => state.CompaniesListReducer.changedCompaniesData)
    const statusDone = companiesData?.campaign?.statusId === 6;
    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    // @ts-ignore
    const saveChangeMessage = <div>{companies && companies.notificationSaveChanges ? companies.notificationSaveChanges.text : saveChangesNotification ? saveChangesNotification : progressBar}</div>
    // @ts-ignore
    const deletionMessage = <div>{companies && companies.notificationCancel ? companies.notificationCancel.text : deletionNotification ? deletionNotification : progressBar}</div>
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    // @ts-ignore
    const errorFields = useAppSelector(state => state.CompaniesListReducer.errorFields);
// @ts-ignore
    const usersData = useAppSelector(state => state.CompaniesListReducer.usersData);
    const skillGroupList = useAppSelector(state => state.QualificationGroupListReducer.skillGroupList);
    const dialerPermissions = checkPermissions(rolesVision, 'Dialer');
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const {
        create: hasDialerCreatePermission,
        update: hasDialerUpdatePermission,
        delete: hasDialerDeletePermission,
    } = dialerPermissions;

    useEffect(() => {
        dispatch(getCampaignDirectoriesGetTranksPhones(jwtToken?.jwtToken));
        dispatch(getSkillGroupList(jwtToken?.jwtToken));
        setActiveIndex(0)
    }, [dialogVisible])

    useEffect(() => {
        if (errorFields && errorFields?.nameUniq) {
            setActiveIndex(0);
        }
    }, [errorFields]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const closeDialogVisible = () => {
        dispatch(setDialogVisible(false));
        setCreatedSchedule(false);
        dispatch(setCompanyStateSave(false));
        dispatch(setChangedCompaniesData(false));
        dispatch(setErrorFields(null));
    }

    const updateCampaign = (type: string) => {
        let companiesCopy = {...companiesData};

        companiesCopy.skillGroups = companiesCopy?.skillGroups?.filter((skillGroup: { skillGroupId: any; }) =>
            skillGroupList?.some((skillListItem: { id: any; }) => skillListItem.id === skillGroup.skillGroupId)
        );

        companiesCopy.campaignUsers = companiesCopy?.campaignUsers?.filter((skillGroup: { userId: any; }) =>
            usersData?.some((userItem: { userId: any; }) => userItem.userId === skillGroup.userId)
        );

        const validationErrors = validate(companiesCopy);

        if (!validationErrors) {
            dispatch(updateCompanies(companiesData, jwtToken?.jwtToken));
            dispatch(setErrorFields(validationErrors));
            dispatch(setChangedCompaniesData(false));
            dispatch(setChangedScheduler(false));

            if (type === 'with_close') {
                closeDialogVisible();
            }
        } else {
            dispatch(setErrorFields(validationErrors));
            if (validationErrors.name || validationErrors.trankId || validationErrors.branchId) {
                setActiveIndex(0);
            } else if (validationErrors.callCycles) {
                setActiveIndex(4);
            } else if (validationErrors.startTime || validationErrors.startDate) {
                setActiveIndex(2);
            } else if (validationErrors.campaignUsers) {
                setActiveIndex(1);
            }
        }

        setCreatedSchedule(false);
    };

    const createCampaign = (type: string) => {
        const validationErrors = validate(companiesData);

        if (!validationErrors) {
            dispatch(createCompanies(companiesData, jwtToken?.jwtToken));
            dispatch(setErrorFields(validationErrors));
            dispatch(setChangedScheduler(false));
            dispatch(setChangedCompaniesData(false));

            if (type === 'with_close') {
                closeDialogVisible();
            }
        } else {
            dispatch(setErrorFields(validationErrors));
            if (validationErrors.name || validationErrors.trankId || validationErrors.branchId) {
                setActiveIndex(0);
            } else if (validationErrors.callCycles) {
                setActiveIndex(4);
            } else if (validationErrors.startTime || validationErrors.startDate) {
                setActiveIndex(2);
            } else if (validationErrors.campaignUsers) {
                setActiveIndex(1);
            }
        }
        setCreatedSchedule(false);
    };

    const deleteCampaign = () => {
        if (jwtToken?.jwtToken) {
            dispatch(deleteCompanies(companiesData.campaignData.campaignId, jwtToken?.jwtToken));
        }
        dispatch(setDialogVisible(false));
        dispatch(setCompanyStateSave(false))
        setCreatedSchedule(false);
    }

    const showDeletionNotification = () => {
        if (!deletionNotification && user.userId) {
            dispatch(getNotification(user.userId, 107, jwtToken?.jwtToken));
        }
        setShowDeleteNotification(true);
    }

    const showSaveChangesNotification = () => {
        if (changedCompaniesData) {
            if (!saveChangesNotification && user.userId) {
                dispatch(getNotification(user.userId, 113, jwtToken?.jwtToken));
            }
            setShowSaveChangeNotification(true);
        } else {
            dispatch(setDialogVisible(false));
            setCreatedSchedule(false);
            dispatch(setCompanyStateSave(false))
        }
    }

    const setActiveTabIndex = (e: any) => {
        if ((createdSchedule || changeScheduler) && activeIndex === 2) {
            setVisible(true);
            setSelectIndex(e.index);
        } else {
            setActiveIndex(e.index);
        }
    }

    const closeNotification = () => {
        setVisible(false);
        setActiveIndex(selectIndex);
        setCreatedSchedule(false);
        dispatch(setChangedScheduler(false));
    }

    const saveNewScheduler = () => {
        closeNotification();
    }

    const cancelSaveScheduler = () => {
        if (!companiesData.campaign.campaignId) {
            closeNotification();
            dispatch(resetScheduler());
            dispatch(setErrorFields(null))
        }
        if (companiesData.campaign.campaignId && !scheduler) {
            closeNotification()
            dispatch(setErrorFields(null))
            dispatch(resetScheduler());
        }
        if (companiesData.campaign.campaignId && scheduler) {
            closeNotification();
            dispatch(setErrorFields(null))
            dispatch(getCampaignId(selectedCompanyId, jwtToken?.jwtToken));
        }
    }

    const footer = (
        <div className="dialog-footer">
            <Divider/>
            <div className="buttons">
                {!companyStateSave && hasDialerDeletePermission && <Button icon={windowWidth > 1248 ? "pi pi-trash" : ''}
                                                                           className="p-button-outlined"
                                                                           label="Видалити"
                                                                           onClick={showDeletionNotification}
                />}
                <Button icon={windowWidth > 1248 ? "pi pi-ban" : ''}
                        className="p-button-outlined"
                        label="Відміна"
                        onClick={() => {
                            showSaveChangesNotification()
                        }}
                />
                {companyStateSave && hasDialerCreatePermission && <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                          className=""
                                                                          label="Створити та зберегти"
                                                                          onClick={() => {
                                                                              createCampaign('')
                                                                          }}
                />}
                {!companyStateSave && !statusDone && hasDialerUpdatePermission && <Button icon={windowWidth > 1248 ? "pi pi-save" : ''}
                                                                                          label="Зберегти"
                                                                                          onClick={() => {
                                                                                              updateCampaign('')
                                                                                          }}
                />}
            </div>
        </div>
    );

    return (
        <Dialog
            header="Налаштування кампанії"
            footer={archiveTableToggle ? footer : ''}
            visible={dialogVisible}
            dismissableMask={false}
            closeOnEscape={false}
            draggable={true}
            blockScroll={true}
            position="top"
            className='dialogInfo user-dialog-info'
            style={{width: '95vw', maxWidth: "1200px", minHeight: "80vh", zIndex: '1101px !important'}}
            onHide={() => showSaveChangesNotification()}
        >
            {companiesData && <div className="company-info-popup">
                <ConfirmDialog visible={showDeleteNotification} onHide={() => setShowDeleteNotification(false)} message={deletionMessage}
                               icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => deleteCampaign()}/>
                {!companyStateSave && <ConfirmDialog visible={showSaveChangeNotification} onHide={() => setShowSaveChangeNotification(false)} message={saveChangeMessage}
                                                     icon="pi pi-exclamation-triangle" acceptLabel="Зберегти" rejectLabel="Відмінити" reject={() => closeDialogVisible()} accept={() => {
                    updateCampaign('with_close')
                }}/>}
                {companyStateSave && <ConfirmDialog visible={showSaveChangeNotification} onHide={() => setShowSaveChangeNotification(false)} message={saveChangeMessage}
                                                    icon="pi pi-exclamation-triangle" acceptLabel="Зберегти" rejectLabel="Відмінити" reject={() => closeDialogVisible()} accept={() => {
                    createCampaign('with_close')
                }}/>}
                <CompaniesListDialogInfo/>
                <Divider style={{margin: '0 0 20px 0'}}/>
                <CompaniesListDialogManagement/>
                <Divider style={{margin: '0 0 20px 0'}}/>
                <TabView className="user-dialog-info" activeIndex={activeIndex} onTabChange={(e) => setActiveTabIndex(e)}>
                    <TabPanel header="Загальні налаштування">
                        {companiesData && <CompaniesListDialogGeneralSettings/>}
                    </TabPanel>
                    <TabPanel header="Додати операторів">
                        <CompaniesListDialogUsers/>
                    </TabPanel>
                    <TabPanel header="Запуск та завершення кампанії">
                        {hasDialerCreatePermission || hasDialerUpdatePermission ? <>{!createdSchedule && !scheduler && archiveTableToggle && <Button onClick={() => {
                            setCreatedSchedule(true);
                            dispatch(setChangedCompaniesData(true));
                        }}>Створити розклад</Button>}</> : ''}
                        {(createdSchedule || scheduler) && <CompaniesListDialogStartEndFinish createdSchedule={createdSchedule}></CompaniesListDialogStartEndFinish>}
                        {!scheduler && !archiveTableToggle && (
                            <div>Немає розкладу дзвінків</div>
                        )}
                    </TabPanel>
                    <TabPanel header="Ліди кампанії">
                        <CompaniesListDialogLeads/>
                    </TabPanel>
                    <TabPanel header="Цикл прозвону">
                        <CompaniesListRingCycle/>
                    </TabPanel>
                </TabView>
            </div>}
            <Dialog visible={visible} style={{width: '25vw'}} onHide={() => setVisible(false)}>
                <p className="m-0">
                    Застосувати розклад запуску та завершення кампанії?
                </p>
                <div className="flex justify-content-end mt-4">
                    <Button className="mr-2" onClick={() => saveNewScheduler()}>Так</Button>
                    <Button className="p-button-outlined mr-2" onClick={() => cancelSaveScheduler()}>Ні</Button>
                    <Button className="p-button-outlined" onClick={() => setVisible(false)}>Відміна</Button>
                </div>
            </Dialog>
        </Dialog>
    )
}

export default CompaniesListDialog
