import React from "react";
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import {Button} from "primereact/button";
import {setClearFilters, setArchiveTableToggle, setDialogVisible, initialCompaniesData, setCompanyStateSave, clearCampaignContacts, setEditType, setErrorFields} from "redux/actions/actions";
import {checkPermissions} from "../../redux/permissions/permissionsUtils";

const CompaniesListNav = (props: any) => {
    const dispatch = useAppDispatch()
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.CompaniesListReducer.archiveTableToggle)
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'Dialer');

    const {
        create: hasDialerCreatePermission,
    } = dialerPermissions;
    return (
        <div className="table-buttons">
            <div>
                <Button onClick={() => { dispatch(setArchiveTableToggle(true))}}
                        label="Дайлер"
                        className={archiveTableToggle ? 'mr-2 mb-2' : 'p-button-secondary mr-2 mb-2'}
                />
                <Button onClick={() => { dispatch(setArchiveTableToggle(false))}}
                        label="Архів"
                        className={archiveTableToggle ? 'p-button-secondary mr-2 mb-2' : 'mr-2 mb-2'}
                />
            </div>
            <div className="desktop">
                {hasDialerCreatePermission && (<Button label="Створити кампанію"
                        icon="pi pi-plus"
                        className="mr-2 mb-2"
                        onClick={() => {
                            dispatch(initialCompaniesData());
                            dispatch(setDialogVisible(true));
                            dispatch(setCompanyStateSave(true));
                            dispatch(setArchiveTableToggle(true));
                            dispatch(setErrorFields(null));
                            dispatch(clearCampaignContacts());
                            if (hasDialerCreatePermission) {
                                dispatch(setEditType(true));
                            } else {
                                dispatch(setEditType(false));
                            }
                        }}
                />)}
                <Button type="button" icon="pi pi-filter-slash" label="Очистити фільтри" className="p-button-outlined mr-2 mb-2" onClick={() => {dispatch(setClearFilters()); props.handleReset()}} />
            </div>
            <div className="mobile">
                {hasDialerCreatePermission && (<Button icon="pi pi-plus"
                        className="mr-2 mb-2"
                        onClick={() => {
                            dispatch(initialCompaniesData());
                            dispatch(setErrorFields(null));
                            dispatch(setDialogVisible(true));
                            dispatch(setCompanyStateSave(true));
                            dispatch(setArchiveTableToggle(true));
                            if (hasDialerCreatePermission) {
                                dispatch(setEditType(true));
                            } else {
                                dispatch(setEditType(false));
                            }
                        }}
                />)}
                <Button type="button" icon="pi pi-filter-slash" className="p-button-outlined mr-2 mb-2" onClick={() => dispatch(setClearFilters())} />
            </div>
        </div>
    )
}

export default CompaniesListNav
