import React, {useEffect, useRef, useState} from "react";
import {Dropdown} from "primereact/dropdown";
import {setChangedCompaniesData, setCompanyField, setTrankId} from "redux/actions/actions";
import {getCampaignDirectoriesCampaignType} from "redux/api/api";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {classNames} from "primereact/utils";
import {InputSwitch} from "primereact/inputswitch";
import {MVP} from "../../redux/config";
import {getUsersManagerUserBranchesData} from "../../redux/api/apiUsersList";

const CompaniesListDialogGeneralSettings = () => {
    const dispatch = useAppDispatch();
    // @ts-ignore
    const companiesData = useAppSelector(state => state.CompaniesListReducer.companiesData);
    // @ts-ignore
    const errorFields = useAppSelector(state => state.CompaniesListReducer.errorFields);
    // @ts-ignore
    const companiesDirectoryCampaignType = useAppSelector(state => state.CompaniesListReducer.companiesDirectoryCampaignType);
    // @ts-ignore
    const companiesDirectoryTranksPhones = useAppSelector(state => state.CompaniesListReducer.companiesDirectoryTranksPhones);
    // @ts-ignore
    const trankId = useAppSelector(state => state.CompaniesListReducer.companiesData.trunkId);
    const [selectedGateway, setSelectedGateway] = useState(null);
    const [selectedPhone, setSelectedPhone] = useState(null);
    const statusDone = companiesData?.campaign?.statusId === 6;
    const phoneField = useRef(null);
    const nameField = useRef(null);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.CompaniesListReducer.archiveTableToggle)


    const scrollIntoNameView = () => {
        // @ts-ignore
        nameField?.current?.scrollIntoView({behavior: 'smooth'});
    }

    const scrollIntoTrankView = () => {
        // @ts-ignore
        phoneField?.current?.scrollIntoView({behavior: 'smooth'});
    }
    const [sla, setSla] = useState("");
    const priorityOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    useEffect(() => {
        if (!companiesData.campaign.name && errorFields?.name) {
            scrollIntoNameView();
        }
        if (errorFields?.nameUniq) {
            scrollIntoNameView();
        }
        if (errorFields?.trankId && companiesData.campaign.name) {
            scrollIntoTrankView();
        }
    }, [errorFields, companiesData]);

    useEffect(() => {
        dispatch(getCampaignDirectoriesCampaignType(jwtToken?.jwtToken));
        dispatch(getUsersManagerUserBranchesData(jwtToken?.jwtToken));
    }, []);

        useEffect(() => {

        companiesDirectoryTranksPhones?.map((item: any) => {
            if (trankId === item.id) {
                setSelectedGateway(item.gatewayName);
                setSelectedPhone(item);
                return item;
            }

            if (!trankId) {
                setSelectedGateway(null);
                setSelectedPhone(null);
            }
        });
    }, [companiesDirectoryTranksPhones, trankId]);

    const gateways = Array.from(new Set(companiesDirectoryTranksPhones?.map((item: any) => item.gatewayName)));

    const filteredData = selectedGateway
        ? companiesDirectoryTranksPhones?.filter((item: any) => item.gatewayName === selectedGateway)
        : companiesDirectoryTranksPhones;

    const setSelectedPhoneNumber = (e: any) => {
        dispatch(setTrankId(e.id));
        setSelectedPhone(e);
    }

    const campaignTypeItemTemplate = (option: any) => {
        const foundType = companiesDirectoryCampaignType?.find((item: { dictionaryId: number }) => item.dictionaryId === option);

        if (foundType) {
            return (
                <div className="p-multiselect-representative-option">
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {foundType.name}
                </span>
                </div>
            );
        } else {
            return null;
        }
    };

    const selectedCampaignTypeTemplate = (option: any) => {
        let typeName = companiesDirectoryCampaignType?.map((item: { dictionaryId: number; name: any; }) => {
            if (item.dictionaryId === option) {
                return item.name
            }
        })
        if (option) {
            return (
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {typeName}
                </span>
            );
        }

        return "Оберіть тип";
    }

    const onChangeNameField = (e: any) => {
        dispatch(setCompanyField({section: 'campaign', fieldName: 'name', value: e.target.value}));
    }

    const formattedBranches = branches?.map((branch: { id: any; name: any; }) => ({
        ...branch,
        label: `${branch.id} ${branch.name}`,
    }));

    return (
        <div className="general-settings">
            <div className="grid p-fluid">
                <div className="field col-12 md:col-6" ref={nameField}>
                    <label htmlFor="name" className={classNames('', {'p-error': errorFields?.name || errorFields?.nameUniq})}>Назва кампанії *</label>
                    <InputText
                        id="name"
                        value={companiesData?.campaign?.name || ''}
                        maxLength={150}
                        className={classNames('', {'p-invalid': errorFields?.name || errorFields?.nameUniq})}
                        onChange={e => {
                            onChangeNameField(e);
                            dispatch(setChangedCompaniesData(true))
                        }}
                        disabled={!archiveTableToggle || statusDone || !editType}
                    />
                    {errorFields?.nameUniq && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span className="title p-error text-sm">{errorFields?.nameUniq}*</span></div>}
                </div>
                <div className="field col-12 md:col-6"></div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="description">Коментар</label>
                    <InputTextarea
                        id="description"
                        value={companiesData?.campaign?.description || ''}
                        onChange={e => {
                            dispatch(setChangedCompaniesData(true));
                            dispatch(setCompanyField({section: 'campaign', fieldName: 'description', value: e.target.value}))
                        }}
                        autoResize
                        rows={3}
                        maxLength={500}
                        disabled={!archiveTableToggle || statusDone || !editType}
                    />
                </div>
                <div className="field col-12 md:col-6"></div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="type">Оберіть тип</label>
                    {MVP ?
                        <Dropdown id="type"
                                  appendTo="self"
                                  value={companiesData.campaign.campaignTypeId || ''}
                                  options={companiesDirectoryCampaignType}
                                  onChange={e => {
                                      dispatch(setCompanyField({section: 'campaign', fieldName: 'campaignTypeId', value: e.target.value}));
                                      dispatch(setChangedCompaniesData(true))
                                  }}
                                  optionValue='dictionaryId'
                                  optionLabel='name'
                                  disabled={!archiveTableToggle || statusDone || !editType}
                                  placeholder="Оберіть тип"
                        /> :
                        <Dropdown id="type"
                                  appendTo="self"
                                  value={companiesData.campaign.campaignTypeId || ''}
                                  options={companiesDirectoryCampaignType.map((item: { dictionaryId: number; }) => item.dictionaryId)}
                                  onChange={e => {
                                      dispatch(setCompanyField({section: 'campaign', fieldName: 'campaignTypeId', value: e.target.value}));
                                      dispatch(setChangedCompaniesData(true))
                                  }}
                                  itemTemplate={campaignTypeItemTemplate}
                                  valueTemplate={selectedCampaignTypeTemplate}
                                  disabled={!archiveTableToggle || statusDone || !editType}
                                  placeholder="Оберіть тип"
                        />}
                </div>

                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="name" className={classNames('', {'p-error': errorFields?.branchId && !companiesData.campaign?.branchId})}>Бранч *</label>
                    <Dropdown
                        id="role"
                        value={companiesData.campaign?.branchId || ''}
                        options={formattedBranches}
                        filter
                        className={classNames('w-full', {'p-invalid': errorFields?.branchId && !companiesData.campaign?.branchId})}
                        optionValue="id"
                        optionLabel="label"
                        placeholder='Виберіть бранч'
                        onChange={e => {
                            dispatch(setCompanyField({section: 'campaign', fieldName: 'branchId', value: e.target.value}));
                        }}
                        disabled={!archiveTableToggle || !editType}
                    />
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>

            </div>
            {companiesData?.campaign?.campaignTypeId && <div>
                <div>
                    <label htmlFor="name" className="font-bold text-base">Налаштування стратегії</label>
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
                <div>
                    <label htmlFor="name">Напрямок дзвінків</label>
                    <div className="field-radiobutton my-4 pl-2">
                        <label htmlFor="name" className="mr-2">На клієнта</label>
                        <InputSwitch disabled={!archiveTableToggle || statusDone || !editType} checked={companiesData.campaign.firstCallOperator} onChange={(e) => {
                            dispatch(setCompanyField({section: 'campaign', fieldName: 'firstCallOperator', value: e.value}));
                            dispatch(setChangedCompaniesData(true))
                        }}/>
                        <label htmlFor="icon">На оператора</label>
                    </div>

                    <label htmlFor="name">А номер</label>
                    <div className="field-radiobutton my-4 pl-2">
                        <label htmlFor="name" className="mr-2">Транк Оператора</label>
                        <InputSwitch disabled={!archiveTableToggle || statusDone || !editType} checked={companiesData.campaign.isGeneralTrunk} onChange={(e) => {
                            dispatch(setCompanyField({section: 'campaign', fieldName: 'isGeneralTrunk', value: e.value}));
                            dispatch(setChangedCompaniesData(true))
                        }}/>
                        <label htmlFor="icon">Загальний транк кампанії</label>
                    </div>
                    <div className="grid p-fluid">

                        {!MVP && <div className="field col-12 md:col-3">
                            <label htmlFor="priority">Пріоритет</label>
                            <Dropdown id="priority"
                                      appendTo="self"
                                      value={companiesData.campaign.priority || 0}
                                      options={priorityOptions}
                                      onChange={e => {
                                          dispatch(setCompanyField({section: 'campaign', fieldName: 'priority', value: e.target.value}));
                                          dispatch(setChangedCompaniesData(true))
                                      }}
                                      disabled={!archiveTableToggle || statusDone || !editType}
                                      placeholder="Оберіть пріоритет"
                            />
                        </div>}
                        {!MVP && <div className="field col-12 md:col-3">
                            <label htmlFor="sla">SLA</label>
                            <InputText id="sla"
                                       value={sla}
                                       onChange={(e) => {
                                           setSla(e.target.value);
                                           dispatch(setChangedCompaniesData(true))
                                       }}
                                       disabled={!archiveTableToggle || statusDone || !editType}
                            />
                        </div>}
                        {!MVP && <div className="field col-12 md:col-3"></div>}
                        {!MVP && <div className="field col-12 md:col-3"></div>}
                        <div className="field col-12 md:col-3" ref={phoneField}>
                            <label htmlFor="type" className={classNames('', {'p-error': errorFields && errorFields.trankId && !selectedGateway})}>Транки*</label>
                            <Dropdown id="type"
                                      appendTo="self"
                                      value={selectedGateway}
                                      options={gateways}
                                      onChange={e => {
                                          setSelectedGateway(e.value);
                                          dispatch(setChangedCompaniesData(true))
                                      }}
                                      disabled={!archiveTableToggle || statusDone || !editType || !companiesData.campaign.isGeneralTrunk}
                                      placeholder="Оберіть транк"
                                      className={classNames('', {'p-invalid': errorFields && errorFields.trankId && !selectedGateway})}
                            />
                        </div>
                        <div className="field col-12 md:col-3">
                            <label htmlFor="type" className={classNames('', {'p-error': errorFields && errorFields.trankId && !selectedPhone})}>Номер*</label>
                            <Dropdown
                                onChange={(e) => {
                                    setSelectedPhoneNumber(e.value);
                                    dispatch(setChangedCompaniesData(true))
                                }}
                                value={selectedPhone}
                                options={filteredData}
                                optionLabel="phoneNo"
                                placeholder="Оберіть номер"
                                disabled={!archiveTableToggle || statusDone || !selectedGateway || !editType || !companiesData.campaign.isGeneralTrunk}
                                className={classNames('', {'p-invalid': errorFields && errorFields.trankId && !selectedPhone})}
                            />
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default CompaniesListDialogGeneralSettings
