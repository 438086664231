import React, {useEffect, useRef, useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column, ColumnFilterElementTemplateOptions} from 'primereact/column';
import {classNames} from "primereact/utils";
import {Badge} from "primereact/badge";
import {Button} from "primereact/button";
import 'react-h5-audio-player/lib/styles.css';
import {Dialog} from "primereact/dialog";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {Paginator} from "primereact/paginator";
import {MultiSelect} from "primereact/multiselect";
import {Calendar} from "primereact/calendar";
import {UPLOAD_AUDIO_LOADING} from "../../../redux/types/types";
import {getQueueDirectoriesQueueDirectionsType} from "../../../redux/api/apiQueue";
import {DataRow} from "../../../types/types";
import {typeChannelDict} from "../../../dictionaries";
import {getUsers} from "../../../redux/api/api";
import {
    getEventStatisticToUserDetail,
    getStatisticsUserSortFields,
    getStatisticsWithPaginationFilterAndSorting
} from "../../../redux/api/apiStatisticsList";
import {
    setStatisticsCurrentPage,
    setStatisticsCurrentPageSize,
    setStatisticsFilter
} from "../../../redux/actions/actionsStatisticsList";
import {
    getCallResetType,
    getCampaignFilterList, getForcedClosingType,
    getStatusDirectoriesStatusType
} from "../../../redux/api/apiMonitoringList";
import {getAudioUrlForPlayer} from "../../../redux/actions/actionsMonitoringList";
import StatisticsListNav from "./StatisticsListNav";
import StatisticsListDetails from "./StatisticsListDetails";
import {GET_STATISTICS} from "../../../redux/types/typesStatisticsList";

const StatisticsList = (props: any) => {
    const dispatch = useAppDispatch();
    const statisticsList = useAppSelector(state => state.StatisticsListReducer?.statisticsList);
    const userStatisticSortFields: any = useAppSelector(state => state.RolesReducer.userStatisticSortFields);
    const campaignList = useAppSelector(state => state.MonitoringListReducer?.campaignListName);
    const statisticData = useAppSelector(state => state.StatisticsListReducer?.statisticData);
    const paginationSize = useAppSelector(state => state.StatisticsListReducer.paginationSize);
    const pagination = useAppSelector(state => state.StatisticsListReducer.pagination);
    const loader = useAppSelector(state => state.StatisticsListReducer.loading);
    const paginationPage = useAppSelector(state => state.StatisticsListReducer.paginationPage);
    const statusDictionary = useAppSelector(state => state.MonitoringListReducer.statusDictionary);
    const callResetDictionary = useAppSelector(state => state.MonitoringListReducer.callResetDictionary);
    const forcedClosingDictionary = useAppSelector(state => state.MonitoringListReducer.forcedClosingDictionary);
    const clearFilters = useAppSelector(state => state.StatisticsListReducer.clearFilters)
    const user = useAppSelector(state => state.UsersReducer.userData);
    // @ts-ignore
    const activeClick = useAppSelector(state => state.UsersListReducer.activeClick);
    const [first, setFirst] = useState(0);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [selectedRowExpansion, setSelectedRowExpansion] = useState<any | null>(null);
    const [selectedSessionRowExpansion] = useState<any | null>(null);
    const [selectedBSessionRowExpansion] = useState<any | null>(null);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [isDescFilter, setIsDescFilter] = useState<boolean>(true);
    const [sortOrder, setSortOrder] = useState<number>(0);
    const [startTime, setStartTime] = useState<any>(new Date("2024-10-29 00:00:00"));
    const [endTime, setEndTime] = useState<any>(() => {const date = new Date(); date.setSeconds(0, 0); return date;});
    const [startFinishTime, setStartFinishTime] = useState<any>(null);
    const [endFinishTime, setEndFinishTime] = useState<any>(null);
    const [filterCampaignId, setFilterCampaignId] = useState<any>(null);
    const [filterNameTo, setFilterNameTo] = useState<any>(null);
    const [filterNameFrom, setFilterNameFrom] = useState<any>(null);
    const [filterAmdHangup, setFilterAmdHangup] = useState<any>(null);
    const [filterCallReset, setFilterCallReset] = useState<any>(null);
    const [filterStatus, setFilterStatus] = useState<any>(null);
    const [filterCampaignName, setFilterCampaignName] = useState<any>(null);
    const [filterQueueName, setFilterQueueName] = useState<any>(null);
    const [filterFullName, setFilterFullName] = useState<any>(null);
    const [filterTypeChannel, setFilterTypeChannel] = useState<any>(null);
    const [filterDirection, setFilterDirection] = useState<any>(null);
    const [filterDateTime, setFilterDateTime] = useState<any>(new Date());
    const [filterEndDateTime, setFilterEndDateTime] = useState<any>(new Date());
    const [filterFinishDateTime, setFilterFinishDateTime] = useState<any>(false);
    const [filterFinishEndDateTime, setFilterFinishEndDateTime] = useState<any>(false);
    const [fullCallDurationStartTime, setfullCallDurationStartTime] = useState<any>(false);
    const [fullCallDurationEndTime, setfullCallDurationEndTime] = useState<any>(false);
    const [durationStartTime, setDurationStartTime] = useState<any>(false);
    const [durationEndTime, setDurationEndTime] = useState<any>(false);
    const [reactionClientStartTime, setReactionClientStartTime] = useState<any>(false);
    const [reactionClientEndTime, setReactionClientEndTime] = useState<any>(false);
    const [reactionOperatorStartTime, setReactionOperatorStartTime] = useState<any>(false);
    const [reactionOperatorEndTime, setReactionOperatorEndTime] = useState<any>(false);
    const [visible, setVisible] = useState(false);
    const [filterCallOwner, setFilterCallOwner] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<string>('table1');
    const dataTableRef = useRef<DataTable | any>(null);
    // @ts-ignore
    const queuesDirectoryDirectionType = useAppSelector(state => state.QueueListReducer.queuesDirectoryDirectionType) as any;
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [expandedSessionRows, setExpandedSessionRows] = useState<any>([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [key, setKey] = useState(1);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const usersData = useAppSelector(state => state.CompaniesListReducer.usersData);
    const [selectedColumns, setSelectedColumns] = useState<any>(userStatisticSortFields?.fields?.filter((item: { isActive: any; }) => item.isActive));
    const pageChanged = useAppSelector(state => state.StatisticsListReducer.pageChanged);
    const [fullCallDuration, setFullCallDuration] = useState<boolean>(false);
    const [duration, setDuration] = useState<boolean>(false);
    const [reactionClient, setReactionClient] = useState<boolean>(false);
    const [reactionOperator, setReactionOperator] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(paginationSize);
    const [hasStartFilter, setHasStartFilter] = useState<boolean>(false);

    const formatDate = (dateString: Date) => {
        const dateObj = new Date(dateString);

        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const day = dateObj.getDate().toString().padStart(2, "0");
        const year = dateObj.getFullYear();

        return `${month}/${day}/${year}`;
    };

    const formatDateTime = (dateString: any) => {
        const date = new Date(dateString);
        return `${date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'})} ${date.toLocaleDateString('en-GB')}`;
    };

    // const getTime = (dateString: Date | any) => {
    //     const dateObj = new Date(dateString);
    //     const hours = dateObj.getHours().toString().padStart(2, "0");
    //     const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    //     const seconds = dateObj.getSeconds().toString().padStart(2, "0");
    //
    //     return `${hours}:${minutes}:${seconds}`;
    // };

    const getTime = (dateString: Date | any) => {
        const dateObj = new Date(dateString);
        const hours = dateObj.getHours().toString().padStart(2, "0");
        const minutes = dateObj.getMinutes().toString().padStart(2, "0");

        return `${hours}:${minutes}:00`;
    };

    const [sortData, setSortData] = useState<any>({
        pageNumber: 1,
        pageSize: 10,
        sort: {
            orderBy: orderBy ? orderBy : "Id",
            isDesc: isDescFilter
        },
        username: user.userName
    });

    const handleScroll = () => {
        resetTableHorizontalScroll();
    };

    useEffect(() => {
        // Добавляем обработчик прокрутки страницы
        window.addEventListener('scroll', handleScroll);

        // Удаляем обработчик при размонтировании компонента
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const resetTableHorizontalScroll = () => {
        if (dataTableRef.current) {
            dataTableRef.current.scrollLeft = 0;
        }
    };

    if (!userStatisticSortFields?.userId) {
        dispatch(getStatisticsUserSortFields(jwtToken?.jwtToken));
    }

    useEffect(() => {
        if (pagination.hasNext && pagination.pageNumber) {
            setTotalItems((pagination.pageNumber * paginationSize) + paginationSize)
        } else if (!pagination.hasNext) {
            setTotalItems(pagination.pageNumber * paginationSize);
        }
    }, [pagination]);

    useEffect(() => {
        setTotalItems((pagination.pageNumber * paginationSize) + paginationSize);
    }, [])


    useEffect(() => {
        setSelectedColumns(userStatisticSortFields?.fields?.filter((item: { isActive: any; }) => item.isActive));
    }, []);

    useEffect(() => {
        setFirst(0);
    }, [pageChanged])

    useEffect(() => {
        setSelectedColumns(userStatisticSortFields?.fields?.filter((item: { isActive: any; }) => item.isActive));
    }, [userStatisticSortFields]);

    const createUuidv4 = () => {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            // @ts-ignore
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    const formatItem = (item: { start: any; }) => {
        if (item && item.start) {
            return {
                ...item,
                start: formatDateTime(item.start),
                dateTime: createUuidv4()
            };
        }
        return item;
    };

    const formatDataRecursively = (obj: any) => {
        const formattedObj = {
            ...obj,
            item: formatItem(obj.item),
        };

        if (formattedObj.children && formattedObj.children.length > 0) {
            formattedObj.children = formattedObj.children.map((child: any) => formatDataRecursively(child));
        }

        return formattedObj;
    };

    const formattedData = statisticsList?.map(formatDataRecursively);

    useEffect(() => {
        handleResetPlayer();
    }, [selectedRowData]);

    const handleReset = () => {
        dataTableRef?.current?.reset();
        customFilter();
        setStartTime(new Date("2024-10-29 00:00:00"));
        setEndTime(() => {const date = new Date(); date.setSeconds(0, 0); return date;});
        setFilterEndDateTime(null);
        setStartFinishTime(null);
        setEndFinishTime(null);
        setFilterFinishDateTime(null);
        setFilterFinishEndDateTime(null);
        setSortOrder(0);
        setFirst(0);
        dispatch(setStatisticsCurrentPage(1));
        dispatch(setStatisticsCurrentPageSize(10));
        setReactionClient(false);
        setReactionOperator(false);
        setFullCallDuration(false);
        setDuration(false);
        setReactionOperatorStartTime(null);
        setReactionOperatorEndTime(null);
        setReactionOperator(false);
        setDurationStartTime(null);
        setDurationEndTime(null);
        setfullCallDurationStartTime(null);
        setfullCallDurationEndTime(null);
        setReactionClientStartTime(null);
        setReactionClientEndTime(null);
        setReactionClient(false);
        setHasStartFilter(false);
        setFilterDateTime(new Date());
        setFilterEndDateTime(new Date());
        setSortData({
            pageNumber: 1,
            pageSize: 10,
            sort: {
                orderBy: orderBy ? orderBy : "Id",
                isDesc: isDescFilter
            },
            username: user.userName
        })
    };

    useEffect(() => {
        dispatch(getQueueDirectoriesQueueDirectionsType(jwtToken?.jwtToken));
        dispatch(getCampaignFilterList(jwtToken?.jwtToken));
        dispatch(getStatusDirectoriesStatusType(jwtToken?.jwtToken));
        dispatch(getCallResetType(jwtToken?.jwtToken));
        dispatch(getForcedClosingType(jwtToken?.jwtToken));
    }, []);

    useEffect(() => {
        dispatch(setStatisticsCurrentPage(1));
    }, []);

    useEffect(() => {
        dispatch(setStatisticsCurrentPageSize(10));
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onPageChange = (event: { first: React.SetStateAction<number>; page: number; rows: any; }) => {
        setFirst(event.first);
        dispatch(setStatisticsCurrentPage(event.page + 1));
        dispatch(setStatisticsCurrentPageSize(event.rows));
    };

    const getTimeWithoutSecond = (dateString: Date) => {
        const dateObj = new Date(dateString);
        const hours = dateObj.getHours().toString().padStart(2, "0");
        const minutes = dateObj.getMinutes().toString().padStart(2, "0");

        return `${hours}:${minutes}`;
    };


    useEffect(() => {
        // setSelectedRowData(null);
        let filter: {
            key: string;
            value: string
        }[] = [];

        const dataSort: any = {
            pageNumber: paginationPage,
            pageSize: paginationSize,
            sort: {
                orderBy: orderBy ? orderBy : "Id",
                isDesc: isDescFilter
            },
            username: user.userName
        }

        if (filterCampaignId?.value) {
            filter.push(filterCampaignId);
        }
        if (filterNameTo?.value) {
            filter.push(filterNameTo);
        }
        if (filterNameFrom?.value) {
            filter.push(filterNameFrom);
        }
        if (filterTypeChannel) {
            filterTypeChannel?.map((item: any) => {
                if (item.value === 'internalSkipped') {
                    filter.push({key: 'IsDeclined', value: '1'});
                } else {
                    filter.push(item);
                }
            })
        }

        if (filterCampaignName) {
            filterCampaignName?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterCallOwner) {
            filterCallOwner?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterQueueName) {
            filterQueueName?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterFullName) {
            filterFullName?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterStatus) {
            filterStatus.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterDirection) {
            filterDirection.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterAmdHangup) {
            filterAmdHangup.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterCallReset) {
            filterCallReset.map((item: any) => {
                filter.push(item);
            })
        }


        let startAdded = false;
        let endAdded = false;

        if (filterDateTime && filterEndDateTime) {
            let startDate = formatDate(filterDateTime);
            let endDate = formatDate(filterEndDateTime);

            if (startTime && endTime) {
                let filterStartTime = getTime(startTime);
                let filterEndTime = getTime(endTime);
                filter.push({
                    "key": "Start",
                    "value": `${startDate} ${filterStartTime},${endDate} ${filterEndTime}`
                });
                startAdded = true;
            }
        }

        if (filterFinishDateTime && filterFinishEndDateTime) {
            let startDate = formatDate(filterFinishDateTime);
            let endDate = formatDate(filterFinishEndDateTime);

            if (startFinishTime && endFinishTime) {
                let filterStartTime = getTime(startFinishTime);
                let filterEndTime = getTime(endFinishTime);
                filter.push({
                    "key": "End",
                    "value": `${startDate} ${filterStartTime},${endDate} ${filterEndTime}`
                });
                endAdded = true;
            }
        }

        if (!startAdded && !endAdded) {
            let currentDateTime = new Date();
            let currentDate = formatDate(currentDateTime);
            let currentTime = getTime(currentDateTime);
            filter.push({
                "key": "Start",
                "value": `${currentDate} 00:00:00,${currentDate} ${currentTime}`
            });
        }

        const addFilterSecond = (condition: boolean, key: string, startTime: number, endTime: number) => {
            if (
                condition &&
                startTime !== undefined && startTime !== null &&
                endTime !== undefined && endTime !== null
            ) {
                filter.push({
                    "key": key,
                    "value": `${startTime}, ${endTime}`
                });
            }
        };

        const getTotalSeconds = (date: any) => {
            if (!date) return null;
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            return hours * 3600 + minutes * 60 + seconds;
        };


        if (reactionClientStartTime && reactionClientEndTime) {
            addFilterSecond(reactionClient, "ReactionClient", getTotalSeconds(reactionClientStartTime), getTotalSeconds(reactionClientEndTime));
        }
        if (reactionOperatorStartTime && reactionOperatorEndTime) {
            addFilterSecond(reactionOperator, "ReactionOperator", getTotalSeconds(reactionOperatorStartTime), getTotalSeconds(reactionOperatorEndTime));
        }
        if (fullCallDurationStartTime && fullCallDurationEndTime) {
            addFilterSecond(fullCallDuration, "FullCallDuration", getTotalSeconds(fullCallDurationStartTime), getTotalSeconds(fullCallDurationEndTime));
        }
        if (durationStartTime && durationEndTime) {
            addFilterSecond(duration, "Duration", getTotalSeconds(durationStartTime), getTotalSeconds(durationEndTime));
        }

        if (filter) {
            dataSort['filter'] = filter;
        }

        const startEntry = dataSort?.filter?.length
            ? dataSort.filter.find((item: { key: string; }) => item.key === "Start")
            : undefined;

        if (startEntry) {
            collapseAll();
            dispatch(getStatisticsWithPaginationFilterAndSorting(dataSort, jwtToken?.jwtToken));
            dispatch(setStatisticsFilter(dataSort));
            setSortData(dataSort);
            resetTableHorizontalScroll();
        } else {
            dispatch({type: GET_STATISTICS, payload: null})
        }

    }, [paginationPage, paginationSize, orderBy, isDescFilter, sortOrder, filterCampaignId, filterNameTo, filterTypeChannel, filterStatus, reactionClient, reactionOperator, duration, fullCallDuration, hasStartFilter, filterFinishDateTime]);

    useEffect(() => {
        if (activeClick?.item === "/statistics" && activeClick?.from ==='#/statistics') {
            dispatch(getStatisticsWithPaginationFilterAndSorting(sortData, jwtToken?.jwtToken));
        }
    }, [activeClick]);

    const monitoringSort = (event: { sortField: any; }) => {
        const handleAction = (sortField: string) => {
            let newOrderBy: string;

            switch (sortField) {
                case 'item.nameFrom':
                    newOrderBy = 'NameFrom';
                    break;
                case 'item.typeChannel':
                    newOrderBy = 'TypeChannel';
                    break;
                case 'item.start':
                    newOrderBy = 'Start';
                    break;
                case 'item.status':
                    newOrderBy = 'Status';
                    break;
                case 'item.nameTo':
                    newOrderBy = 'NameTo';
                    break;
                case 'item.campaignName':
                    newOrderBy = 'CampaignName';
                    break;
                case 'item.campaignId':
                    newOrderBy = 'CampaignId';
                    break;
                case 'item.callReset':
                    newOrderBy = 'CallReset';
                    break;
                case 'forcedClosing':
                    newOrderBy = 'ForcedClosing';
                    break;
                case 'item.fullName':
                    newOrderBy = 'Username';
                    break;
                case 'item.direction':
                    newOrderBy = 'Direction';
                    break;
                case 'item.queueId':
                    newOrderBy = 'QueueId';
                    break;
                case 'item.ownerCall':
                    newOrderBy = 'OwnerCall';
                    break;
                case 'FullCallDuration':
                    newOrderBy = 'FullCallDuration';
                    break;
                case 'Duration':
                    newOrderBy = 'Duration';
                    break;
                case 'ReactionOperator':
                    newOrderBy = 'ReactionOperator';
                    break;
                case 'ReactionClient':
                    newOrderBy = 'ReactionClient';
                    break;
                case 'End':
                    newOrderBy = 'End';
                    break;
                default:
                    return;
            }

            setOrderBy(newOrderBy);
            setIsDescFilter(!isDescFilter);
            setSortOrder(sortOrder === 1 ? -1 : 1);
            dispatch(setStatisticsCurrentPage(1));
            setFirst(0);
        };
        let sortField = event.sortField;
        handleAction(sortField);
    };

    const filterClearTemplate = (options: { filterClearCallback: React.MouseEventHandler<HTMLButtonElement> | undefined; field: string }) => {
        return <Button type="button" className="p-button-secondary" icon="pi pi-times" onClick={(e) => {
            if (options.filterClearCallback) {
                options.filterClearCallback(e);
            }
            if (options.field === 'start') {
                setStartTime(null);
                setEndTime(null);
                setFilterDateTime(null);
                setFilterEndDateTime(null);
            }
            if (options.field === 'end') {
                setStartFinishTime(null);
                setEndFinishTime(null);
                setFilterFinishDateTime(null);
                setFilterFinishEndDateTime(null);
            }
            if (options.field === 'reactionClient') {
                setReactionClientStartTime(null);
                setReactionClientEndTime(null);
                setReactionClient(false);
            }
            if (options.field === 'reactionOperator') {
                setReactionOperatorStartTime(null);
                setReactionOperatorEndTime(null);
                setReactionOperator(false);
            }
            if (options.field === 'duration') {
                setDuration(false);
                setDurationStartTime(null);
                setDurationEndTime(null);
            }
            if (options.field === 'fullCallDuration') {
                setfullCallDurationStartTime(null);
                setfullCallDurationEndTime(null);
                setFullCallDuration(false);
            }
        }}></Button>;
    }

    const filterApplyTemplate = (options: { filterApplyCallback: React.MouseEventHandler<HTMLButtonElement> | undefined; field: string; }) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} className=""
                       disabled={
                           (options.field === 'duration' && (!durationStartTime || !durationEndTime)) ||
                           (options.field === 'fullCallDuration' && (!fullCallDurationStartTime || !fullCallDurationEndTime)) ||
                           (options.field === 'reactionOperator' && (!reactionOperatorStartTime || !reactionOperatorEndTime)) ||
                           (options.field === 'reactionClient' && (!reactionClientStartTime || !reactionClientEndTime)) ||
                           (options.field === 'start' && (!startTime || !endTime || !filterDateTime || !filterEndDateTime)) ||
                           (options.field === 'end' && (!startFinishTime || !endFinishTime || !filterFinishDateTime || !filterFinishEndDateTime))}></Button>
    }

    useEffect(() => {
        dispatch(getUsers(user));
    }, []);

    const customFilter = (event?: any) => {
        if (event) {
            const filters = event.filters;
            const {end} = event.filters;
            const {fullCallDuration} = event.filters;
            const {duration} = event.filters;
            const {reactionClient} = event.filters;
            const {reactionOperator} = event.filters;

            setFullCallDuration(!!fullCallDuration.value);
            setDuration(!!duration.value);
            setReactionClient(!!reactionClient.value);
            setReactionOperator(!!reactionOperator.value);
            setFilterFinishDateTime(end.value || null);
            const setFilter = (filterName: string, key: string, defaultValue?: any | null) => {
                if (!filters[filterName]) {
                    return defaultValue;
                }

                const value = filters[filterName].value || filters[filterName].constraints?.[0]?.value;
                if (!value) {
                    return defaultValue;
                }

                if (Array?.isArray(value)) {
                    return value.map((item) => ({key, value: String(item)}));
                } else {
                    return {key, value: String(value)};
                }
            };

            setFilterCampaignId(setFilter('campaignId', 'CampaignId'));
            setFilterNameTo(setFilter('nameTo', 'NameTo'));
            setFilterNameFrom(setFilter('nameFrom', 'NameFrom'));
            setFilterTypeChannel(setFilter('typeChannel', 'TypeChannel', []));
            setFilterFullName(setFilter('fullName', 'Username', []));
            setFilterCampaignName(setFilter('campaignFullName', 'CampaignId', []));
            setFilterCallOwner(setFilter('ownerCall', 'OwnerCall', []));
            setFilterQueueName(setFilter('queueFullName', 'QueueId', []));
            setFilterDirection(setFilter('direction', 'Direction', []));
            setFilterStatus(setFilter('status', 'Status', []));
            setFilterAmdHangup(setFilter('amdHangup', 'ForcedClosing', []));
            setFilterCallReset(setFilter('callResetName', 'CallReset', []));
        } else {
            setFilterCampaignId(null);
            setFilterFullName(null);
            setFilterNameTo(null);
            setFilterNameFrom(null);
            setFilterStatus(null);
            setFilterCampaignName(null);
            setFilterTypeChannel(null);
            setFilterAmdHangup(null);
            setFilterCallReset(null);
            setOrderBy(null);
            setIsDescFilter(true);
            setFilterDirection(null);
            setFilterQueueName(null);
            setFilterCallOwner(null);
        }
    };

    const renderHeader = (field: string, sortField: string) => {
        const sorted = sortField === orderBy;
        const sortIcon = classNames('p-sortable-column-icon', {
            'pi pi-sort-amount-up-alt': sorted && sortOrder === 1,
            'pi pi-fw pi-sort-amount-down': sorted && sortOrder === -1,
            'pi pi-fw pi-sort-alt': sortOrder === 0 || !sorted
        });

        return (
            <div className="flex align-items-center" onClick={() => monitoringSort({'sortField': sortField})}>
                {field} <i className={sortIcon}></i>
            </div>
        );
    };

    const toggleTab = (tableName: string) => {
        setSelectedRowData(null);
        setActiveTab(tableName);
    };

    const isMobile = () => {
        return windowWidth <= 1248;
    }

    const renderActivityColumn = ({item}: DataRow) => {
        return item?.typeChannel === 'transferInternal' && !item.isDeclined ? (
            <div className="relative">
                <i className="pi pi-reply p-2 bg-green-500 border-circle text-white"
                   style={{transform: 'rotate(90deg) scaleX(1) scaleY(-1)'}}></i>
            </div>
        ) : item?.typeChannel === 'transferInternal' && item.isDeclined ? (
                <div className="relative">
                    <i className="pi pi-reply p-2 bg-green-500 border-circle text-white"
                       style={{transform: 'rotate(90deg) scaleX(1) scaleY(-1)'}}></i>
                    <div className="absolute" style={{top: '-5px', left: '18px'}}>
                        <div className="border-circle relative"
                             style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                            <i className="pi pi-times text-white absolute left-50 top-50 kk"
                               style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                        </div>
                    </div>
                </div>) :

            item?.typeChannel === 'transferExternal' && !item.isDeclined ? (
                <div className="relative">
                    <i className="pi pi-reply p-2 bg-primary border-circle text-white"
                       style={{transform: 'rotate(90deg) scaleX(-1) scaleY(-1)'}}></i>
                </div>
            ) : item?.typeChannel === 'transferInternal' && item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-reply p-2 bg-primary border-circle text-white"
                           style={{transform: 'rotate(90deg) scaleX(-1) scaleY(-1)'}}></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative"
                                 style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                                <i className="pi pi-times text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div>)
                : item?.typeChannel === 'callback' ? (
                    <div className="relative">
                        <i className="pi pi-undo p-2 border-circle text-white" style={{backgroundColor: "#fda610"}}></i>
                    </div>
                ) : item?.typeChannel === 'internal' && !item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-arrow-down-left p-2 border-circle bg-green-500 text-white"></i>
                    </div>
                ) : item?.typeChannel === 'internal' && item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-arrow-down-left p-2 border-circle bg-green-500 text-white"></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative"
                                 style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                                <i className="pi pi-times text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div>) : item?.typeChannel === 'external' && !item.isDeclined ? (
                    <>
                        <i className="pi pi-arrow-up-right bg-primary p-2 border-circle text-white"></i>
                    </>
                ) : item?.typeChannel === 'external' && item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-arrow-up-right bg-primary p-2 border-circle text-white"></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative"
                                 style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                                <i className="pi pi-times text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div>) : <></>;
    };

    const handleResetPlayer = () => {
        dispatch({type: UPLOAD_AUDIO_LOADING, payload: false});
        dispatch(getAudioUrlForPlayer(null));
        setKey(key + 1);
    };

    const typeBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rowData.item?.ownerCall}
                </span>
            </React.Fragment>
        );
    };

    const typeOperatorTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rowData.item?.fullName}
                </span>
            </React.Fragment>
        );
    };


    const typeDirectionBodyTemplate = (rowData: DataRow) => {
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rowData.item?.direction}
                </span>
            </React.Fragment>
        );
    };

    const campaignBodyTemplate = (rowData: DataRow) => {
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rowData.item?.campaignFullName}
                </span>
            </React.Fragment>
        );
    };

    const queueBodyTemplate = (rowData: DataRow) => {
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rowData.item?.queueFullName}
                </span>
            </React.Fragment>
        );
    };

    const callResetBodyTemplate = (rowData: any) => {
        const foundCallReset = callResetDictionary?.filter((item: any) => item?.dictionaryId === rowData?.item?.callReset);
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {foundCallReset?.[0]?.name}
                </span>
            </React.Fragment>
        );
    };

    const amdHangupBodyTemplate = (rowData: any) => {
        const foundAmdHangup = forcedClosingDictionary?.filter((item: any) => item?.dictionaryId === rowData?.item?.forcedClosing);
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {foundAmdHangup?.[0]?.name}
                </span>
            </React.Fragment>
        );
    };

    const renderStatusColumn = (rowData: any) => {
        let foundStatus = statusDictionary?.filter((status: any) => status?.dictionaryId === rowData?.item?.status);

        const statusMap: {
            [key: string]: string
        } = {
            101: 'p-badge status-active',
            103: 'p-badge status-success',
            104: 'p-badge status-unsuccessful',
            ACTION: 'p-badge status-action',
            102: 'p-badge status-processing'
        };

        const statusClass = statusMap[foundStatus?.[0]?.dictionaryId];

        return (
            <Badge value={foundStatus?.[0]?.name} className={`${statusClass} text-white customer-badge w-6 text-xs p-0`}
                   style={{maxWidth: '95px', minWidth: '95px'}}/>
        );
    };

    const amdHangupFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Виберіть:</div>
                <MultiSelect
                    value={options.value}
                    options={forcedClosingDictionary}
                    optionLabel='name'
                    optionValue={'dictionaryId'}
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };
    const callResetFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Виберіть ініціатора</div>
                <MultiSelect
                    value={options.value}
                    options={callResetDictionary || null}
                    optionValue="dictionaryId"
                    optionLabel="name"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const statusFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть статус</div>
                <MultiSelect
                    value={options.value}
                    options={statusDictionary || null}
                    optionValue="dictionaryId"
                    optionLabel="name"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const directionFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть статус</div>
                <MultiSelect
                    value={options.value}
                    optionValue="name"
                    options={queuesDirectoryDirectionType}
                    optionLabel="name"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };
    const usersDataWithFullName = usersData?.map((userData: any) => {
        return {
            ...userData,
            fullName: `${userData?.lastName} ${userData?.firstName} ${userData?.middleName ? `${userData.middleName}` : ''}`
        }
    });

    const campaignListWithId = campaignList?.campaigns?.map((campaignData: any) => {
        return {
            ...campaignData,
            campaignWithId: `${campaignData.id} ${campaignData.name}`
        }
    });

    const queueListWithId = campaignList?.queues?.map((queueData: any) => {
        return {
            ...queueData,
            queueWithId: `${queueData?.id} ${queueData?.name}`
        }
    });

    const operatorFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть:</div>
                <MultiSelect
                    value={options.value}
                    optionValue="userName"
                    selectedItemsLabel={options.value ? `Обрано: ${options.value.length}` : ""}
                    filter
                    options={usersDataWithFullName}
                    maxSelectedLabels={0}
                    optionLabel="fullName"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"

                />
            </>
        );
    };

    const ownerFilterTemplate = (options: any) => {
        let usersData: any
        if (!Array.isArray(usersDataWithFullName)) {
            console.error('usersDataWithFullName is not an array');
        } else {
            usersData = [
                {value: "", userName: ""},
                ...usersDataWithFullName
            ];
        }
        return (
            <>
                <div className="mb-3 text-bold">Оберіть:</div>
                <MultiSelect
                    value={options.value}
                    optionValue="userName"
                    selectedItemsLabel={options.value ? `Обрано: ${options.value.length}` : ""}
                    filter
                    options={usersData}
                    maxSelectedLabels={0}
                    optionLabel="userName"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"

                />
            </>
        );
    };

    const campaignFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть:</div>
                <MultiSelect
                    value={options.value}
                    optionValue="id"
                    selectedItemsLabel={options.value ? `Обрано: ${options.value.length}` : ""}
                    filter
                    options={campaignListWithId}
                    maxSelectedLabels={0}
                    optionLabel="campaignWithId"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"

                />
            </>
        );
    };

    const queueFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть:</div>
                <MultiSelect
                    value={options.value}
                    optionValue="id"
                    selectedItemsLabel={options.value ? `Обрано: ${options.value.length}` : ""}
                    filter
                    options={queueListWithId}
                    maxSelectedLabels={0}
                    optionLabel="queueWithId"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"

                />
            </>
        );
    };

    const typeChannelItemTemplate = (option: any) => {
        let statusName = typeChannelDict.map((item) => {
            if (item.code === option) {
                return item.name
            }
        })
        return (
            <div className="p-multiselect-representative-option">
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {statusName}
                </span>
            </div>
        );
    }

    const selectedTypeChannelTemplate = (option: any) => {
        let statusName = typeChannelDict.map((item) => {
            if (item.code === option) {
                return item.name
            }
        })
        if (option) {
            return (
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {statusName},
                </span>
            );
        }

        return "Оберіть тип";
    }

    const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <>
            <span className="title">Виберіть дату</span>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>З*</span>
                <Calendar className="mt-2" value={filterDateTime ? filterDateTime : null} onChange={(e: any) => {
                    options.filterCallback(e.value, options.index);
                    setFilterDateTime(e.value)
                }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
            </div>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>По*</span>
                <Calendar className="mt-2" value={filterEndDateTime ? filterEndDateTime : null} onChange={(e: any) => {
                    setFilterEndDateTime(e.value)
                }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
            </div>
            <div className="flex max-w-15rem">
                <div className="md:col-6">
                    <div className="time-item">
                        <span className="title">З*</span>
                        <Calendar
                            appendTo="self"
                            id="icon"
                            value={startTime ? startTime : null}
                            timeOnly
                            onChange={(e) => setStartTime(e.value)}
                            hourFormat="24"
                        />
                    </div>
                </div>
                <div className="md:col-6">
                    <div className="time-item">
                        <span className="title">По*</span>
                        <Calendar
                            appendTo="self"
                            id="icon"
                            value={endTime ? endTime : null}
                            required
                            timeOnly
                            onChange={(e) => setEndTime(e.value)}
                            hourFormat="24"
                        />
                    </div>
                </div>
            </div>
        </>
    };

    const dateEndFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <>
            <span className="title">Виберіть дату</span>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>З*</span>
                <Calendar className="mt-2" value={filterFinishDateTime ? filterFinishDateTime : null}
                          onChange={(e: any) => {
                              options.filterCallback(e.value, options.index);
                              setFilterFinishDateTime(e.value)
                          }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
            </div>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>По*</span>
                <Calendar className="mt-2" value={filterFinishEndDateTime ? filterFinishEndDateTime : null}
                          onChange={(e: any) => {
                              setFilterFinishEndDateTime(e.value)
                          }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
            </div>
            <div className="flex max-w-15rem">
                <div className="md:col-6">
                    <div className="time-item">
                        <span className="title">З*</span>
                        <Calendar
                            appendTo="self"
                            id="icon"
                            value={startFinishTime ? startFinishTime : null}
                            timeOnly
                            onChange={(e) => setStartFinishTime(e.value)}
                            hourFormat="24"
                        />
                    </div>
                </div>
                <div className="md:col-6">
                    <div className="time-item">
                        <span className="title">По*</span>
                        <Calendar
                            appendTo="self"
                            id="icon"
                            value={endFinishTime ? endFinishTime : null}
                            required
                            timeOnly
                            onChange={(e) => setEndFinishTime(e.value)}
                            hourFormat="24"
                        />
                    </div>
                </div>
            </div>
        </>
    };

    const fullCallDurationFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <>
            <span className="title">Виберіть час:</span>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>З*</span>
                <Calendar timeOnly showSeconds className="mt-2"
                          value={fullCallDurationStartTime ? fullCallDurationStartTime : 0} onChange={(e: any) => {
                    options.filterCallback(e.value, options.index);
                    setfullCallDurationStartTime(e.value)
                }} placeholder="00:00:00"/>
            </div>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>По*</span>
                <Calendar timeOnly showSeconds className="mt-2"
                          value={fullCallDurationEndTime ? fullCallDurationEndTime : 0} onChange={(e: any) => {
                    setfullCallDurationEndTime(e.value)
                }} placeholder="00:00:00"/>
            </div>
        </>
    };

    const durationFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <>
            <span className="title">Виберіть час:</span>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>З*</span>
                <Calendar timeOnly showSeconds className="mt-2" value={durationStartTime ? durationStartTime : 0}
                          onChange={(e: any) => {
                              options.filterCallback(e.value, options.index);
                              setDurationStartTime(e.value)
                          }} placeholder="00:00:00"/>
            </div>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>По*</span>
                <Calendar timeOnly showSeconds className="mt-2" value={durationEndTime ? durationEndTime : 0}
                          onChange={(e: any) => {
                              setDurationEndTime(e.value)
                          }} placeholder="00:00:00"/>
            </div>
        </>
    };

    const reactionClientFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <>
            <span className="title">Виберіть час:</span>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>З*</span>
                <Calendar timeOnly showSeconds className="mt-2"
                          value={reactionClientStartTime ? reactionClientStartTime : 0} onChange={(e: any) => {
                    options.filterCallback(e.value, options.index);
                    setReactionClientStartTime(e.value)
                }} placeholder="00:00:00"/>
            </div>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>По*</span>
                <Calendar timeOnly showSeconds className="mt-2"
                          value={reactionClientEndTime ? reactionClientEndTime : 0} onChange={(e: any) => {
                    setReactionClientEndTime(e.value)
                }} placeholder="00:00:00"/>
            </div>
        </>
    };

    const reactionOperatorFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <>
            <span className="title">Виберіть час:</span>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>З*</span>
                <Calendar timeOnly showSeconds className="mt-2"
                          value={reactionOperatorStartTime ? reactionOperatorStartTime : 0} onChange={(e: any) => {
                    options.filterCallback(e.value, options.index);
                    setReactionOperatorStartTime(e.value)
                }} placeholder="00:00:00"/>
            </div>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>По*</span>
                <Calendar timeOnly showSeconds className="mt-2"
                          value={reactionOperatorEndTime ? reactionOperatorEndTime : 0} onChange={(e: any) => {
                    setReactionOperatorEndTime(e.value)
                }} placeholder="00:00:00"/>
            </div>
        </>
    };

    const typeChannelFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть тип</div>
                <MultiSelect
                    value={options.value}
                    options={typeChannelDict.map((item) => item.code)}
                    itemTemplate={typeChannelItemTemplate}
                    selectedItemTemplate={selectedTypeChannelTemplate}
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter mb-3"
                />
            </>
        );
    };

    const BSessionIcon = () => {
        return <i className="pi pi-reply mr-2" style={{fontSize: '1rem', transform: 'rotate(270deg)'}}></i>
    }

    // const convertMillisecondsToTime = (totalMilliseconds: number): string => {
    //     const hours = Math.floor(totalMilliseconds / (1000 * 60 * 60));
    //     const minutes = Math.floor((totalMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    //     const seconds = Math.floor((totalMilliseconds % (1000 * 60)) / 1000);
    //
    //     return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    // };

    const convertSecondsToTime = (totalSeconds: number): string => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Math.floor(totalSeconds % 60);

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const firstLevelTableCase = (name: string, i: number) => {
        let itemName: any;

        switch (name) {
            case "nameTo":
                itemName = <Column
                    key={i}
                    field="item.nameTo"
                    filterField="nameTo"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть назву клiента"
                    filter
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterMenuClassName={'filterStart'}
                    bodyClassName={(rowData) => rowData.item?.isMatchingSessionId ? 'your-custom-class' : ''}
                    header={'Клiент'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '150px'}}
                />;
                break;
            case "ownerCall":
                itemName = <Column
                    key={i}
                    field="item.ownerCall"
                    filterField="ownerCall"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть назву власника"
                    filter
                    filterElement={ownerFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    header={'Власник дзвінка'}
                    body={typeBodyTemplate}
                    filterMenuClassName={'filterStart'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "fullName":
                itemName = <Column
                    field="item.fullName"
                    key={i}
                    sortable
                    filterField="fullName"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть назву оператора"
                    filter
                    filterElement={operatorFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    header={renderHeader('Оператор', 'Username')}
                    body={typeOperatorTemplate}
                    filterMenuClassName={'filterStart'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "direction":
                itemName = <Column
                    field="item.direction"
                    key={i}
                    filterField="direction"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть назву оператора"
                    filter
                    filterElement={directionFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    header={'Тип Активності'}
                    body={typeDirectionBodyTemplate}
                    filterMenuClassName={'filterStart'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "campaignFullName":
                itemName = <Column
                    field="item.campaignId"
                    sortable
                    key={i}
                    filterField="campaignFullName"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть назву кампанії"
                    filter
                    body={campaignBodyTemplate}
                    filterElement={campaignFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterMenuClassName={'filterStart'}
                    header={renderHeader('ID / Назва Кампанii', 'CampaignId')}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "queueFullName":
                itemName = <Column
                    field="item.queueId"
                    sortable
                    key={i}
                    filterField="queueFullName"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть назву черги"
                    filter
                    body={queueBodyTemplate}
                    filterElement={queueFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterMenuClassName={'filterStart'}
                    header={renderHeader('ID / Назва Черги', 'QueueId')}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "status":
                itemName = <Column
                    field="item.status"
                    header={renderHeader('Статус', 'Status')}
                    sortable
                    key={i}
                    filterField="status"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterElement={statusFilterTemplate}
                    filter
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterMenuClassName={'filterStart'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '150px'}}
                    body={renderStatusColumn}
                />;
                break;
            case "typeChannel":
                itemName = <Column
                    field="item.typeChannel"
                    header={'Активність'}
                    key={i}
                    filterField="typeChannel"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть ID кампанії"
                    filter
                    filterElement={typeChannelFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    body={renderActivityColumn}
                    filterMenuClassName={'filterStart'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '150px'}}
                />;
                break;
            case "callResetName":
                itemName = <Column
                    field="item.callReset"
                    sortable
                    filterField="callResetName"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filter
                    key={i}
                    filterElement={callResetFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    header={renderHeader('Завершив', 'CallReset')}
                    body={callResetBodyTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    filterMenuClassName={'filterStart'}
                />;
                break;
            case "amdHangup":
                itemName = <Column
                    field="forcedClosing"
                    header={renderHeader('Причина завершення', 'ForcedClosing')}
                    sortable
                    key={i}
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterElement={amdHangupFilterTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    body={amdHangupBodyTemplate}
                    filterMenuClassName={'filterStart'}
                    filter
                    filterField="amdHangup"
                />;
                break;
            case "start":
                itemName = <Column
                    field="item.start"
                    filterField="start"
                    key={i}
                    filterElement={dateFilterTemplate}
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть ID кампанії"
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterMenuClassName={'filterStart'}
                    header={renderHeader('Початок', 'Start')}
                    sortable
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "fullCallDuration":
                itemName = <Column
                    key={i}
                    header={renderHeader('Тривалість дзвінка', 'FullCallDuration')}
                    className="justify-content-start"
                    body={(data) => data.item.fullCallDuration && data?.item?.fullCallDuration !== -1 ? convertSecondsToTime(data?.item.fullCallDuration) : data?.item?.fullCallDuration === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    sortable
                    filterPlaceholder="Введіть час"
                    filter
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterField="fullCallDuration"
                    filterElement={fullCallDurationFilterTemplate}
                />;
                break;
            case "duration":
                itemName = <Column
                    key={i}
                    header={renderHeader('Тривалість розмови', 'Duration')}
                    className="justify-content-start"
                    body={(data) => data?.item?.duration && data?.item?.duration !== -1 ? convertSecondsToTime(data?.item?.duration) : data?.item?.duration === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    sortable
                    filterPlaceholder="Введіть час"
                    filter
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterField="duration"
                    filterElement={durationFilterTemplate}
                />;
                break;
            case "reactionClient":
                itemName = <Column
                    key={i}
                    header={renderHeader('Реакція Клієнта', 'ReactionClient')}
                    className="justify-content-start"
                    body={(data) => data?.item?.reactionClient && data?.item?.reactionClient !== -1 ? convertSecondsToTime(data?.item?.reactionClient) : data?.item?.reactionClient === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    sortable
                    filterPlaceholder="Введіть час"
                    filter
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterField="reactionClient"
                    filterElement={reactionClientFilterTemplate}
                />;
                break;
            case "reactionOperator":
                itemName = <Column
                    key={i}
                    header={renderHeader('Реакція Оператора', 'ReactionOperator')}
                    className="justify-content-start"
                    body={(data) => data?.item?.reactionOperator && data?.item?.reactionOperator !== -1 ? convertSecondsToTime(data?.item?.reactionOperator) : data?.item?.reactionOperator === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    sortable
                    filterPlaceholder="Введіть час"
                    filter
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterField="reactionOperator"
                    filterElement={reactionOperatorFilterTemplate}
                />;
                break;
            case "end":
                itemName = <Column
                    key={i}
                    header={renderHeader('Завершення', 'End')}
                    className="justify-content-start"
                    body={(data) => data?.item?.end ? formatDateTime(data?.item?.end) : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    sortable
                    filterPlaceholder="Введіть час"
                    filter
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterField="end"
                    filterElement={dateEndFilterTemplate}
                />;
                break;
            default:
                itemName = name;
        }

        return itemName;
    }

    const secondLevelTableCase = (name: string, i: number) => {
        let itemName: any;
        switch (name) {
            case "nameTo":
                itemName = <Column
                    key={i}
                    className="justify-content-start"
                    field="item.nameTo"
                    headerClassName='hidden'
                    style={{cursor: 'pointer', width: `100%`, minWidth: '150px'}}
                />;
                break;
            case "ownerCall":
                itemName = <Column
                    key={i}
                    field="ownerCall"
                    className="justify-content-start"
                    headerClassName='hidden'
                    body={typeBodyTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "fullName":
                itemName = <Column
                    key={i}
                    field="fullName"
                    className="justify-content-start"
                    headerClassName='hidden'
                    body={typeOperatorTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "direction":
                itemName = <Column
                    key={i}
                    className="justify-content-start"
                    field="direction"
                    headerClassName='hidden'
                    body={typeDirectionBodyTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "campaignFullName":
                itemName = <Column
                    key={i}
                    className="justify-content-start"
                    field="campaignId"
                    headerClassName='hidden'
                    body={campaignBodyTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "queueFullName":
                itemName = <Column
                    key={i}
                    field="queueId"
                    className="justify-content-start queue-column"
                    body={queueBodyTemplate}
                    headerClassName='hidden'
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "status":
                itemName = <Column
                    key={i}
                    className="justify-content-start status-column"
                    field="status"
                    headerClassName='hidden'
                    style={{cursor: 'pointer', width: `100%`, minWidth: '150px'}}
                    body={renderStatusColumn}
                />;
                break;
            case "typeChannel":
                itemName = <Column
                    key={i}
                    field="typeChannel"
                    className="justify-content-start channel-column"
                    body={renderActivityColumn}
                    headerClassName='hidden'
                    style={{cursor: 'pointer', width: `100%`, minWidth: '150px'}}
                />;
                break;
            case "callResetName":
                itemName = <Column
                    key={i}
                    field="callResetName"
                    className="justify-content-start"
                    headerClassName='hidden'
                    body={callResetBodyTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "amdHangup":
                itemName = <Column
                    key={i}
                    field="amdHangup"
                    className="justify-content-start"
                    headerClassName='hidden'
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    body={amdHangupBodyTemplate}
                />;
                break;
            case "start":
                itemName = <Column
                    key={i}
                    field="item.start"
                    className="justify-content-start"
                    sortable
                    headerClassName='hidden'
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "fullCallDuration":
                itemName = <Column
                    key={i}
                    header={'Тривалість дзвінка'}
                    headerClassName='hidden'
                    className="justify-content-start"
                    body={(data) => data.item.fullCallDuration && data?.item?.fullCallDuration !== -1 ? convertSecondsToTime(data?.item.fullCallDuration) : data?.item?.fullCallDuration === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "duration":
                itemName = <Column
                    key={i}
                    header={'Тривалість розмови'}
                    headerClassName='hidden'
                    className="justify-content-start"
                    body={(data) => data?.item?.duration && data?.item?.duration !== -1 ? convertSecondsToTime(data?.item?.duration) : data?.item?.duration === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "reactionClient":
                itemName = <Column
                    key={i}
                    header={'Реакція Клієнта'}
                    headerClassName='hidden'
                    className="justify-content-start"
                    body={(data) => data?.item?.reactionClient && data?.item?.reactionClient !== -1 ? convertSecondsToTime(data?.item?.reactionClient) : data?.item?.reactionClient === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "reactionOperator":
                itemName = <Column
                    key={i}
                    header={'Реакція Оператора'}
                    className="justify-content-start"
                    headerClassName='hidden'
                    body={(data) => data?.item?.reactionOperator && data?.item?.reactionOperator !== -1 ? convertSecondsToTime(data?.item?.reactionOperator) : data?.item?.reactionOperator === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "end":
                itemName = <Column
                    key={i}
                    header={'Завершення'}
                    headerClassName='hidden'
                    className="justify-content-start"
                    body={(data) => data?.item?.end ? formatDateTime(data?.item?.end) : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}

                />;
                break;
            default:
                itemName = name;
        }

        return itemName;
    }

    const rowExpansionBSessionTemplate = (data: DataRow) => {
        return (
            <DataTable
                value={data?.children}
                className="p-datatable-customers monitoring-table monitoring-table-td monitoring-Expansion"
                selectionMode="single"
                rowClassName={dynamicBSessionRowClass}
                selection={selectedBSessionRowExpansion}
                onRowClick={(e) => {
                    setSelectedRowData(null);
                    handleResetPlayer();
                    dispatch(getEventStatisticToUserDetail(e.data.item.id, jwtToken?.jwtToken));
                    if (!isMobile) {
                        if (props.profileMenuPin === true) {
                            setSelectedRowExpansion(e.data);
                            setVisible(true);
                        } else {
                            setSelectedRowExpansion(e.data);
                        }
                    } else {
                        setSelectedRowExpansion(e.data);
                        setVisible(true);
                    }
                }}
                dataKey="item.bSessionId"
            >
                <Column headerClassName={'hidden'} body={BSessionIcon}
                        style={{minWidth: '6rem', display: 'flex', alignItems: 'center', justifyContent: 'end'}}/>
                {selectedColumns && selectedColumns?.length &&
                    selectedColumns.sort((a: any, b: any) => a.order - b.order)
                        .map((col: any, i: number) => (
                            isColumnSelected(col.name) && secondLevelTableCase(col.name, i)
                        ))}
            </DataTable>
        );
    };

    const sessionBodyIcon = (rowData: any) => {
        if (rowData?.children?.length) {
            return <></>
        }
    }

    const rowExpansionTemplate = (data: DataRow) => {
        return (
            <DataTable
                value={data?.children}
                className="p-datatable-customers monitoring-table monitoring-table-td monitoring-Expansion"
                selectionMode="single"
                selection={selectedSessionRowExpansion}
                rowExpansionTemplate={rowExpansionBSessionTemplate}
                expandedRows={expandedSessionRows}
                onRowToggle={(e) => setExpandedSessionRows(e.data)}
                onRowClick={(e) => {
                    handleResetPlayer();
                    dispatch(getEventStatisticToUserDetail(e.data.item.id, jwtToken?.jwtToken));
                    setSelectedRowData(null);
                    if (!isMobile) {
                        if (props.profileMenuPin === true) {
                            setSelectedRowExpansion(e.data);
                            setVisible(true);
                        } else {
                            setSelectedRowExpansion(e.data);
                        }
                    } else {
                        setSelectedRowExpansion(e.data);
                        setVisible(true);
                    }
                }}
                dataKey="item.bSessionId"
            >
                <Column rowReorderIcon={'pi pi-close'} headerClassName={'hidden'} expander={allowExpansion}
                        style={{minWidth: '4rem', display: 'flex', alignItems: 'center', justifyContent: 'end'}}/>
                <Column headerClassName={'hidden'} body={sessionBodyIcon} expander={allowExpansion}
                        style={{minWidth: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}/>
                {selectedColumns && selectedColumns?.length &&
                    selectedColumns.sort((a: any, b: any) => a.order - b.order)
                        .map((col: any, i: number) => (
                            isColumnSelected(col.name) && secondLevelTableCase(col.name, i)
                        ))}
            </DataTable>
        );
    };

    const allowExpansion = (rowData: DataRow) => {
        return rowData.children?.length || false;
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };

    const dynamicRowClass = (data: { item: any; }) => {
        if (data?.item?.bSessionId === selectedRowData?.item?.bSessionId) {
            return 'mainRowMonitoringTable p-highlight';
        }
        return 'mainRowMonitoringTable';
    };

    const dynamicBSessionRowClass = () => {
        return 'BSessionRow';
    };

    const isColumnSelected = (columnName: string) => {
        return selectedColumns?.some((item: { name: string }) => item.name === columnName);
    };
    const renderTable = () => {
        return (
            <div className="layout-dashboard">
                <div className="flex flex-wrap col-12 md:col-12">
                    <div
                        className={classNames('card widget-table mr-4 col-12 transition-duration-300 monitoring-table align-self-start monitoringLoader', {'': (selectedRowData || selectedRowExpansion) && !isMobile(), 'md:col-12': !(selectedRowData || selectedRowExpansion)})}>
                        <StatisticsListNav hasStartFilter={hasStartFilter} setHasStartFilter={setHasStartFilter}
                                           setEndTime={setEndTime} endTime={endTime} startTime={startTime}
                                           setStartTime={setStartTime} setFilterEndDateTime={setFilterEndDateTime}
                                           filterEndDateTime={filterEndDateTime} setFilterDateTime={setFilterDateTime}
                                           filterDateTime={filterDateTime}
                                           userSortFields={userStatisticSortFields?.fields} sortData={sortData}
                                           resetSorting={handleReset} toggleTab={toggleTab}
                                           activeTab={activeTab}/>
                        <DataTable
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            rowExpansionTemplate={rowExpansionTemplate}
                            scrollable
                            scrollHeight={(selectedRowData || selectedRowExpansion) && !isMobile() ? '40vh' : '70vh'}
                            ref={dataTableRef}
                            rows={10}
                            onSort={monitoringSort}
                            filters={clearFilters}
                            loading={loader}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            value={formattedData}
                            className="p-datatable-customers monitoring-table monitoring-table-rowGroup"
                            rowClassName={dynamicRowClass}
                            selectionMode="single"
                            emptyMessage="Нічого не знайдено"
                            rowHover={false}
                            onFilter={customFilter}
                            onRowClick={(e) => {
                                setSelectedRowExpansion(null);
                                handleResetPlayer();
                                dispatch(getEventStatisticToUserDetail(e.data.item.id, jwtToken?.jwtToken));
                                if (!isMobile) {
                                    if (props.profileMenuPin === true) {
                                        setSelectedRowData(e.data);
                                        setVisible(true);
                                    } else {
                                        setSelectedRowData(e.data);
                                    }
                                } else {
                                    setSelectedRowData(e.data);
                                    setVisible(true);
                                }
                            }} dataKey="item.bSessionId"
                        >
                            <Column rowReorderIcon={'pi pi-close'} expander={allowExpansion}
                                    style={{minWidth: '6rem'}}/>
                            {selectedColumns && selectedColumns?.length &&
                                selectedColumns.sort((a: any, b: any) => a.order - b.order)
                                    .map((col: any, i: number) => (
                                        isColumnSelected(col.name) && firstLevelTableCase(col.name, i)
                                    ))}
                        </DataTable>
                        <Paginator
                            first={first}
                            rows={paginationSize}
                            totalRecords={totalItems}
                            rowsPerPageOptions={[5, 10, 15, 25, 50]}
                            onPageChange={onPageChange}
                            className="border-top-1 border-bottom-1"
                            template="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                        />
                    </div>
                    {(selectedRowData || selectedRowExpansion) && statisticData && (
                        <>
                            {windowWidth > 1248 && <div
                                className={classNames('card widget-table col-12 md:col-12 align-self-start sticky top-0')}>
                                <StatisticsListDetails setSelectedRowData={setSelectedRowData}
                                                       setSelectedRowExpansion={setSelectedRowExpansion}
                                                       handleResetPlayer={handleResetPlayer}/>
                            </div>}

                            {windowWidth <= 1248 && <div>
                                <Dialog visible={visible} className="monitoring-info" onHide={() => setVisible(false)}>
                                    <StatisticsListDetails setSelectedRowData={setSelectedRowData}
                                                           setSelectedRowExpansion={setSelectedRowExpansion}
                                                           handleResetPlayer={handleResetPlayer}/>
                                </Dialog>
                            </div>}
                        </>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div>
            {renderTable()}
        </div>
    );
};

export default StatisticsList;
