import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {classNames} from "primereact/utils";
import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {InputSwitch} from "primereact/inputswitch";
import {Button} from "primereact/button";
import {ColorPicker} from "primereact/colorpicker";
import {addStatusGroupBranch, deleteStatusBranch, setStatusField} from "../../../redux/actions/actionsStatuses";
import {Badge} from "primereact/badge";
import {MultiSelect} from "primereact/multiselect";
import {Divider} from "primereact/divider";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {setBranchDialogVisible} from "../../../redux/actions/actionsBranchList";
import {getBranchDetailById} from "../../../redux/api/apiBranchList";
import {setEditType} from "../../../redux/actions/actions";

const StatusesListDialogGeneralSettings = () => {
    const dispatch = useAppDispatch();
    const errorFields = useAppSelector(state => state.StatusesListReducer.errorFields) as unknown as any;
    const descriptionField = useRef(null);
    const nameField = useRef(null);
    const statusData = useAppSelector(state => state.StatusesListReducer.statusData) as unknown as any;
    const [colorHEX, setColorHEX] = useState<any>('ffffff');
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
    const archiveStatusTableToggle = useAppSelector(state => state.StatusesListReducer.archiveStatusTableToggle);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    let inSystem = statusData?.inSystem;
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);
    const replaceStatusName = statusData?.statusName?.replace(/Copy.*/, 'Copy...');
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const branchPermissions = checkPermissions(rolesVision, 'Branch');
    const branchDetailsLoader = useAppSelector(state => state.BranchesListReducer.branchDetailsLoader);
    const [currentUserId, setCurrentUserId] = useState<any>(null);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    const {
        visible: hasBranchVisionPermission,
        update: hasBranchUpdatePermission
    } = branchPermissions;

    useEffect(() => {
        dispatch(setBranchDialogVisible(false));
    }, []);

    const colors = [
        {value: '#232d64', className: 'bg-indigo-800'},
        {value: '#6d1b7b', className: 'bg-purple-700'},
        {value: '#125386', className: 'bg-blue-800'},
        {value: '#006775', className: 'bg-cyan-800'},
        {value: '#2a602c', className: 'bg-green-800'},
        {value: '#b08620', className: 'bg-yellow-800'},
        {value: '#ac5700', className: 'bg-orange-700'},
        {value: '#a31545', className: 'bg-pink-700'},

        {value: '#36459a', className: 'bg-indigo-600'},
        {value: '#c279ce', className: 'bg-purple-300'},
        {value: '#4baaf5', className: 'bg-blue-400'},
        {value: '#00bcd4', className: 'bg-cyan-500'},
        {value: '#4caf50', className: 'bg-green-500'},
        {value: '#fbc02d', className: 'bg-yellow-500'},
        {value: '#f57c00', className: 'bg-orange-500'},
        {value: '#e91e63', className: 'bg-pink-500'},

        {value: '#6372c3', className: 'bg-indigo-400'},
        {value: '#d4a2dd', className: 'bg-purple-200'},
        {value: '#75bef8', className: 'bg-blue-300'},
        {value: '#58d4e5', className: 'bg-cyan-200'},
        {value: '#90cd93', className: 'bg-green-300'},
        {value: '#dcbe72', className: 'bg-yellow-200'},
        {value: '#f9ae61', className: 'bg-orange-300'},
    ];

    const scrollIntoNameView = () => {
        // @ts-ignore
        nameField?.current?.scrollIntoView({behavior: 'smooth'});
    }

    const scrollIntoDescriptionView = () => {
        // @ts-ignore
        descriptionField?.current?.scrollIntoView({behavior: 'smooth'});
    }

    useEffect(() => {
        if (!statusData.name && errorFields?.name) {
            scrollIntoNameView();
        }
        if (!statusData.description && errorFields?.description) {
            scrollIntoDescriptionView();
        }
    }, [errorFields, statusData]);

    const onChangeNameField = (e: any) => {
        dispatch(setStatusField({section: 'statusData', fieldName: 'statusName', value: e.target.value}));
    }

    const formattedBranches = branches?.map((branch: { id: any; name: any; }) => ({
        ...branch,
        label: `${branch.id} ${branch.name}`,
    }));

    const branchesValue = statusData?.statusBranches?.map((item: any) => {
        const user = formattedBranches.find((userItem: any) => userItem.id === item.id);
        const {statusId, ...userWithoutCampaignId} = item;
        return {
            ...userWithoutCampaignId,
            description: user?.description,
            id: user?.id,
            isArchived: user?.isArchived,
            name: user?.name,
            label: user?.label,
            ownerId: user?.ownerId,
            ownerName: user?.ownerName,
            parentId: user?.parentId,
            parentName: user?.parentName,
            userCount: user?.userCount,
            integrationURLId: user?.integrationURLId,
            isMainIntegrationUrl: user?.isMainIntegrationUrl,
            mainBranchId: user?.mainBranchId
        }
    });

    const branchItems = statusData?.statusBranches?.map((queueItem: any) => {
        return formattedBranches?.map((branchItem: any, index: number) => {
            if (queueItem.id === branchItem.id) {
                return (
                    <div key={index} className="flex align-items-center mb-2">
                        {hasBranchVisionPermission && <Button
                            className="max-w-4rem p-button-secondary"
                            style={{minWidth: '40px'}}
                            loading={branchDetailsLoader && currentUserId === branchItem.id}
                            disabled={!archiveStatusTableToggle || branchDetailsLoader}
                            icon={archiveStatusTableToggle && hasBranchUpdatePermission ? 'pi pi-pencil' : !archiveStatusTableToggle || !hasBranchUpdatePermission ? 'pi pi-eye' : ''}
                            onClick={() => {
                                setCurrentUserId(branchItem.id);
                                dispatch(getBranchDetailById(branchItem.id, jwtToken?.jwtToken, true));
                                if (hasBranchUpdatePermission) {
                                    dispatch(setEditType(true));
                                } else {
                                    dispatch(setEditType(false));
                                }
                            }}/>}
                        <div className="field col-10 md:col-10 flex align-items-center m-0">
                            {branchItem.label}
                        </div>
                        <div className="field col-2 md:col-1 flex align-items-center justify-content-end m-0 p-0 pr-2">
                            <Button name={branchItem.id}
                                    onClick={() => {
                                        dispatch(deleteStatusBranch(branchItem.id));
                                    }}
                                    icon="pi pi-times"
                                    className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2 m-0"
                                    disabled={!archiveStatusTableToggle || !editType || inSystem}
                            />
                        </div>
                    </div>
                )
            }
        })
    })

    const changeStatusBranches = (e: any) => {
        const filteredEValue = e.value?.filter((item: { id: number; }) => item.id);
        const existingIds = new Set(filteredEValue.map((item: { id: number; }) => item.id));
        const branchGroupData = new Set(formattedBranches.map((item: { id: number; }) => item.id));
        const newItems = statusData && statusData.statusBranches
            ? statusData.statusBranches.filter((item: { id: number; }) =>
                !existingIds.has(item.id) && !branchGroupData.has(item.id))
            : [];
        const updatedEValue = [...filteredEValue, ...newItems];

        dispatch(addStatusGroupBranch({ statusId: statusData?.id, id: updatedEValue }));
    }

    return (
        <div className="general-settings">
            <div className="grid p-fluid">
                <div className="field col-12 md:col-6 mb-0" ref={nameField}>
                    <label htmlFor="name" className={classNames('', {'p-error': errorFields?.name})}>Назва статусу *</label>
                    <InputText
                        id="name"
                        value={statusData?.statusName || ''}
                        maxLength={20}
                        className={classNames('', {'p-invalid': errorFields?.name})}
                        onChange={e => {
                            onChangeNameField(e);
                        }}
                        disabled={!archiveStatusTableToggle || !editType || inSystem}
                    />
                    {errorFields?.name && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span className="title p-error text-sm">{errorFields?.name?.name}*</span></div>}               </div>
                <div className="field col-12 md:col-6"></div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="description" className={classNames('', {'p-error': errorFields?.description})}>Коментар</label>
                    <InputTextarea
                        id="description"
                        value={statusData?.description || ''}
                        onChange={e => {
                            dispatch(setStatusField({section: 'statusData', fieldName: 'description', value: e.target.value}))
                        }}
                        className={classNames('', {'p-invalid': errorFields?.description})}
                        autoResize
                        rows={3}
                        maxLength={500}
                        disabled={!archiveStatusTableToggle || !editType || inSystem}
                    />
                </div>
                <div className="field col-12 md:col-6"></div>
                <Divider className="mx-2"/>
                <div className="field col-12 md:col-4 flex flex-column">
                    <label htmlFor="name" className={classNames('', {'p-error': errorFields?.branchId && !statusData?.branchId})}>Бранч *</label>
                    <MultiSelect
                        appendTo="self"
                        value={branchesValue}
                        options={formattedBranches}
                        className={classNames('', {'p-invalid': errorFields?.branchId})}
                        onChange={(e) => {
                            changeStatusBranches(e);
                        }}
                        placeholder="Оберіть бранч"
                        filter
                        optionLabel="label"
                        selectedItemsLabel={branchesValue ? `Обрано: ${branchesValue?.length}` : ""}
                        maxSelectedLabels={0}
                        scrollHeight="250px"
                        disabled={!archiveStatusTableToggle || !editType || inSystem}
                    />
                    {
                        branchesValue?.length ?
                            <div className="users-list mt-3">
                                {branchItems}
                            </div>
                            :
                            <>

                                {
                                    errorFields?.branchId ? <span className={classNames('', {'block title p-error text-sm mt-4': errorFields?.branchId})}>Нічого не вибрано</span> :
                                        <span className={classNames('no-users-message mt-4')}>Нічого не вибрано</span>
                                }
                            </>

                    }
                </div>
            </div>
            <div>
                <div>
                    <label htmlFor="name" className="font-bold text-base">Колір</label>
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
                <div className="grid">
                    <div className="field col-12 md:col-3">
                        <div>
                            {colors.map((color) => (
                                <Button
                                    key={color.value}
                                    className={`${color.className} border-none outline-none shadow-none border-round-lg mb-1`}
                                    style={{
                                        width: '23px',
                                        height: '23px',
                                        marginLeft: '5px',
                                        marginRight: '5px'
                                    }}
                                    disabled={!archiveStatusTableToggle || !editType || inSystem}
                                    onClick={() => {
                                        dispatch(setStatusField({section: 'statusData', fieldName: 'statusColor', value: color.value}));
                                        setColorHEX(color.value)
                                    }}
                                />
                            ))}
                            <Button
                                icon={!showColorPicker ? "pi pi-plus" : "pi pi-minus"}
                                className={`font-bold  border-none outline-none shadow-none border-round-lg mb-1`}
                                disabled={!archiveStatusTableToggle || !editType || inSystem}
                                style={{
                                    width: '23px',
                                    height: '23px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                }}

                                onClick={() => setShowColorPicker(!showColorPicker)}
                            />
                        </div>
                    </div>

                    <div className="field col-12 md:col-3">
                        <div>
                            <Badge value={replaceStatusName} className={`field p-badge text-white customer-badge text-xs mr-4 mb-1`} style={{borderRadius: '6px', backgroundColor: `${statusData.statusColor}`, minWidth: '135px', minHeight: '20px'}}/>
                        </div>
                        {showColorPicker && <div className="flex flex-column">
                            <label className="mb-2">Виберіть колір:</label>
                            <ColorPicker inputId="cp-hex" className="border-none" panelClassName="status-color-picker outline-none border-none border-color-none" format="hex" value={colorHEX} onChange={e => {
                                dispatch(setStatusField({section: 'statusData', fieldName: 'statusColor', value: '#' + e.target.value}))
                            }}/>
                        </div>}
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <label htmlFor="name" className="font-bold text-base">Стан</label>
                </div>
                <div className="p-divider p-component p-divider-horizontal p-divider-solid p-divider-left"></div>
                <div>
                    <div className="field-radiobutton my-4 pl-2">
                        <label htmlFor="name" className="mr-2">Неактивна</label>
                        <InputSwitch disabled={!archiveStatusTableToggle || !editType || inSystem} checked={statusData?.active} onChange={(e) => {
                            dispatch(setStatusField({section: 'statusData', fieldName: 'active', value: e.value}));
                        }}/>
                        <label htmlFor="icon">Активна</label>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default StatusesListDialogGeneralSettings;
