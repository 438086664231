import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React from "react";
import {Checkbox} from "primereact/checkbox";
import {setRoleField} from "../../../redux/actions/actionsRoles";

const RolesDialogStatusMatrix = (props: any) => {
    const dispatch = useAppDispatch();
    const rolesData = useAppSelector(state => state.RolesReducer.rolesData) as unknown as any;
    const archiveTableToggle = useAppSelector(state => state.RolesReducer.archiveRolesTableToggle);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const statuses = useAppSelector(state => {
        // @ts-ignore
        const currentStatuses = state.UsersListReducer.statuses;
        return {statuses: [...currentStatuses], rolesData};
    });

    const updateStatusInMatrix = (idFrom: any, idTo: any, checked: any) => {
        const foundMatrix = rolesData.statusMatrix?.find((item: { idFrom: any; }) => item.idFrom === idFrom);
        let statusMatrix = rolesData.statusMatrix || [];
        if (checked) {
            if (foundMatrix) {
                const elementIndex = foundMatrix?.idsTo?.findIndex((item: any) => item.idTo === idTo);

                if (elementIndex !== -1) {
                    let newIdsTo = [
                        ...foundMatrix.idsTo.slice(0, elementIndex),
                        ...foundMatrix.idsTo.slice(elementIndex + 1)
                    ];

                    const updatedMatrix = {...foundMatrix, idsTo: newIdsTo};

                    const updatedData = statusMatrix?.map((item: { idFrom: any; }) => {
                        if (item.idFrom === updatedMatrix.idFrom) {
                            return updatedMatrix;
                        }
                        return item;
                    });

                    dispatch(setRoleField({section: 'rolesData', fieldName: 'statusMatrix', value: updatedData}));
                } else {
                    let newIdTo = {
                        "idTo": idTo,
                        "isEdit": true,
                        "isAccess": true
                    }
                    const updatedMatrix = {...foundMatrix, idsTo: [...foundMatrix.idsTo, newIdTo]};
                    const updatedData = statusMatrix?.map((item: { idFrom: any; }) => {
                        if (item.idFrom === updatedMatrix?.idFrom) {
                            return {...item, idsTo: updatedMatrix?.idsTo};
                        }
                        return item;
                    });

                    dispatch(setRoleField({section: 'rolesData', fieldName: 'statusMatrix', value: updatedData}));
                }
            } else {
                const newMatrix = {
                    "idFrom": idFrom,
                    "idsTo": [
                        {
                            "idTo": idTo,
                            "isEdit": true,
                            "isAccess": true
                        }
                    ]
                }
                statusMatrix = [...statusMatrix, newMatrix];
                dispatch(setRoleField({section: 'rolesData', fieldName: 'statusMatrix', value: statusMatrix}));
            }

        } else {
            const foundMatrix = statusMatrix.find((item: { idFrom: any; }) => item.idFrom === idFrom);
            if (foundMatrix) {
                const indexToRemove = foundMatrix.idsTo.findIndex((item: any) => item.idTo === idTo);
                if (indexToRemove !== -1) {
                    const updatedMatrix = {
                        ...foundMatrix,
                        idsTo: foundMatrix.idsTo.filter((item: any, index: any) => index !== indexToRemove)
                    };

                    if (updatedMatrix.idsTo.length === 0) {
                        statusMatrix = statusMatrix.filter((item: { idFrom: any; }) => item.idFrom !== idFrom);
                    } else {
                        statusMatrix = statusMatrix.map((item: { idFrom: any; }) => (item.idFrom === idFrom ? updatedMatrix : item));
                    }

                    dispatch(setRoleField({ section: 'rolesData', fieldName: 'statusMatrix', value: statusMatrix }));
                } else {
                    const foundMatrixIndex = statusMatrix.findIndex((item: { idFrom: any; }) => item.idFrom === idFrom);

                    if (foundMatrixIndex !== -1) {
                        const newIdsTo = [...statusMatrix[foundMatrixIndex].idsTo];
                        const elementIndex = newIdsTo.findIndex(item => item.idTo === idTo);
                        if (elementIndex !== -1) {
                            newIdsTo[elementIndex] = {
                                ...newIdsTo[elementIndex],
                                "isEdit": true,
                                "isAccess": false
                            };

                        } else {
                            newIdsTo.push({
                                "idTo": idTo,
                                "isEdit": true,
                                "isAccess": false
                            });
                        }

                        const newFoundMatrix = {
                            ...statusMatrix[foundMatrixIndex],
                            idsTo: newIdsTo
                        };

                        const newStatusMatrix = [
                            ...statusMatrix.slice(0, foundMatrixIndex),
                            newFoundMatrix,
                            ...statusMatrix.slice(foundMatrixIndex + 1)
                        ];

                        dispatch(setRoleField({ section: 'rolesData', fieldName: 'statusMatrix', value: newStatusMatrix }));
                    } else {
                        let newIdTo = {
                            "idTo": idTo,
                            "isEdit": true,
                            "isAccess": false
                        }
                        const updatedMatrix = {...foundMatrix, idsTo: [...foundMatrix.idsTo, newIdTo]};
                        const updatedData = statusMatrix?.map((item: { idFrom: any; }) => {
                            if (item.idFrom === updatedMatrix?.idFrom) {
                                return {...item, idsTo: updatedMatrix?.idsTo};
                            }
                            return item;
                        });

                        dispatch(setRoleField({section: 'rolesData', fieldName: 'statusMatrix', value: updatedData}));
                    }
                }
            } else {
                const newMatrix = {
                    "idFrom": idFrom,
                    "idsTo": [
                        {
                            "idTo": idTo,
                            "isEdit": true,
                            "isAccess": false
                        }
                    ]
                }
                statusMatrix = [...statusMatrix, newMatrix];
                dispatch(setRoleField({section: 'rolesData', fieldName: 'statusMatrix', value: statusMatrix}));
            }
        }

    };

    const statusMatrixBody = (rowData: any, column: any) => {
        const foundMatrix = statuses?.rolesData?.statusMatrix?.find((item: any) => item.idFrom === rowData?.id);
        if (foundMatrix) {
            const foundObject = foundMatrix?.idsTo?.find((item: any) => item.idTo === column);
            if (foundObject) {
                return <> <Checkbox
                    checked={foundObject?.idTo === column && foundObject?.isAccess}
                    disabled={!foundObject?.isEdit  || !archiveTableToggle || !rolesData?.isEdit || !(editType)}
                    onChange={(e) => {
                        updateStatusInMatrix(rowData.id, column, e.checked);
                    }}
                /> </>
            } else {
                return <> <Checkbox
                    checked={foundObject?.idTo !== column}
                    disabled={!archiveTableToggle || !rolesData?.isEdit || !(editType)}
                    onChange={(e) => {
                        updateStatusInMatrix(rowData.id, column, e.checked);
                    }}
                /> </>
            }
        } else {
            return <> <Checkbox
                checked={true}
                disabled={!archiveTableToggle || !rolesData?.isEdit || !(editType)}
                onChange={(e) => {
                    updateStatusInMatrix(rowData.id, column, e.checked);
                }}
            /> </>
        }
    }

    return (
        <div>
            <DataTable
                value={statuses?.statuses}
                showGridlines
                scrollable scrollHeight={props?.height || '47vh'}
                tableStyle={{minWidth: '100%'}}
                cellSelection
            >
                <Column header="" frozen field="statusName"  style={{minWidth: '180px', backgroundColor: '#353845', zIndex: '100'}} headerStyle={{backgroundColor: '#353845'}} headerClassName='zindex'></Column>
                {statuses?.statuses?.map((col, i) => (
                    <Column
                        key={i}
                        className="justify-content-center"
                        headerStyle={{minWidth: '150px'}}
                        headerClassName={'text-white'}
                        style={{minWidth: '150px'}}
                        body={(rowData: any) => statusMatrixBody(rowData, col?.id)}
                        header={col.statusName}
                    />
                ))}
            </DataTable>
        </div>
    );
};

export default RolesDialogStatusMatrix;
