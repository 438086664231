import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React from "react";
import {Divider} from "primereact/divider";
import {useAppSelector} from "../../../redux/hooks";

const RolesListDialogInfo = () => {
    const rolesData = useAppSelector(state => state.RolesReducer.rolesData) as unknown as any;

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                <p className="pb-1">{rowData?.name || '-'}</p>
            </>
        );
    }

    const activeBodyTemplate = (rowData: any) => {
        return (
            <>
                <p className="pb-1">{rowData?.active ? 'Активна' : 'Неактивна'}</p>
            </>
        );
    }

    return (
        <>
            <div id="pr_id_3_header" className="p-dialog-title mb-3">Налаштування ролі</div>
            <DataTable value={[rolesData]}>
                <Column className="w-6" field="name" body={nameBodyTemplate} header="Назва"></Column>
                <Column className="w-6" field="active" body={activeBodyTemplate} header="Стан"></Column>
            </DataTable>
            <Divider style={{margin: '0 0 20px 0'}}/>
        </>

    )
}

export default RolesListDialogInfo
