import React, {useEffect, useRef, useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {classNames} from "primereact/utils";
import {Button} from "primereact/button";
import 'react-h5-audio-player/lib/styles.css';
import {Dialog} from "primereact/dialog";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {Paginator} from "primereact/paginator";
import {MultiSelect} from "primereact/multiselect";
import {getUsers} from "../../../redux/api/api";
import CallsStatisticsListNav from "./StatusStatisticsListNav";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {
    getUserStatusDetailsInfo,
    setStatusStatisticsCurrentPage,
    setStatusStatisticsCurrentPageSize, setStatusStatisticsFilter
} from "../../../redux/actions/actionsStatusStatistics";
import {getStatusStatisticsWithPaginationFilterAndSorting} from "../../../redux/api/apiStatusStatisticsList";
import StatusStatisticsDetails from "./StatusStatisticsDetails";

const StatusStatisticsList = (props: any) => {
    const dispatch = useAppDispatch();
    const statisticsStatusList = useAppSelector(state => state.StatisticsStatusListReducer?.statisticsStatusList);
    const statisticCallsData = useAppSelector(state => state.StatisticsStatusListReducer.statisticStatusData);
    const userStatisticSortFields: any = useAppSelector(state => state.RolesReducer.userStatisticSortFields);
    const paginationSize = useAppSelector(state => state.StatisticsStatusListReducer.paginationSize);
    const pagination = useAppSelector(state => state.StatisticsStatusListReducer.pagination);
    const loader = useAppSelector(state => state.StatisticsStatusListReducer.loading);
    const paginationPage = useAppSelector(state => state.StatisticsStatusListReducer.paginationPage);
    const clearFilters = useAppSelector(state => state.StatisticsStatusListReducer.clearFilters)
    const user = useAppSelector(state => state.UsersReducer.userData);
    // @ts-ignore
    const activeClick = useAppSelector(state => state.UsersListReducer.activeClick);
    const [first, setFirst] = useState(0);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [selectedRowExpansion, setSelectedRowExpansion] = useState<any | null>(null);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [isDescFilter, setIsDescFilter] = useState<boolean>(true);
    const [sortOrder, setSortOrder] = useState<number>(0);
    const [startTime, setStartTime] = useState<any>(new Date("2024-10-29 00:00:00"));
    const [endTime, setEndTime] = useState<any>(new Date());
    const [startFinishTime, setStartFinishTime] = useState<any>(null);
    const [endFinishTime, setEndFinishTime] = useState<any>(null);
    const [filterCampaignId, setFilterCampaignId] = useState<any>(null);
    const [filterNameTo, setFilterNameTo] = useState<any>(null);
    const [filterNameFrom, setFilterNameFrom] = useState<any>(null);
    const [filterAmdHangup, setFilterAmdHangup] = useState<any>(null);
    const [filterCallReset, setFilterCallReset] = useState<any>(null);
    const [filterStatus, setFilterStatus] = useState<any>(null);
    const [filterCampaignName, setFilterCampaignName] = useState<any>(null);
    const [filterId, setFilterId] = useState<any>(null);
    const [filterFullName, setFilterFullName] = useState<any>(null);
    const [filterTypeChannel, setFilterTypeChannel] = useState<any>(null);
    const [filterDirection, setFilterDirection] = useState<any>(null);
    const [filterDateTime, setFilterDateTime] = useState<any>(new Date());
    const [filterEndDateTime, setFilterEndDateTime] = useState<any>(new Date());
    const [filterFinishDateTime, setFilterFinishDateTime] = useState<any>(false);
    const [filterFinishEndDateTime, setFilterFinishEndDateTime] = useState<any>(false);
    const [fullCallDurationStartTime, setfullCallDurationStartTime] = useState<any>(false);
    const [fullCallDurationEndTime, setfullCallDurationEndTime] = useState<any>(false);
    const [durationStartTime, setDurationStartTime] = useState<any>(false);
    const [durationEndTime, setDurationEndTime] = useState<any>(false);
    const [reactionClientStartTime, setReactionClientStartTime] = useState<any>(false);
    const [reactionClientEndTime, setReactionClientEndTime] = useState<any>(false);
    const [reactionOperatorStartTime, setReactionOperatorStartTime] = useState<any>(false);
    const [reactionOperatorEndTime, setReactionOperatorEndTime] = useState<any>(false);
    const [visible, setVisible] = useState(false);
    const [filterCallOwner, setFilterCallOwner] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<string>('table1');
    const dataTableRef = useRef<DataTable | any>(null);
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const usersData = useAppSelector(state => state.CompaniesListReducer.usersData);
    const pageChanged = useAppSelector(state => state.StatisticsStatusListReducer.pageChanged);
    const [fullCallDuration, setFullCallDuration] = useState<boolean>(false);
    const [duration, setDuration] = useState<boolean>(false);
    const [reactionClient, setReactionClient] = useState<boolean>(false);
    const [reactionOperator, setReactionOperator] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(paginationSize);
    const [sortStatusId, setSortStatusId] = useState<number | any>(null);
    const [hasStartFilter, setHasStartFilter] = useState<boolean>(false);
    const [statusesListArray, setStatusesListArray] = useState<any>([]);

    const formatDate = (dateString: Date) => {
        const dateObj = new Date(dateString);

        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const day = dateObj.getDate().toString().padStart(2, "0");
        const year = dateObj.getFullYear();

        return `${month}/${day}/${year}`;
    };

    useEffect(() => {
        dispatch(getUsers(user));
    }, []);

    useEffect(() => {
        setStartTime(new Date("2024-10-29 00:00:00"));
        setEndTime(new Date());
        setFilterDateTime(new Date());
        setFilterEndDateTime(new Date());
    }, []);

    const formatDateTime = (dateString: any) => {
        const date = new Date(dateString);
        return `${date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'})} ${date.toLocaleDateString('en-GB')}`;
    };

    // const getTime = (dateString: Date | any) => {
    //     const dateObj = new Date(dateString);
    //     const hours = dateObj.getHours().toString().padStart(2, "0");
    //     const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    //     const seconds = dateObj.getSeconds().toString().padStart(2, "0");
    //
    //     return `${hours}:${minutes}:${seconds}`;
    // };

    const getTime = (dateString: Date | any) => {
        const dateObj = new Date(dateString);
        const hours = dateObj.getHours().toString().padStart(2, "0");
        const minutes = dateObj.getMinutes().toString().padStart(2, "0");

        return `${hours}:${minutes}:00`;
    };

    let startDate = formatDate(filterDateTime);
    let endDate = formatDate(filterEndDateTime);
    let filterStartTime = getTime(startTime);
    let filterEndTime = getTime(endTime);

    const [sortData, setSortData] = useState<any>({
        pageNumber: 1,
        pageSize: 10,
        sort: {
            orderBy: orderBy ? orderBy : "Id",
            isDesc: isDescFilter
        },
        filter: [
            {
                "key": "Start",
                "value": `${startDate} ${filterStartTime},${endDate} ${filterEndTime}`
            }
        ],
        username: user.userName
    });

    const handleScroll = () => {
        resetTableHorizontalScroll();
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const resetTableHorizontalScroll = () => {
        if (dataTableRef.current) {
            dataTableRef.current.scrollLeft = 0;
        }
    };

    // if (!userStatisticSortFields?.userId) {
    //     dispatch(getStatisticsUserSortFields(jwtToken?.jwtToken));
    // }

    useEffect(() => {
        if (pagination.hasNext && pagination.pageNumber) {
            setTotalItems((pagination.pageNumber * paginationSize) + paginationSize)
        } else if (!pagination.hasNext) {
            setTotalItems(pagination.pageNumber * paginationSize);
        }
    }, [pagination]);

    useEffect(() => {
        setTotalItems((pagination.pageNumber * paginationSize) + paginationSize);
    }, [])


    // useEffect(() => {
    //     setSelectedColumns(userStatisticSortFields?.fields?.filter((item: { isActive: any; }) => item.isActive));
    // }, []);

    useEffect(() => {
        setFirst(0);
    }, [pageChanged])

    // useEffect(() => {
    //     setSelectedColumns(userStatisticSortFields?.fields?.filter((item: { isActive: any; }) => item.isActive));
    // }, [userStatisticSortFields]);


    useEffect(() => {
        setStatusesListArray(statisticsStatusList);
    }, [statisticsStatusList]);


    const createUuidv4 = () => {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            // @ts-ignore
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    const formatItem = (item: {
        start: any;
    }) => {
        if (item && item.start) {
            return {
                ...item,
                start: formatDateTime(item.start),
                dateTime: createUuidv4()
            };
        }
        return item;
    };

    const formatDataRecursively = (obj: any) => {
        const formattedObj = {
            ...obj,
            item: formatItem(obj.item),
        };

        if (formattedObj.children && formattedObj.children.length > 0) {
            formattedObj.children = formattedObj.children.map((child: any) => formatDataRecursively(child));
        }

        return formattedObj;
    };

    const handleReset = () => {
        dataTableRef?.current?.reset();
        customFilter();
        setStartTime(new Date("2024-10-29 00:00:00"));
        setEndTime(new Date());
        setFilterEndDateTime(null);
        setStartFinishTime(null);
        setEndFinishTime(null);
        setFilterFinishDateTime(null);
        setFilterFinishEndDateTime(null);
        setSortOrder(0);
        setFirst(0);
        dispatch(setStatusStatisticsCurrentPage(1));
        dispatch(setStatusStatisticsCurrentPageSize(10));
        setFilterId(null);
        setReactionOperator(false);
        setFullCallDuration(false);
        setDuration(false);
        setReactionOperatorStartTime(null);
        setReactionOperatorEndTime(null);
        setReactionOperator(false);
        setDurationStartTime(null);
        setDurationEndTime(null);
        setfullCallDurationStartTime(null);
        setfullCallDurationEndTime(null);
        setReactionClientStartTime(null);
        setReactionClientEndTime(null);
        setReactionClient(false);
        setHasStartFilter(false);
        setFilterDateTime(new Date());
        setFilterEndDateTime(new Date());
        setSortStatusId(null);
        setSortData({
            pageNumber: 1,
            pageSize: 10,
            sort: {
                orderBy: orderBy ? orderBy : "Id",
                isDesc: isDescFilter
            },
            username: user.userName
        })
    };


    useEffect(() => {
        dispatch(setStatusStatisticsCurrentPage(1));
    }, []);

    useEffect(() => {
        dispatch(setStatusStatisticsCurrentPageSize(10));
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onPageChange = (event: {
        first: React.SetStateAction<number>;
        page: number;
        rows: any;
    }) => {
        setFirst(event.first);
        dispatch(setStatusStatisticsCurrentPage(event.page + 1));
        dispatch(setStatusStatisticsCurrentPageSize(event.rows));
    };

    useEffect(() => {
        setSelectedRowData(null);
        let filter: {
            key: string;
            value: string
        }[] = [];

        const dataSort: any = {
            pageNumber: paginationPage,
            pageSize: paginationSize,
            sort: {
                orderBy: orderBy ? orderBy : "Id",
                isDesc: isDescFilter,
                ...(sortStatusId ? {statusId: sortStatusId} : {})
            },
            username: user.userName
        }

        if (filterCampaignId?.value) {
            filter.push(filterCampaignId);
        }
        if (filterNameTo?.value) {
            filter.push(filterNameTo);
        }
        if (filterNameFrom?.value) {
            filter.push(filterNameFrom);
        }
        if (filterTypeChannel) {
            filterTypeChannel?.map((item: any) => {
                if (item.value === 'internalSkipped') {
                    filter.push({key: 'IsDeclined', value: '1'});
                } else {
                    filter.push(item);
                }
            })
        }

        if (filterCampaignName) {
            filterCampaignName?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterCallOwner) {
            filterCallOwner?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterId) {
            filter.push(filterId);
        }

        if (filterFullName) {
            filterFullName?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterStatus) {
            filterStatus.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterDirection) {
            filterDirection.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterAmdHangup) {
            filterAmdHangup.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterCallReset) {
            filterCallReset.map((item: any) => {
                filter.push(item);
            })
        }


        let startAdded = false;
        let endAdded = false;

        if (filterDateTime && filterEndDateTime) {
            let startDate = formatDate(filterDateTime);
            let endDate = formatDate(filterEndDateTime);

            if (startTime && endTime) {
                let filterStartTime = getTime(startTime);
                let filterEndTime = getTime(endTime);
                filter.push({
                    "key": "Start",
                    "value": `${startDate} ${filterStartTime},${endDate} ${filterEndTime}`
                });
                startAdded = true;
            }
        }

        if (filterFinishDateTime && filterFinishEndDateTime) {
            let startDate = formatDate(filterFinishDateTime);
            let endDate = formatDate(filterFinishEndDateTime);

            if (startFinishTime && endFinishTime) {
                let filterStartTime = getTime(startFinishTime);
                let filterEndTime = getTime(endFinishTime);
                filter.push({
                    "key": "End",
                    "value": `${startDate} ${filterStartTime},${endDate} ${filterEndTime}`
                });
                endAdded = true;
            }
        }

        if (!startAdded && !endAdded) {
            let currentDateTime = new Date();
            let currentDate = formatDate(currentDateTime);
            let currentTime = getTime(currentDateTime);
            filter.push({
                "key": "Start",
                "value": `${currentDate} 00:00:00,${currentDate} ${currentTime}`
            });
        }

        const addFilterSecond = (condition: boolean, key: string, startTime: number, endTime: number) => {
            if (
                condition &&
                startTime !== undefined && startTime !== null &&
                endTime !== undefined && endTime !== null
            ) {
                filter.push({
                    "key": key,
                    "value": `${startTime}, ${endTime}`
                });
            }
        };

        const getTotalSeconds = (date: any) => {
            if (!date) return null;
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            return hours * 3600 + minutes * 60 + seconds;
        };


        if (reactionClientStartTime && reactionClientEndTime) {
            addFilterSecond(reactionClient, "ReactionClient", getTotalSeconds(reactionClientStartTime), getTotalSeconds(reactionClientEndTime));
        }
        if (reactionOperatorStartTime && reactionOperatorEndTime) {
            addFilterSecond(reactionOperator, "ReactionOperator", getTotalSeconds(reactionOperatorStartTime), getTotalSeconds(reactionOperatorEndTime));
        }
        if (fullCallDurationStartTime && fullCallDurationEndTime) {
            addFilterSecond(fullCallDuration, "FullCallDuration", getTotalSeconds(fullCallDurationStartTime), getTotalSeconds(fullCallDurationEndTime));
        }
        if (durationStartTime && durationEndTime) {
            addFilterSecond(duration, "Duration", getTotalSeconds(durationStartTime), getTotalSeconds(durationEndTime));
        }

        if (filter) {
            dataSort['filter'] = filter;
        }

        const startEntry = dataSort?.filter?.length
            ? dataSort.filter.find((item: {
                key: string;
            }) => item.key === "Start")
            : undefined;

        if (startEntry) {
            collapseAll();
            dispatch(getStatusStatisticsWithPaginationFilterAndSorting(dataSort, jwtToken?.jwtToken));
            dispatch(setStatusStatisticsFilter(dataSort));
            setSortData(dataSort);
            resetTableHorizontalScroll();
        }
    }, [paginationPage, paginationSize, orderBy, isDescFilter, sortOrder, filterCampaignId, filterNameTo, filterTypeChannel, filterStatus, reactionClient, reactionOperator, duration, fullCallDuration, hasStartFilter]);

    useEffect(() => {
        if (activeClick?.item === "/statuses" && activeClick?.from ==='#/statuses') {
            dispatch(getStatusStatisticsWithPaginationFilterAndSorting(sortData, jwtToken?.jwtToken));
        }
    }, [activeClick]);

    const monitoringSort = (event: {
        sortField: any;
    }, statusId?: any) => {

        if (statusId !== null) {
            setSortStatusId(statusId);
        } else {
            setSortStatusId(null);
        }

        const handleAction = (sortField: string) => {
            let newOrderBy: string;
            switch (sortField) {
                case 'Id':
                    newOrderBy = 'Id';
                    break;
                case 'FullName':
                    newOrderBy = 'FullName';
                    break;
                case 'AllCountUserCalls':
                    newOrderBy = 'AllCountUserCalls';
                    break;
                case 'StatusCount':
                    newOrderBy = 'StatusCount';
                    break;
                case 'AvgTimePerStatus':
                    newOrderBy = 'AvgTimePerStatus';
                    break;
                case 'StatusName':
                    newOrderBy = 'StatusName';
                    break;
                case 'TimeInStatus':
                    newOrderBy = 'TimeInStatus';
                    break;
                default:
                    return;
            }

            setOrderBy(newOrderBy);
            setIsDescFilter(!isDescFilter);
            setSortOrder(sortOrder === 1 ? -1 : 1);
            dispatch(setStatusStatisticsCurrentPage(1));
            setFirst(0);
        };
        let sortField = event.sortField;
        handleAction(sortField);
    };

    const filterClearTemplate = (options: {
        filterClearCallback: React.MouseEventHandler<HTMLButtonElement> | undefined;
        field: string
    }) => {
        return <Button type="button" className="p-button-secondary" icon="pi pi-times" onClick={(e) => {
            if (options.filterClearCallback) {
                options.filterClearCallback(e);
            }
            if (options.field === 'start') {
                setStartTime(null);
                setEndTime(null);
                setFilterDateTime(null);
                setFilterEndDateTime(null);
            }
            if (options.field === 'end') {
                setStartFinishTime(null);
                setEndFinishTime(null);
                setFilterFinishDateTime(null);
                setFilterFinishEndDateTime(null);
            }
            if (options.field === 'reactionClient') {
                setReactionClientStartTime(null);
                setReactionClientEndTime(null);
                setReactionClient(false);
            }
            if (options.field === 'reactionOperator') {
                setReactionOperatorStartTime(null);
                setReactionOperatorEndTime(null);
                setReactionOperator(false);
            }
            if (options.field === 'duration') {
                setDuration(false);
                setDurationStartTime(null);
                setDurationEndTime(null);
            }
            if (options.field === 'fullCallDuration') {
                setfullCallDurationStartTime(null);
                setfullCallDurationEndTime(null);
                setFullCallDuration(false);
            }
        }}></Button>;
    }

    const filterApplyTemplate = (options: { filterApplyCallback: React.MouseEventHandler<HTMLButtonElement> | undefined; field: string; }) => {
        return (
            <Button
                type="button"
                icon="pi pi-check"
                onClick={(e) => {
                    if (options.filterApplyCallback) options.filterApplyCallback(e);
                    dispatch(setStatusStatisticsCurrentPage(1));
                }}
                className=""
                disabled={
                    (options.field === 'duration' && (!durationStartTime || !durationEndTime)) ||
                    (options.field === 'fullCallDuration' && (!fullCallDurationStartTime || !fullCallDurationEndTime)) ||
                    (options.field === 'reactionOperator' && (!reactionOperatorStartTime || !reactionOperatorEndTime)) ||
                    (options.field === 'reactionClient' && (!reactionClientStartTime || !reactionClientEndTime)) ||
                    (options.field === 'start' && (!startTime || !endTime || !filterDateTime || !filterEndDateTime)) ||
                    (options.field === 'end' && (!startFinishTime || !endFinishTime || !filterFinishDateTime || !filterFinishEndDateTime))
                }
            ></Button>
        );
    };

    useEffect(() => {
        dispatch(getUsers(user));
    }, []);

    const customFilter = (event?: any) => {
        if (event) {
            const filters = event.filters;
            const {end} = event.filters;
            const {fullCallDuration} = event.filters;
            const {duration} = event.filters;
            const {reactionClient} = event.filters;
            const {reactionOperator} = event.filters;

            setFullCallDuration(!!fullCallDuration.value);
            setDuration(!!duration.value);
            setReactionClient(!!reactionClient.value);
            setReactionOperator(!!reactionOperator.value);
            setFilterFinishDateTime(end.value || null);
            const setFilter = (filterName: string, key: string, defaultValue?: any | null) => {
                if (!filters[filterName]) {
                    return defaultValue;
                }

                const value = filters[filterName].value || filters[filterName].constraints?.[0]?.value;
                if (!value) {
                    return defaultValue;
                }

                if (Array?.isArray(value)) {
                    return value.map((item) => ({key, value: String(item)}));
                } else {
                    return {key, value: String(value)};
                }
            };

            setFilterCampaignId(setFilter('campaignId', 'CampaignId'));
            setFilterNameTo(setFilter('nameTo', 'NameTo'));
            setFilterNameFrom(setFilter('nameFrom', 'NameFrom'));
            setFilterTypeChannel(setFilter('typeChannel', 'TypeChannel', []));
            setFilterFullName(setFilter('fullName', 'Username', []));
            setFilterCampaignName(setFilter('campaignFullName', 'CampaignId', []));
            setFilterCallOwner(setFilter('ownerCall', 'OwnerCall', []));
            setFilterId(setFilter('id', 'Id', null));
            setFilterDirection(setFilter('direction', 'Direction', []));
            setFilterStatus(setFilter('status', 'Status', []));
            setFilterAmdHangup(setFilter('amdHangup', 'ForcedClosing', []));
            setFilterCallReset(setFilter('callResetName', 'CallReset', []));
        } else {
            setFilterCampaignId(null);
            setFilterFullName(null);
            setFilterNameTo(null);
            setFilterNameFrom(null);
            setFilterStatus(null);
            setFilterCampaignName(null);
            setFilterTypeChannel(null);
            setFilterAmdHangup(null);
            setFilterCallReset(null);
            setOrderBy(null);
            setIsDescFilter(true);
            setFilterDirection(null);
            setFilterId(null);
            setFilterCallOwner(null);
        }
    };

    const operatorFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть:</div>
                <MultiSelect
                    value={options.value}
                    optionValue="userName"
                    selectedItemsLabel={options.value ? `Обрано: ${options.value.length}` : ""}
                    filter
                    options={usersDataWithFullName}
                    maxSelectedLabels={0}
                    optionLabel="fullName"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"

                />
            </>
        );
    };

    const renderHeader = (field: string, sortField: string, statusId?: any) => {
        const sorted = sortField === orderBy;
        const sortIcon = classNames('p-sortable-column-icon', {
            'pi pi-sort-amount-up-alt': sorted && sortOrder === 1 && statusId === sortStatusId,
            'pi pi-fw pi-sort-amount-down': sorted && sortOrder === -1 && statusId === sortStatusId,
            'pi pi-fw pi-sort-alt': sortOrder === 0 || !sorted || statusId !== sortStatusId
        });

        return (
            <div className="flex align-items-center cursor-pointer"
                 onClick={() => monitoringSort({'sortField': sortField}, statusId)}>
                {field} <i className={sortIcon + ' pl-2'}></i>
            </div>
        );
    };

    const toggleTab = (tableName: string) => {
        setSelectedRowData(null);
        setActiveTab(tableName);
    };

    const isMobile = () => {
        return windowWidth <= 1248;
    }

    const usersDataWithFullName = usersData?.map((userData: any) => {
        return {
            ...userData,
            fullName: `${userData?.lastName} ${userData?.firstName} ${userData?.middleName ? `${userData.middleName}` : ''}`
        }
    });

    const collapseAll = () => {
        setExpandedRows(null);
    };

    const dynamicRowClass = (data: any) => {
        if (data?.id === selectedRowData?.id) {
            return 'mainRowMonitoringTable p-highlight';
        }
        return 'mainRowMonitoringTable';
    };

    const convertSecondsToTime = (totalSeconds: number): string => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);

        const remainingSeconds = totalSeconds % 60;
        const seconds = remainingSeconds >= 0.5 ? Math.ceil(remainingSeconds) : Math.floor(remainingSeconds);

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const headerIdBlock = () => {
        return <div className="flex">
            <div style={{width: '70px', minWidth: '70px', maxWidth: '70px'}}>{renderHeader('ID', 'Id')}</div>
            <div style={{width: '170px', minWidth: '170px', maxWidth: '170px'}}>{renderHeader('ПІБ', 'FullName')}</div>
        </div>
    }

    const headerCurrentStatus = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '170px'}}>{renderHeader('Назва', 'StatusName')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '170px'}}>{renderHeader('Тривалість', 'TimeInStatus')}</div>
        </div>
    }

    const bodyIdTemplate = (data: any) => {
        return <div className="flex align-items-center">
            <div className="flex align-items-center"
                 style={{width: '70px', minWidth: '70px', maxWidth: '70px', minHeight: '33px'}}>{data?.id}</div>
            <div style={{width: '155px', minWidth: '155px', maxWidth: '155px'}}>{data?.fullName}</div>
        </div>
    }

    const bodyCurrentStatusTemplate = (data: any) => {
        return <div className="flex align-items-center">
            <div style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{data?.now?.statusName}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{data?.now?.timeInStatus ? convertSecondsToTime(data?.now?.timeInStatus) : '00:00:00'}</div>
        </div>
    }

    const headerTitleStatuses = (statusId: any) => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Кількість', 'StatusCount', statusId)}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Тривалість', 'TimeInStatus', statusId)}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Середня', 'AvgTimePerStatus', statusId)}</div>
        </div>
    }

    const headerStatusesValue = (statusCount: any, timeInStatus: any, avgTimePerStatus: any) => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statusCount || 0}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{timeInStatus ? convertSecondsToTime(timeInStatus) : '00:00:00'}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{avgTimePerStatus ? convertSecondsToTime(avgTimePerStatus) : '00:00:00'}</div>
        </div>
    }

    const headerGroup = () => {
        return (<ColumnGroup>
                <Row>
                    <Column className="column-border-none" frozen header="Оператор"
                            style={{display: "table-cell !important"}}
                            headerStyle={{width: '240px', minWidth: '240px', maxWidth: '240px', display: 'table-cell !important'}}/>
                    <Column className="column-border-none" header="Поточний статус"
                            headerStyle={{width: '240px', minWidth: '240px', maxWidth: '240px'}}/>
                    {statisticsStatusList?.all && statisticsStatusList?.all?.map((item: any, index: number) => {
                        return (
                            <Column className="column-border-none" key={index} header={item.statusName}
                                    headerStyle={{width: '360px', minWidth: '360px', maxWidth: '360px'}}/>
                        )
                    })}
                </Row>
                <Row>
                    <Column className={'Id-FullName-Header'} header={headerIdBlock} filter filterField={'fullName'}
                            showFilterMatchModes={false} filterElement={operatorFilterTemplate}
                            filterApply={filterApplyTemplate} filterClear={filterClearTemplate}
                            showFilterMenuOptions={false}/>
                    <Column className={'CurrentStatusHeader'}
                            header={headerCurrentStatus}
                            showFilterMatchModes={false} filterElement={operatorFilterTemplate}
                            filterApply={filterApplyTemplate} filterClear={filterClearTemplate}
                            showFilterMenuOptions={false}/>
                    {statisticsStatusList?.all && statisticsStatusList?.all?.map((item: any, index: number) => {
                        return (
                            <Column className="column-border-none" key={index}
                                    header={headerTitleStatuses(item?.statusId)}/>
                        )
                    })}
                </Row>
                <Row>
                    <Column className="column-border-none" header="Усього по операторам"
                            style={{borderRight: '1px solid grey', display: 'table-cell'}}/>
                    <Column className="column-border-none" header="" style={{borderRight: '1px solid grey'}}/>
                    {statisticsStatusList?.all && statisticsStatusList?.all?.map((item: any, index: number) => {
                        return (
                            <Column className="column-border-none" key={index}
                                    header={headerStatusesValue(item?.statusCount, item?.timeInStatus, item?.avgTimePerStatus)}/>
                        )
                    })}
                </Row>
            </ColumnGroup>
        )
    };

    const dynamicColumns = (statisticsStatusList?.users?.[0]?.statuses || []).map((status: {
        statusName: any;
    }, index: string | number) => (
        <Column
            key={`status-${index}`}
            body={(rowData) => {
                const userStatus = rowData?.statuses[index];
                return (
                    <div className="flex">
                        <div
                            style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{userStatus?.statusCount || '0'}</div>
                        <div
                            style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{userStatus?.timeInStatus ? convertSecondsToTime(userStatus?.timeInStatus) : '00:00:00'}</div>
                        <div
                            style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{userStatus?.avgTimePerStatus ? convertSecondsToTime(userStatus?.avgTimePerStatus) : '00:00:00'}</div>
                    </div>
                );
            }}
            style={{width: '360px', minWidth: '360px', maxWidth: '360px', minHeight: '45px'}}
        />
    ));

    const renderTable = () => {
        return (
            <div className="layout-dashboard">
                <div className="flex flex-wrap col-12 md:col-12">
                    <div
                        className={classNames('card widget-table mr-4 col-12 transition-duration-300 monitoring-table align-self-start monitoringLoader', {'': (selectedRowData || selectedRowExpansion) && !isMobile(), 'md:col-12': !(selectedRowData || selectedRowExpansion)})}>
                        <CallsStatisticsListNav hasStartFilter={hasStartFilter} setHasStartFilter={setHasStartFilter}
                                                setEndTime={setEndTime} endTime={endTime} startTime={startTime}
                                                setStartTime={setStartTime} setFilterEndDateTime={setFilterEndDateTime}
                                                filterEndDateTime={filterEndDateTime}
                                                setFilterDateTime={setFilterDateTime}
                                                filterDateTime={filterDateTime}
                                                userSortFields={userStatisticSortFields?.fields} sortData={sortData}
                                                resetSorting={handleReset} toggleTab={toggleTab}
                                                activeTab={activeTab}/>
                        <DataTable
                            headerColumnGroup={headerGroup()}
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            scrollable={windowWidth <= 960}
                            scrollHeight={(selectedRowData || selectedRowExpansion) && !isMobile() ? '40vh' : '70vh'}
                            ref={dataTableRef}
                            rows={10}
                            onSort={monitoringSort}
                            filters={clearFilters}
                            loading={loader}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            value={statisticsStatusList?.users || []}
                            className="p-datatable-customers monitoring-table monitoring-table-rowGroup statusStatisticks"
                            rowClassName={dynamicRowClass}
                            selectionMode="single"
                            emptyMessage="Нічого не знайдено"
                            rowHover={false}
                            onFilter={customFilter}
                            onRowClick={(e) => {
                                setSelectedRowExpansion(null);
                                dispatch(getUserStatusDetailsInfo(e.data));
                                if (!isMobile) {
                                    if (props.profileMenuPin === true) {
                                        setSelectedRowData(e.data);
                                        setVisible(true);
                                    } else {
                                        setSelectedRowData(e.data);
                                    }
                                } else {
                                    setSelectedRowData(e.data);
                                    setVisible(true);
                                }
                            }} dataKey="item.id"
                        >
                            <Column body={bodyIdTemplate}/>
                            <Column style={{width: '240px', minWidth: '240px', maxWidth: '240px'}}
                                    body={bodyCurrentStatusTemplate}/>
                            {dynamicColumns}
                        </DataTable>
                        <Paginator
                            first={first}
                            rows={paginationSize}
                            totalRecords={totalItems}
                            rowsPerPageOptions={[5, 10, 15, 25, 50]}
                            onPageChange={onPageChange}
                            className="border-top-1 border-bottom-1"
                            template="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                        />
                    </div>
                    {(selectedRowData || selectedRowExpansion) && statisticCallsData && (
                        <>
                            {windowWidth > 1248 && <div
                                className={classNames('card widget-table col-12 md:col-12 align-self-start sticky top-0')}>
                                <StatusStatisticsDetails setSelectedRowData={setSelectedRowData}
                                                        setSelectedRowExpansion={setSelectedRowExpansion}
                                />
                            </div>}

                            {windowWidth <= 1248 && <div>
                                <Dialog visible={visible} className="monitoring-info"
                                        header={statisticCallsData?.fullName} headerClassName={"pl-5  text-xs"}
                                        onHide={() => setVisible(false)}>
                                    <StatusStatisticsDetails setSelectedRowData={setSelectedRowData}
                                                            setSelectedRowExpansion={setSelectedRowExpansion}
                                    />
                                </Dialog>
                            </div>}
                        </>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div>
            {renderTable()}
        </div>
    );
};

export default StatusStatisticsList;
